import React from 'react'
import { Link } from 'react-router-dom';
const envConfig = require('../../../config/envConfig');

function Carousel(props) {
  const MyBackgroundImage ="assets/img/HomePage.jpeg"
  return (
    
	<div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
	<div class="carousel-inner">
	  <div class="carousel-item active">
	  </div>
	  <div class="carousel-item">
		<img src="..." class="d-block w-100" alt="..."/>
	  </div>
	  <div class="carousel-item">
		<img src="..." class="d-block w-100" alt="..."/>
	  </div>
	</div>
  </div>
  );
}

export default Carousel;