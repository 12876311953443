import React from 'react'
import { Link } from 'react-router-dom';

const ProductsViaCategory = ({products}) => {
    return (
        <>
            <div className="container-fluid hi mb-5">
                <div className="row">
                    <div className='col-md-12'>
                        <h3>Categories</h3>
                    </div>
                    { products.map((product) => {
                        return(
                            <>
                                <div className="col-md-3">
                                    <div className="card my-2 my-md-0 border">
                                        <h5 className="card-title ml-4 pt-3">{product.category_name}</h5>
                                        <div className="row">
                                            <div className="col-12 col-sm-5 ml-4 productcard1" > 
                                                <Link to="#">
                                                    <img src={product.image} alt="product img" className="img-fluid1" />
                                                </Link>
                                            </div>
                                            <div className="col-6 col-sm-5  productcard1" >
                                                <Link to="#">
                                                    <img src={product.image} alt="product img" className="img-fluid1" />
                                                </Link>
                                            </div>

                                            <div className="w-100"></div>

                                            <div className="col-12 col-sm-5 ml-4 productcard1">
                                                <Link to="#">
                                                    <img src={product.image} alt="product img" className="img-fluid1" />
                                                </Link>
                                            </div>
                                            <div className="col-12 col-sm-5 productcard1" >
                                                <Link to="#">
                                                    <img src={product.image} alt="product img" className="img-fluid1" />
                                                </Link>
                                            </div>
                                            <Link className="ml-4 py-3" to="">See more</Link>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default ProductsViaCategory;