import React from 'react';
import './css/deliverylogin.css';
import $ from 'jquery';
import { Link, useNavigate } from 'react-router-dom';
const common = require('../../utils/Common');

const DeliveryLogin = () => {
  let navigate = useNavigate();
  const LoginUser = async () => {
		let data = {
			"user_id" : $('#user_id').val(),
			"password" : $('#password').val(),
		}
		if ($('#user_id').val() == '' || $('#password').val() == '') {
			$('#error').html('Please fill both user id and password');
			return;
		}
		let response = await common.apiRequest('post', '/delivery/login', data, 'delivery');
		if (response.status == 200) {
			if (response.data.code == 'success') {
				localStorage.setItem('delivery_token', response.data.access_token);
				localStorage.setItem('delivery_userId', response.data.id);
				localStorage.setItem('delivery_userType', response.data.user_type);
				navigate('/delivery/products');
			} else {
				$('#error').html(response.data.error);
			}
		} else {
			$('#error').html(response);
		}
	}

  if (localStorage.getItem('delivery_token')) {
		navigate('/delivery/products');
	}

  return (
    <>
    <div className='new-div'>
    <div class="container_login">
  <div class="login_card">
    <h2 className='login_head'>Login</h2>
      <input className='login_input' type="text" id="user_id" name="username" placeholder="Username" required/>
      <input className='login_input' type="password" id="password" name="password" placeholder="Password" required/>
      <br/>
      <button className='login_button' onClick={() => LoginUser()}>Login</button>
  </div>
</div>
    </div>
 
       
    </>
  )
}

export default DeliveryLogin