import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './homepage.css';
import $ from 'jquery';
import { Html5QrcodeScanner } from "html5-qrcode";
import { Modal, ModalHeader, ModalBody, Input, FormGroup, Button, Label, Form, ModalFooter } from 'reactstrap';
import moment from 'moment';
const common = require('../../../utils/Common');
const envConfig = require('../../../config/envConfig');

const MenuCard = ({ products }) => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [addedToCart, setAddToCart] = useState({});
    
    const filteredProducts = products.filter((product) =>
        product.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        addressAddAlert();
        initializeCartStatus();
    }, [products]);

    const initializeCartStatus = () => {
        const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
        const cartProductIds = cartItems.map(item => item.product_item_id);
        const newAddedToCart = {};
        products.forEach(product => {
            const productItemId = product.product_items[0].id;
            newAddedToCart[productItemId] = cartProductIds.includes(productItemId);
        });
        setAddToCart(newAddedToCart);
    };

    const addressAddAlert = async () => {
        if (localStorage.getItem("userId") && (!localStorage.getItem("address_alert_date") || localStorage.getItem("address_alert_date") !== moment().format("YYYY-MM-DD"))) {
            let data = { "user_id": localStorage.getItem('userId') };
            let response = await common.apiRequest('post', '/users/get-address', data);
            if (response.status === 200) {
                if (response.data.code === 'success' && response.data.data.length === 0) {
                    common.alert("Please add home address", 'info');
                    localStorage.setItem("address_alert_date", moment().format("YYYY-MM-DD"));
                    navigate("/my-account/address");
                }
            }
        }
    };

    const addToCart = async (productId, productItemId, supplypincode, selfDeliver) => {
        var cartItems = localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [];
        let newCartItem = {
            'product_id': productId,
            'product_item_id': productItemId,
            'quantity_purchased': 1,
            'user_id': localStorage.getItem('userId'),
            'supply_pincode': supplypincode,
            'service_id': 0,
            'type': 'product',
            'action': 'create',
            'self_deliver': selfDeliver,
            'active': 0
        };
        let response = await common.apiRequest('post', '/products/add-remove-cart-items', newCartItem);
        let data = (response.data) ? response.data : [];
        newCartItem.cart_id = data.cart_id;
        delete newCartItem.user_id;
        delete newCartItem.action;
        cartItems.push(newCartItem);
        localStorage.setItem('cartItems', JSON.stringify(cartItems));

        setAddToCart((prev) => ({ ...prev, [productItemId]: true }));
        common.alert(data.message, 'success');
    };

    const removeFromCart = async (productItemId) => {
        var cartItems = JSON.parse(localStorage.getItem('cartItems'));
        if (cartItems) {
            const cartItemToRemove = cartItems.find(o => o.product_item_id === productItemId);
            if (!cartItemToRemove) return;  
            cartItemToRemove.action = "delete";
            let response = await common.apiRequest('post', '/products/add-remove-cart-items', cartItemToRemove);
            let data = (response.data) ? response.data : [];

            const updatedCartItems = cartItems.filter(item => item.product_item_id !== productItemId);
            localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));

            setAddToCart((prev) => ({ ...prev, [productItemId]: false }));
            common.alert(data.message, 'success');
        }
    };

    return (
        <div className='mainproduct'>
            <div className="container-fluid bg-transparent my-4 p-3" style={{ position: "relative", maxWidth: "1400px" }}>
                <div className="row g-3">
                    {filteredProducts.map((product) => {
                        const productItemId = product.product_items[0].id;
                        return (
                            <div className="col-6 col-sm-6 col-md-4 col-lg-3" key={product.id} style={{ marginBottom: "30px" }}>
                                <div className="card h-100 shadow-sm transparent-card">
                                    <Link to={`/product/${product.id}/${product.product_items[0].id}`}>
                                        {product.product_items[0] && product.product_items[0].product_image ? 
                                            <img src={envConfig.api_url + product.product_items[0].product_image.split(',')[0]} className="card-img-top" alt="product img" />
                                            : ""
                                        }
                                    </Link>
                                    <div className="label-top shadow-sm">
                                        <Link className="text-white" to={`/product/${product.id}/${product.product_items[0].id}`}>
                                            {product.name}
                                        </Link>
                                    </div>
                                    <div className="card-body">
                                        <div className="clearfix mb-3">
                                            <span className="float-start badge rounded-pill bg-warning">Rs. {product.product_items[0].price}</span>
                                        </div>
                                        <div className="d-grid gap-2 my-4">
                                            {!addedToCart[productItemId] ? (
                                                <button className='btn btn-warning bold-btn' onClick={() => addToCart(product.id, productItemId, product.seller_address.pincode, product.product_items[0].self_deliver)}>
                                                    Add to Cart
                                                </button>
                                            ) : (
                                                <button className='btn btn-danger bold-btn' onClick={() => removeFromCart(productItemId)}>
                                                    Remove from Cart
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default MenuCard;
