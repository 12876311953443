const CustomButton = ({ buttonText, onClick }) => {
    return (
      <button
        style={{
          backgroundColor: '#FFCC00', 
          border: 'none', 
          color: 'white',
          padding: '10px 20px', 
          fontSize: '16px', 
          fontWeight: 'bold',
          borderRadius: '5px',
          cursor: 'pointer',
          margin: '5px',
          textAlign: 'center',
          width: '300px'
        }}
        onClick={onClick}
      >
         <span dangerouslySetInnerHTML={{ __html: buttonText }} />
      </button>
    );
}

export default CustomButton;