import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { FormGroup, Button, Label, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import moment from 'moment';
import './userA.css';
import { exportToCSV, exportToExcel, exportToPDF } from '../../utils/Exports';

const common = require('../../utils/Common');

const UserRefeanceDetails = () => {
    const [userData, setUserData] = useState([]);
    const [orderDetails, setOrderDetails] = useState([]);
    const [modal, setModal] = useState(false);
    const [commissionPercentOnlineProduct, setCommissionPercentOnlineProduct] = useState(0);
    const [commissionPercentOfflineProduct, setCommissionPercentOfflineProduct] = useState(0);
    const [commissionPercentService, setCommissionPercentService] = useState(0);
    const [orderDetailsType, setOrderDetailsType] = useState(null);
    const [commissionType, setCommissionType] = useState('sale_retail');

    const fetchReferralData = async () => {
        if (!$('#start_date').val() || !$('#end_date').val() || !$('#commission_type').val()) {
            common.alert("Fill all filters (Start Date, End Date & Commission Type)", "error");
            return;
        }
        setCommissionType($('#commission_type').val());
        var parameters = {
            start_date: $('#start_date').val(),
            end_date: $('#end_date').val(),
            user_id: localStorage.getItem('userId'),
            fetch_approved: $('#fetch_approved').is(':checked'),
            retainer_commission: $('#commission_type').val() == "retainer" ? 1 : 0
        };
        let response = await common.apiRequest('post', '/admin/fetch-user-commission', parameters);
        
        if (response.data && response.data.code === 'success') {
            setUserData(response.data.data);
        }
    };

    const toggle = () => {
        setModal(!modal);
        setOrderDetails([]);
        setCommissionPercentOnlineProduct(0);
        setCommissionPercentOfflineProduct(0);
        setCommissionPercentService(0);
        setOrderDetailsType(null);
    }

    const getOrderDetails = async (orderIds, commissionPercentOnlineProduct, commissionPercentOfflineProduct, commissionPercentService, type) => {
        let data = {
            'ids': orderIds
        }
        let response = await common.apiRequest('post', '/orders/get-order-by-order-id', data);
		if (response.status == 200) {
			if (response.data.code == 'success') {
                setOrderDetails(response.data.data);
                setModal(true);
                setCommissionPercentOnlineProduct(commissionPercentOnlineProduct);
                setCommissionPercentOfflineProduct(commissionPercentOfflineProduct);
                setCommissionPercentService(commissionPercentService);
                setOrderDetailsType(type);
			} else {
                common.alert(response.data.error, "error");
            }
		} else {
            common.alert(response.data.error, "error");
		}
    }
    const formatDecimal = (value) => {
        if (value === null || value === undefined || value === '') {
            return '0.00';
        }
        const numValue = typeof value === 'string' ? parseFloat(value) : value;
      
        if (isNaN(numValue)) {
            return '0.00';
        }
        return Number(Math.round(numValue + 'e2') + 'e-2').toFixed(2);
      };
    const data = userData.map((data, index) => ({
        "User Id": data.reg_id,
        "First Name": data.first_name,
        "Last Name": data.last_name,
        "Total Online Shopping Product": formatDecimal(data.total_shopping_online_product),
        "Total Taxable Online Shopping Product":formatDecimal(data.total_taxable_shopping_online_product),
        "Commission Percent Online Product": formatDecimal(data.commission_percent_online_product),
        "Total Offline Shopping Product": formatDecimal(data.total_shopping_offline_product),
        "Total Taxable Offline Shopping Product": formatDecimal(data.total_taxable_shopping_offline_product),
        "Commission Percent Offline Product": formatDecimal(data.commission_percent_offline_product),
        "Total Shopping Service": formatDecimal(data.total_shopping_service),
        "Total Taxable Shopping Service": formatDecimal(data.total_taxable_shopping_service),
        "Commission Percent Service": formatDecimal(data.commission_percent_service),
        "Referrer User Id": data.ref_user_reg_id,
        "Total Order Amount Online Product": formatDecimal(data.total_order_amount_shopping_online_product),
        "Total Taxable Amount Online Product": formatDecimal(data.total_taxable_amount_shopping_online_product),
        "Commission Online Product": formatDecimal(data.commission_shopping_online_product),
        "Total Order Amount Offline Product": formatDecimal(data.total_order_amount_shopping_offline_product),
        "Total Taxable Amount Offline Product": formatDecimal(data.total_taxable_amount_shopping_offline_product),
        "Commission Offline Product": formatDecimal(data.commission_shopping_offline_product),
        "Total Order Amount Service": formatDecimal(data.total_order_amount_shopping_service),
        "Total Taxable Amount Service": formatDecimal(data.total_taxable_amount_shopping_service),
        "Commission Service": formatDecimal(data.commission_shopping_service),
        "10% Commission Deduction": ((parseFloat(data.commission_shopping_online_product) + parseFloat(data.commission_shopping_offline_product) + parseFloat(data.commission_shopping_service)) * 0.10).toFixed(2),
        "Remaining Commission After Deduction": ((parseFloat(data.commission_shopping_online_product) + parseFloat(data.commission_shopping_offline_product) + parseFloat(data.commission_shopping_service)) - ((parseFloat(data.commission_shopping_online_product) + parseFloat(data.commission_shopping_offline_product) + parseFloat(data.commission_shopping_service)) * 0.10)).toFixed(2),        
        "Total Order Amount Retail Online Product": formatDecimal(data.total_order_amount_retail_online_product),
        "Total Taxable Amount Retail Online Product": formatDecimal(data.total_taxable_amount_retail_online_product),
        "Commission Percent Retail Online Product": formatDecimal(data.commission_percent_retail_online_product),
        "Commission Retail Online Product": formatDecimal(data.commission_retail_online_product),
        "Total Order Amount Retail Offline Product": formatDecimal(data.total_order_amount_retail_offline_product),
        "Total Taxable Amount Retail Offline Product": formatDecimal(data.total_taxable_amount_retail_offline_product),
        "Commission Percent Retail Offline Product": formatDecimal(data.commission_percent_retail_offline_product),
        "Commission Retail Offline Product": formatDecimal(data.commission_retail_offline_product),
        "Total Order Amount Retail Service": formatDecimal(data.total_order_amount_retail_service),
        "Total Taxable Amount Retail Service": formatDecimal(data.total_taxable_amount_retail_service),
        "Commission Percent Retail Service": formatDecimal(data.commission_percent_retail_service),
        "Commission Retail Service": formatDecimal(data.commission_retail_service),
        "10% Commission Deduction Retail": ((parseFloat(data.commission_retail_online_product) + parseFloat(data.commission_retail_offline_product) + parseFloat(data.commission_retail_service)) * 0.10).toFixed(2),        
        "Remaining Commission After Deduction Retail": (parseFloat(data.commission_retail_online_product) + parseFloat(data.commission_retail_offline_product) + parseFloat(data.commission_retail_service) - ((parseFloat(data.commission_retail_online_product) + parseFloat(data.commission_retail_offline_product) + parseFloat(data.commission_retail_service)) * 0.10)).toFixed(2),                           
        "Bank Name": data.bank_name,
        "Bank Account No": data.bank_account_no,
        "IFSC Code": data.ifsc_code
    }));
    
    const handleExportCSV = () => {
        exportToCSV(data, 'buyer commission');
    }
    
    const handleExportExcel = () => {
        exportToExcel(data, 'buyer commission');
    }
    
    const handleExportPDF = () => {
        exportToPDF(data, 'buyer commission');
    }

    const dataOther = orderDetails.map((data, index) => {
        
        return {
            "S.No": index + 1,
            "Order Id": data.order?.order_id || 'NA',
            "Invoice No": data.order?.invoices?.find(invoice => invoice.invoice_type === (orderDetailsType === "shopping" || orderDetailsType === "retainer" ? 'product' : 'seller_product'))?.invoice_no || 'NA',
            "Product Name": data.product_item?.product?.name || data.offline_sale_product_datum?.product_description || data.seller_service?.service_name || 'NA',
            "Online Product Sale Amount": formatDecimal(data.product_id ? data.order_price : 0),
            "Online Product Sale Taxable Amount": formatDecimal(data.product_id ? data.taxable_amount : 0),
            "Commission Percent Online Product Sale": formatDecimal(commissionPercentOnlineProduct),
            "Offline Product Sale Amount": formatDecimal(data.offline_sale_id && data.offline_sale_product_datum ? data.offline_sale_product_datum.sale_amount : 0),
            "Offline Product Sale Taxable Amount": formatDecimal(data.offline_sale_id && data.offline_sale_product_datum ? data.offline_sale_product_datum.taxable_supply : 0),
            "Commission Percent Offline Product Sale": formatDecimal(commissionPercentOfflineProduct),
            "Service Sale Amount": formatDecimal(data.service_id ? data.order_price : 0),
            "Service Sale Taxable Amount": formatDecimal(data.service_id ? Number(data.taxable_amount) : 0),
            "Commission Percent Service Sale": formatDecimal(commissionPercentService),
            "Total Commission": formatDecimal(data.taxable_amount * (data.product_id ? commissionPercentOnlineProduct : data.offline_sale_id ? commissionPercentOfflineProduct : commissionPercentService) / 100),
            "TDS (10%)": formatDecimal((data.taxable_amount * (data.product_id ? commissionPercentOnlineProduct : data.offline_sale_id ? commissionPercentOfflineProduct : commissionPercentService) / 100 * 0.10).toFixed(2)),
            "Net Receivable": formatDecimal((data.taxable_amount * (data.product_id ? commissionPercentOnlineProduct : data.offline_sale_id ? commissionPercentOfflineProduct : commissionPercentService) / 100 - data.taxable_amount * (data.product_id ? commissionPercentOnlineProduct : data.offline_sale_id ? commissionPercentOfflineProduct : commissionPercentService) / 100 * 0.10).toFixed(2)), 
        };
    });
    const handleExportCSVs = () => {
        exportToCSV(dataOther, 'buyer commission');
    }
    
    const handleExportExcels = () => {
        exportToExcel(dataOther, 'buyer commission');
    }
    
    const handleExportPDFs = () => {
        exportToPDF(dataOther, 'buyer commission');
    }
    
    return (
        <>
            {/* Main component JSX remains the same */}
            <div className="container-fluid">
                <div className="row">
                    <ol className="col-12 breadcrumb bg-transparent">
                        <li className="breadcrumb-item "><Link to="/" className="text-dark">Home</Link></li>
                        <li className="breadcrumb-item "><Link to="/my-account" className="text-dark">user-account</Link></li>
                        <li className="breadcrumb-item active"><Link to="#" className="text-dark">reffer info</Link></li>
                    </ol>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-3'>
                    <label>Start Date</label>
                    <input type='date' className='form-control' id="start_date"/><br/>
                    <input type='checkbox' id="fetch_approved"/> Fetch Approved
                </div>
                <div className='col-md-3'>
                    <label>End Date</label>
                    <input type='date' className='form-control' id="end_date"/>
                </div>
                <div className='col-md-3'>
                    <label>Commission Type</label>
                    <select id="commission_type" className='form-control'>
                        <option value="">Select Commission Type</option>
                        <option value="sale_retail">Sale/Retail Commission</option>
                        <option value="retainer">Retainer Commission</option>
                    </select>
                </div>
                <div className='col-md-3'>
                    <br/>
                    <button type="button" className='btn btn-primary btn-sm mt-2' onClick={fetchReferralData}>Submit</button>
                </div>
                <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
                <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
                <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>
            </div>

            <br/>
            {
                commissionType == "retainer" ?
                    <table class="orders-table table-responsive report-margin commontop table-container">
                        <thead>
                            <tr className='table-primary'>
                                <th>Reg Id</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Total Shopping Online Product</th>
                                <th>Total Taxable Shopping Online Product</th>
                                <th>Commission Percent Online Product</th>
                                <th>Commission Online Product</th>
                                <th>Total Shopping Offline Product</th>
                                <th>Total Taxable Shopping Offline Product</th>
                                <th>Commission Percent Offline Product</th>
                                <th>Commission Offline Product</th>
                                <th>Total Shopping Service</th>
                                <th>Total Taxable Shopping Service</th>
                                <th>Commission Percent Service</th>
                                <th>Commission Service</th>
                                <th>Total Commission</th>
                                <th>TDS</th>
                                <th>Receiveable Commission</th>
                                <th>View Order Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                userData.length > 0 ? 
                                    userData.map((data) => {
                                        return (
                                            <tr className='trtd'>
                                                <td>{data.reg_id}</td>
                                                <td>{data.first_name}</td>
                                                <td>{data.last_name}</td>
                                                <td>{formatDecimal (data.retainer_order_amount_online)}</td>
                                                <td>{formatDecimal (data.retainer_taxable_order_amount_online)}</td>
                                                <td>{formatDecimal (data.retainer_commission_percent_online)}</td>
                                                <td>{formatDecimal (data.retainer_commission_online)}</td>
                                                <td>{formatDecimal (data.retainer_order_amount_offline)}</td>
                                                <td>{formatDecimal (data.retainer_taxable_order_amount_offline)}</td>
                                                <td>{formatDecimal (data.retainer_commission_percent_offline)}</td>
                                                <td>{formatDecimal (data.retainer_commission_offline)}</td>
                                                <td>{formatDecimal (data.retainer_order_amount_service)}</td>
                                                <td>{formatDecimal (data.retainer_taxable_order_amount_service)}</td>
                                                <td>{formatDecimal (data.retainer_taxable_order_amount_service)}</td>
                                                <td>{(parseFloat(data.retainer_commission_online) + parseFloat(data.retainer_commission_offline) + parseFloat(data.retainer_taxable_order_amount_service)).toFixed(2)}</td>
                                                <td>{((parseFloat(data.retainer_commission_online) + parseFloat(data.retainer_commission_offline) + parseFloat(data.retainer_taxable_order_amount_service)) * 0.10).toFixed(2)}</td>
                                                <td>{((parseFloat(data.retainer_commission_online) + parseFloat(data.retainer_commission_offline) + parseFloat(data.retainer_taxable_order_amount_service)) - ((parseFloat(data.retainer_commission_online) + parseFloat(data.retainer_commission_offline) + parseFloat(data.retainer_taxable_order_amount_service)) * 0.10)).toFixed(2)}</td>
                                                <td>
                                                    <><button type="button" className='btn btn-primary btn-sm' onClick={() => getOrderDetails(data.order_item_ids, data.retainer_commission_percent_online, data.retainer_commission_percent_offline, data.retainer_commission_percent_service, 'retainer')}>View Order Details</button></>
                                                </td>
                                            </tr>
                                        )
                                    })
                                :
                                "No Data"
                            }
                        </tbody>
                    </table>
                :
                    <table class="orders-table table-responsive report-margin commontop table-container">
                        <thead>
                            <tr className='table-primary'>
                                <th>Reg Id</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Total Shopping Online Product</th>
                                <th>Total Taxable Shopping Online Product</th>
                                <th>Commission Percent Online Product</th>
                                <th>Total Shopping Offline Product</th>
                                <th>Total Taxable Shopping Offline Product</th>
                                <th>Commission Percent Offline Product</th>
                                <th>Total Shopping Service</th>
                                <th>Total Taxable Shopping Service</th>
                                <th>Commission Percent Service</th>
                                <th>Referral User Id</th>
                                <th>Total Order Amount Shopping Online Product</th>
                                <th>Taxable Order Amount Shopping Online Product</th>
                                <th>Commission Shopping Online Product</th>
                                <th>Total Order Amount Shopping Offline Product</th>
                                <th>Taxable Order Amount Shopping Offline Product</th>
                                <th>Commission Shopping Offline Product</th>
                                <th>Total Order Amount Shopping Service</th>
                                <th>Taxable Order Amount Shopping Service</th>
                                <th>Commission Shopping Service</th>
                                <th>TDS Shopping</th>
                                <th>Receiveable Shopping</th>
                                <th>Total Order Amount Retail Online Product</th>
                                <th>Taxable Order Amount Retail Online Product</th>
                                <th>Commission Percent Retail Online Product</th>
                                <th>Commission Retail Online Product</th>
                                <th>Total Order Amount Retail Offline Product</th>
                                <th>Taxable Order Amount Retail Offline Product</th>
                                <th>Commission Percent Retail Offline Product</th>
                                <th>Commission Retail Offline Product</th>
                                <th>Total Order Amount Retail Service</th>
                                <th>Taxable Order Amount Retail Service</th>
                                <th>Commission Percent Retail Service</th>
                                <th>Commission Retail Service</th>
                                <th>TDS Retail</th>
                                <th>Receiveable Retail</th>
                                <th>View Order Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                userData.length > 0 ? 
                                    userData.map((data) => {
                                        return (
                                            <tr className='trtd'>
                                                <td>{data.reg_id}</td>
                                                <td>{data.first_name}</td>
                                                <td>{data.last_name}</td>
                                                <td>{formatDecimal(data.total_shopping_online_product)}</td>
                                                <td>{formatDecimal (data.total_taxable_shopping_online_product)}</td>
                                                <td>{formatDecimal (data.commission_percent_online_product)}</td>
                                                <td>{formatDecimal (data.total_shopping_offline_product)}</td>
                                                <td>{formatDecimal (data.total_taxable_shopping_offline_product)}</td>
                                                <td>{formatDecimal (data.commission_percent_offline_product)}</td>
                                                <td>{formatDecimal (data.total_shopping_service)}</td>
                                                <td>{formatDecimal (data.total_taxable_shopping_service)}</td>
                                                <td>{formatDecimal (data.commission_percent_service)}</td>
                                                <td>{data.ref_user_reg_id}</td>
                                                <td>{formatDecimal (data.total_order_amount_shopping_online_product)}</td>
                                                <td>{formatDecimal (data.total_taxable_amount_shopping_online_product)}</td>
                                                <td>{formatDecimal (data.commission_shopping_online_product)}</td>
                                                <td>{formatDecimal (data.total_order_amount_shopping_offline_product)}</td>
                                                <td>{formatDecimal (data.total_taxable_amount_shopping_offline_product)}</td>
                                                <td>{formatDecimal (data.commission_shopping_offline_product)}</td>
                                                <td>{formatDecimal (data.total_order_amount_shopping_service)}</td>
                                                <td>{formatDecimal (data.total_taxable_amount_shopping_service)}</td>
                                                <td>{formatDecimal (data.commission_shopping_service)}</td>
                                                <td>{((parseFloat(data.commission_shopping_online_product) + parseFloat(data.commission_shopping_offline_product) + parseFloat(data.commission_shopping_service)) * 0.10).toFixed(2)}</td>
                                                <td>{((parseFloat(data.commission_shopping_online_product) + parseFloat(data.commission_shopping_offline_product) + parseFloat(data.commission_shopping_service)) - ((parseFloat(data.commission_shopping_online_product) + parseFloat(data.commission_shopping_offline_product) + parseFloat(data.commission_shopping_service)) * 0.10)).toFixed(2)}</td>
                                                <td>{formatDecimal (data.total_order_amount_retail_online_product)}</td>
                                                <td>{formatDecimal (data.total_taxable_amount_retail_online_product)}</td>
                                                <td>{formatDecimal (data.commission_percent_retail_online_product)}</td>
                                                <td>{formatDecimal (data.commission_retail_online_product)}</td>
                                                <td>{formatDecimal (data.total_order_amount_retail_offline_product)}</td>
                                                <td>{formatDecimal (data.total_taxable_amount_retail_offline_product)}</td>
                                                <td>{formatDecimal (data.commission_percent_retail_offline_product)}</td>
                                                <td>{formatDecimal (data.commission_retail_offline_product)}</td>
                                                <td>{formatDecimal (data.total_order_amount_retail_service)}</td>
                                                <td>{formatDecimal (data.total_taxable_amount_retail_service)}</td>
                                                <td>{formatDecimal (data.commission_percent_retail_service)}</td>
                                                <td>{formatDecimal (data.commission_retail_service)}</td>
                                                <td>{((parseFloat(data.commission_retail_online_product) + parseFloat(data.commission_retail_offline_product) + parseFloat(data.commission_retail_service)) * 0.10).toFixed(2)}</td>
                                                <td>{(parseFloat(data.commission_retail_online_product) + parseFloat(data.commission_retail_offline_product) + parseFloat(data.commission_retail_service) - ((parseFloat(data.commission_retail_online_product) + parseFloat(data.commission_retail_offline_product) + parseFloat(data.commission_retail_service)) * 0.10)).toFixed(2)}</td>
                                                <td>
                                                    {
                                                        data.order_item_ids_shopping ? 
                                                        <><button type="button" className='btn btn-primary btn-sm' onClick={() => getOrderDetails(data.order_item_ids_shopping, data.commission_percent_online_product, data.commission_percent_offline_product, data.commission_percent_service, 'shopping')}>View Shopping Order Details</button></> : null
                                                    }
                                                    <br/><br/>
                                                    {
                                                        data.order_item_ids_retail ? 
                                                        <><button type="button" className='btn btn-primary btn-sm' onClick={() => getOrderDetails(data.order_item_ids_retail, data.commission_percent_retail_online_product, data.commission_percent_retail_offline_product, data.commission_percent_retail_service, 'retail')}>View Retail Order Details</button></> : null
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                :
                                "No Data"
                            }
                        </tbody>
                    </table>
            }
            <br/>
            
            <Modal isOpen={modal} toggle={toggle} size="lg">
                <ModalHeader toggle={toggle}>Order Details</ModalHeader>
                <ModalBody>
                <table class="orders-table table-responsive report-margin commontop table-container">
                <button className='btn button-product' onClick={handleExportCSVs}>Export to CSV</button>
                        <button className='btn button-product' onClick={handleExportExcels}>Export to Excel</button>
                        <button className='btn button-product' onClick={handleExportPDFs}>Export to PDF</button>

                    <thead>
                        <tr className='table-primary'>
                            <th>S.No</th>
                            <th>Order Id</th>
                            <th>Invoice No</th>
                            <th>Product Name</th>
                            <th>Online Product Sale {orderDetailsType == "shopping" ? "Shopping" : orderDetailsType == "retainer" ? "Retainer" : "Retail"} Amount</th>
                            <th>Online Product Sale {orderDetailsType == "shopping" ? "Shopping" : orderDetailsType == "retainer" ? "Retainer" : "Retail"} Taxable Amount</th>
                            <th>Logistics {orderDetailsType == "shopping" ? "Shopping" : orderDetailsType == "retainer" ? "Retainer" : "Retail"} Amount</th>
                            <th>Logistics {orderDetailsType == "shopping" ? "Shopping" : orderDetailsType == "retainer" ? "Retainer" : "Retail"} Taxable Amount</th>
                            <th>Commission Percent Online Product Sale</th>
                            <th>Offline Product Sale {orderDetailsType == "shopping" ? "Shopping" : orderDetailsType == "retainer" ? "Retainer" : "Retail"} Amount</th>
                            <th>Offline Product Sale {orderDetailsType == "shopping" ? "Shopping" : orderDetailsType == "retainer" ? "Retainer" : "Retail"} Taxable Amount</th>
                            <th>Commission Percent Offline Product Sale</th>
                            <th>Service Sale {orderDetailsType == "shopping" ? "Shopping" : orderDetailsType == "retainer" ? "Retainer" : "Retail"} Amount</th>
                            <th>Service Sale {orderDetailsType == "shopping" ? "Shopping" : orderDetailsType == "retainer" ? "Retainer" : "Retail"} Taxable Amount</th>
                            <th>Commission Percent Service Sale</th>
                            <th>Commission</th>
                            <th>TDS</th>
                            <th>Net ReceiveAble</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            orderDetails.map((data, index) => {
                                return (
                                    <tr className='trtd'>
                                        <td>{++index}</td>
                                        <td>{data.order && data.order.order_id ? data.order.order_id : 'NA'}</td>
                                        <td>
                                            {data.order && data.order.invoices
                                            ? (data.order.invoices.find(invoice => invoice.invoice_type === (orderDetailsType == "shopping" || orderDetailsType == "retainer" ? 'product' : 'seller_product')) || { invoice_no: "" }).invoice_no : ""}
                                        </td>
                                        <td>{data.product_item?.product.name || data.offline_sale_product_datum?.product_description || data.seller_service?.service_name || 'NA'}</td>
                                        <td>{data.product_id ? data.order_price : 0}</td>
                                        <td>{data.product_id ? data.taxable_amount : 0}</td>
                                        <td>{data.logistics_order ? data.logistics_order.order_price : 0}</td>
                                        <td>{data.logistics_order ? data.logistics_order.taxable_value : 0}</td>
                                        <td>{data.product_id || data.logistics_order ? commissionPercentOnlineProduct : 0}</td>
                                        <td>{data.offline_sale_id && data.offline_sale_product_datum ? data.offline_sale_product_datum.sale_amount : 0}</td>
                                        <td>{data.offline_sale_id && data.offline_sale_product_datum ? data.offline_sale_product_datum.taxable_supply: 0}</td>
                                        <td>{data.offline_sale_id ? commissionPercentOfflineProduct : 0}</td>
                                        <td>{data.service_id ? data.order_price : 0}</td>
                                        <td>{data.service_id ? Number(data.taxable_amount) : 0}</td>
                                        <td>{data.service_id ? commissionPercentService : 0}</td>
                                        <td>{(parseFloat(data.taxable_amount) + parseFloat(data.logistics_order ? data.logistics_order.taxable_value : 0)) * (data.product_id ? commissionPercentOnlineProduct : data.offline_sale_id ? commissionPercentOfflineProduct : commissionPercentService)/100}</td>
                                        <td>{((parseFloat(data.taxable_amount) + parseFloat(data.logistics_order ? data.logistics_order.taxable_value : 0)) * (data.product_id ? commissionPercentOnlineProduct : data.offline_sale_id ? commissionPercentOfflineProduct : commissionPercentService)/100 * 0.10).toFixed(2)}</td>
                                        <td>{((parseFloat(data.taxable_amount) + parseFloat(data.logistics_order ? data.logistics_order.taxable_value : 0)) * (data.product_id ? commissionPercentOnlineProduct : data.offline_sale_id ? commissionPercentOfflineProduct : commissionPercentService)/100 - data.taxable_amount * (data.product_id ? commissionPercentOnlineProduct : data.offline_sale_id ? commissionPercentOfflineProduct : commissionPercentService)/100 * 0.10).toFixed(2)}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                </ModalBody>
            </Modal>
        </>
    )
}

export default UserRefeanceDetails;