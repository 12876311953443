import React, { useState, useEffect } from 'react';
import { Input, FormGroup, Button, Label } from 'reactstrap';
import $ from 'jquery';
import CircularProgressBar from '../personal/CircularProgressBar';

const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');

const SellerInfo = () => {
    const [sellerData, setSellerData] = useState(null);
    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const [useMobileNo,setUseMobileNo] = useState();
    const [completionPercentage, setCompletionPercentage] = useState(0);
    const [filePath, setFilePath] = useState("");
    const [filechequePath, setchequeFilePath] = useState("");
    const [filepanPath, setPanFilePath] = useState("");

    useEffect(() => {
        getSeller();
    }, []);
    useEffect(() => {
        if (sellerData && sellerData.length > 0) {
            calculateCompletionPercentage(sellerData[0]);
        }
    }, [sellerData]);
    
    const calculateCompletionPercentage = (sellerData) => {
        if (!sellerData) return;
    const fields = [
        { name: 'business_person_name', path: ['user_business', 'business_person_name'] },
        { name: 'business_address', path: ['user_business', 'business_address'] },
        { name: 'pan_no', path: ['pan_no'] },
        { name: 'email', path: ['user_business', 'email'] },
        { name: 'mobile', path: ['user_business', 'mobile'] },
        { name: 'GST_no', path: ['seller_addresses', 0, 'gst_no'] },
        { name: 'qualification', path: ['user_business', 'qualification'] },
        { name: 'occupation', path: ['user_business', 'occupation'] },
        { name: 'bank_account_no', path: ['user_business', 'bank_account_no'] },
        { name: 'bank_name', path: ['user_business', 'bank_name'] },
        { name: 'ifsc_code', path: ['user_business', 'ifsc_code'] },
        { name: 'nominee_name', path: ['user_business', 'nominee_name'] },
        { name: 'relation_with_nominee', path: ['user_business', 'relation_with_nominee'] },
        { name: 'nominee_dob', path: ['user_business', 'nominee_dob'] },
        { name: 'nominee_percent', path: ['user_business', 'nominee_percent'] },
        { name: 'nature_of_business', path: ['user_business', 'nature_of_business'] },
        { name: 'business_name', path: ['user_business', 'business_name'] },
        { name: 'Point_Contact', path: ['user_business', 'Point_Contact'] }
    ];

    let filledFieldsCount = 0;
    let totalFields = fields.length;
    let emptyFields = [];

    fields.forEach(field => {
        let value = sellerData;
        for (let key of field.path) {
            value = value && value[key];
        }
        if (value && value.trim() !== '') {
            filledFieldsCount++;
        } else {
            emptyFields.push(field.name);
        }
    });

    const percentage = Math.round((filledFieldsCount / totalFields) * 100);
    setCompletionPercentage(percentage);
};
    
    const getSeller = async () => {
        let data = {
            'seller_id': localStorage.getItem('sellerId'),
            'action': "read"
        };
        let response = await common.apiRequest('post', '/seller/crud-seller-profile', data);
        if (response.data.code === 'success') {
            let data = response.data.data ? response.data.data : [];
            setSellerData(data);
            if (data.length > 0) {
                calculateCompletionPercentage(data[0]);
            }
        } else {
            setSellerData([]);
        }
    };

    const updateSellerinfo = async () => {
        let data = {
            'seller_id': localStorage.getItem('sellerId'),
            'action': "update",
            'business_person_name': $('#business_person_name').val(),
            'business_address': $('#business_address').val(),
            'email': $('#email').val(),
            'mobile': $('#mobile').val(),
            'qualification': $('#qualification').val(),
            'occupation': $('#occupation').val(),
            'bank_account_no': $('#bank_account_no').val(),
            'bank_name': $('#bank_name').val(),
            'ifsc_code': $('#ifsc_code').val(),
            'nominee_name': $('#nominee_name').val(),
            'relation_with_nominee': $('#relation_with_nominee').val(),
            'nominee_dob': $('#nominee_dob').val(),
            'nominee_percent': $('#nominee_percent').val(),
            'nature_of_business': $('#nature_of_business').val(),
            'business_name': $('#business_name').val(),
            'Point_Contact': $('#Point_Contact').val(),
            'gst_proof_copy': filePath || seller.user_business.gst_proof_copy,
            'cheque_copy': filechequePath || seller.user_business.cancel_cheque_copy,
            'cancel_cheque': filepanPath || seller.user_business.cancel_cheque
        };
        let response = await common.apiRequest('post', '/seller/crud-seller-profile', data);
        if (response.data.code === 'success') {
            common.alert(response.data.message);
            setIsOtpVerified(false);
            getSeller();
        } else {
            setSellerData([]);
        }
    };

    if (!sellerData) {
        return <div>Loading...</div>; // Show a loading message while data is being fetched
    }

    if (sellerData.length === 0) {
        return <div>No seller data available.</div>; // Show a message if there's no seller data
    }

    const seller = sellerData[0];
    const verifyOtp = async (type) => {
        if (type === "sms" && $('#mobile_otp').val() === "") {
            common.alert("Please enter OTP received on SMS", 'error');
            return false;
        }
        let otpData = {
            "phone": useMobileNo,
            "channel": type,
            "code": $('#mobile_otp').val()
        };
        let response = await common.apiRequest('post', '/users/verify-otp', otpData);
        if (response.data.code === 'success') {
            common.alert(response.data.message);
            setIsOtpVerified(true);
            return true;
        } else {
            common.alert(response.data.error, 'error');
            return false;
        }
    }
    const sandOTP = async () => {
        var parameters = {
            'action': 'read',
            'seller_id': localStorage.getItem('userId'),
        };
        let response = await common.apiRequest('post', '/users/UserAddesOTPverify', parameters);
        common.alert(response.data.message)
        let data = (response.data && response.data.data) ? response.data.data : [];
        setUseMobileNo(data);
        setIsOtpVerified(false);
        // toggle();
    } 
    
	const uploadDocument = async (file, documentType) => {
        const fileData = new FormData();
        fileData.append('document', file);
        fileData.append('documentType', documentType);
        const response = await common.apiRequest('post', '/users/seller_document_upload', fileData);
        return response.data.message;
        
    }
    
    const uploadGSTImage = async (file) => {
        const filePath = await uploadDocument(file, 'seller_gst_image');
        setFilePath(filePath);
    }
    
    const uploadchequeImage = async (file) => {
        const filePath = await uploadDocument(file, 'seller_cheque_image');
        setchequeFilePath(filePath);
    }
    
    const uploadsellerPanImage = async (file) => {
        const filePath = await uploadDocument(file, 'seller_pan_image');
        setPanFilePath(filePath);
    }
    return (
        <>
               <div className="container">
                <div className="row">
                    <div className="col-12 d-flex justify-content-center align-items-center mb-4">
                        <CircularProgressBar percentage={completionPercentage} />
                    </div>
                    <div className="col-12 text-center">
                        <h2>Profile Completion</h2>
                    </div>
                </div>
            </div>
            <div className='chnagepasscard'>
                <div className="card" style={{ width: "40%" }}>
                    <div className="card-body">
                    <FormGroup>
                    <Label for="newEntry">Name</Label>
                    <Input  defaultValue={seller.user_business.business_person_name}
                        type='text' id="business_person_name" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Address</Label>
                    <Input defaultValue={seller.user_business.business_address}
                        type='text' id="business_address" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Pan No</Label>
                    <Input defaultValue={seller.pan_no}
                        type='text' id="Pan_no" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Email</Label>
                    <Input defaultValue={seller.user_business.email}
                        type='text' id="email" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Mobile No</Label>
                    <Input defaultValue={seller.user_business.mobile}
                        type='text' id="mobile" 
                    />
                </FormGroup>
                 <FormGroup>
                    <Label for="newEntry">GST No</Label>
                    <Input defaultValue={seller.seller_addresses[0].gst_no}
                        type='text' id="GST_no" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Qualification</Label>
                    <Input defaultValue={seller.user_business.qualification}
                        type='text' id="qualification" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Occupation</Label>
                    <Input defaultValue={seller.user_business.occupation}
                        type='text' id="occupation" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Bank Account No</Label>
                    <Input defaultValue={seller.user_business.bank_account_no}
                        type='text' id="bank_account_no" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Bank Name</Label>
                    <Input defaultValue={seller.user_business.bank_name}
                        type='text' id="bank_name" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">RTGS/NEFT/IFSC Code of Bank</Label>
                    <Input defaultValue={seller.user_business.ifsc_code}
                        type='text' id="ifsc_code" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Nominee Name</Label>
                    <Input  defaultValue={seller.user_business.nominee_name}
                        type='text' id="nominee_name" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Relation with Nominee</Label>
                    <Input defaultValue={seller.user_business.relation_with_nominee}
                        type='text' id="relation_with_nominee" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Nominee Date of Birth</Label>
                    <Input  defaultValue={seller.user_business.nominee_dob}
                        type='text' id="nominee_dob" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Nominee % of Share</Label>
                    <Input defaultValue={seller.user_business?seller.user_business.nominee_percent:"100"}
                        type='text' id="nominee_percent" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Nature of Business</Label>
                    <Input defaultValue={seller.user_business.nature_of_business}
                        type='text' id="nature_of_business" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Business Name</Label>
                    <Input defaultValue={seller.user_business.business_name}
                        type='text' id="business_name" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Point of Contact</Label>
                    <Input defaultValue={seller.user_business.Point_Contact}
                        type='text' id="Point_Contact" 
                    />
                </FormGroup>
                            <FormGroup className="mb-2 me-sm-2 mb-sm-0">
				                <Label className="me-sm-2" for="pan_no">GST Proof Copy</Label>
				                <Input type="file" id="gst_image" onChange={(e) => uploadGSTImage(e.target.files[0])} />
				            </FormGroup>
								<img className='pan-image' src={envConfig.api_url + seller.user_business.gst_proof_copy} />
                                <FormGroup className="mb-2 me-sm-2 mb-sm-0">
				                <Label className="me-sm-2" for="pan_no">Cancel Cheque Copy</Label>
				                <Input type="file" id="seller_cheque_image" onChange={(e) => uploadchequeImage(e.target.files[0]) }  />
				            </FormGroup>
								<img className='pan-image' src={envConfig.api_url + seller.user_business.cheque_copy} />
                            <FormGroup className="mb-2 me-sm-2 mb-sm-0">
				                <Label className="me-sm-2" for="pan_no">Pan Proof Copy</Label>
				                <Input type="file" id="seller_pan_image" onChange={(e) => uploadsellerPanImage(e.target.files[0])}  />
				            </FormGroup>
								<img className='pan-image' src={envConfig.api_url + seller.user_business.cancel_cheque} />
                <Button onClick={() => sandOTP()} className="btn btn-outline-primary btn-sm">
                Please Send OTP                
                </Button>
                {!isOtpVerified && (
                        <div className='otp_verify'>
                            
                            <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                                <Label className="me-sm-2" for="mobile_otp">Mobile OTP</Label>
                                <Input type="number" className='inputs' id="mobile_otp" placeholder="1234" />
                                <Button type='button' id="mobile_otp_verify_button" className='submit-button' onClick={() => verifyOtp('sms')}>Verify OTP</Button>
                            </FormGroup>
                        </div>
                    )}
                                                {isOtpVerified && (

                        <Button className='submit' onClick={updateSellerinfo}>
                            Submit
                        </Button>
                         )}
                    </div>
                </div>
            </div> 
        </>
    );
}

export default SellerInfo;
