import React, { useEffect, useState } from 'react';
import { Button, CardBody, Card, Form, FormGroup, Label, Input, CardHeader, Col,Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import envConfig from '../config/envConfig';
import { Html5QrcodeScanner } from "html5-qrcode"
const common = require('../utils/Common');

const Signup = (props) => {
	let navigate = useNavigate();
	
	const [referralStatus, setReferralStatus] = useState(0);
	const [referralMail, setReferralMail] = useState("");
	const [signupData, setSignupData] = useState({});
	const [timer, setTimer] = useState(60); 
	const [showQRScanner, setShowQRScanner] = useState(false);
	const [isTimerRunning, setIsTimerRunning] = useState(false);
	const [isTermsChecked, setIsTermsChecked] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [QRdata, setQRdata] = useState("");
	const [uniqueId, setUniqueId] = useState("**********");
	const [validatePan, setValidatePan] = useState(true);

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
	};
	const Qrsearch = async (searchString) => {
		var parameters = {
			"action": "read",
		  'id': searchString
		};
		var response = await common.apiRequest('post', '/users/QRRegId', parameters);
		const result = response.data.data.reg_id
		setQRdata(result)
		$('#referance_id').val(result);		
		checkReferralSendOtp();
	  };
	useEffect(() => {
		if (showQRScanner) {
			const scanner = new Html5QrcodeScanner('reader', {
			  qrbox: {
				width: 250,
				height: 250,
			  },
			  fps: 5,
			});
	  
			scanner.render(success, error);
	  
			function success(result) {
			  scanner.clear();
			  Qrsearch(result)
			  setShowQRScanner(false); 
			}  
			function error(err) {
			  console.warn(err);
			}
	  
			return () => {
			  scanner.clear();
			};
		  }
		
		document.title = "Signup"
		let interval;
		if (isTimerRunning) {
		  interval = setInterval(() => {
			setTimer(prevTimer => {
			  if (prevTimer === 1) {
				setIsTimerRunning(false);
				clearInterval(interval);
			  }
			  return prevTimer - 1;
			});
		  }, 1000);
		}
		return () => clearInterval(interval);
	}, [isTimerRunning,showQRScanner]);
	
	const nextStep = async (id) => {
		setIsTimerRunning(false);
		let processForm = 1;
		if (id == 1) {
			if ($('#email').val() == "") {
				common.alert("Please enter email id and verify it", 'error');
				return;
			}
			if (! await verifyOtp('mail')) {
				return;
			} else {
				setIsTimerRunning(false);
				setSignupData({...signupData, email : $('#email').val() });
			}
		} else if (id == 2) {
			if ($('#referance_id').val() == "") {
				common.alert("Please enter reference id and verify it", 'error');
				return;
			}
			if (referralStatus == 1) {
				if (! await verifyOtp('referral')) {
					return;
				}
			}
		} else if (id == 3) {
			if ($('#mobile_phone').val() == "") {
				common.alert("Please enter mobile no and verify it", 'error');
				return;
			}
			if (! await verifyOtp('sms')) {
				return;
			} else {
				setIsTimerRunning(false);
				setSignupData({...signupData, mobile_phone : $('#mobile_phone').val()});
				var panVerification = await common.getSystemConfig('pan_verification', 'web', true);
				console.log(panVerification);
				if (panVerification && panVerification.active) {
					setValidatePan(true);	
				} else {
					setValidatePan(false);
				}
			}
		}else if (id==4){
			if (!isTermsChecked) {
				common.alert("Please accept the Terms and Conditions", 'error');
				return;
			} 
		}
		 else if (id==5){
			 if (!filePath) {
				common.alert("Please upload PAN first", 'error');
				return;
			} else if ($('#pan_no').val() == "" || $('#password').val() == "" || $('#confirm_password').val() == "" || $('#first_name').val() == "" || $('#last_name').val() == "") {
				common.alert("Please fill all fields", 'error');
				return;
			}
			signupData.first_name = $('#first_name').val();
			signupData.last_name = $('#last_name').val();
			signupData.middle_name = $('#middle_name').val();
			signupData.password = $('#password').val();
			signupData.confirm_password = $('#confirm_password').val();
			signupData.pan_no = $('#pan_no').val();
			signupData.pan_status = "1";
			signupData.active = "1";
			signupData.user_pan_image = filePath;
			signupData.checkAccountExists = true;
			signupData.reg_id = null;
			var response = await common.apiRequest('post', '/users/signup', signupData);
			if (response.status == 200) {
				if (response.data.code == "info") {
					var createAnother = window.confirm(response.data.message);
					if (createAnother) {
						signupData.reg_id = response.data.reg_id;
						signupData.checkAccountExists = false;
						response = await common.apiRequest('post', '/users/signup', signupData);
						if (response.data.code == 'success') {
							common.alert("Account created");
							setUniqueId(response.data.data.reg_id ? response.data.data.reg_id : '**********');
						} else {
							common.alert(response.data.error, 'error');
							navigate('/signup');
						}
					} else {
						navigate('/login');
					}
				} else if (response.data.code == 'success') {
					common.alert("Account created");
					setUniqueId(response.data.data.reg_id ? response.data.data.reg_id : '**********');
				} else {
					common.alert(response.data.error, 'error');
					navigate('/signup');
				}
			} else {
				common.alert("Something went wrong", 'error');
				navigate('/signup');
			}
		}
		if (processForm == 1) {
			$('#step-'+id).hide();
			$('#step-'+(id+1)).show();
		}
	}

	$(document).ready(function() {
		$('#mobile_phone').on('input', function() {
		  var maxDigits = 10;
		  var mobilePhone = $(this).val().replace(/\D/g, ''); 
	  
		  if (mobilePhone.length > maxDigits) {
		
			mobilePhone = mobilePhone.substring(0, maxDigits);
			$(this).val(mobilePhone);
		  }
		});
	});
	
	const checkReferralSendOtp = async () => {
		var referral_code = $('#referance_id').val();
		if (referral_code.length > 19) {
			let params = {
				'referral_code' : referral_code
			}
			let response = await common.apiRequest('post', '/users/check-referral-send-otp', params);
			if (response.data.code == 'success') {
				common.alert(response.data.message);
				setSignupData({...signupData, referrer : response.data.referrer });
				setReferralStatus(1);
				setReferralMail(response.data.referral_mail);
				setIsTimerRunning(true);
				setTimer(60);
			} else {
				common.alert(response.data.error, 'error');
			}
		}
	}

	const verifyOtp = async (type) => {
		if ((type == "mail" && $('#email_otp').val() == "") || (type == "referral" && $('#reference_otp').val() == "")) {
			common.alert("Please enter OTP received on mail", 'error');
			return false;
		} else if (type == "sms" && $('#mobile_otp').val() == "") {
			common.alert("Please enter OTP received on sms", 'error');
			return false;
		}
		let otpData = {
			"phone" : (type == 'mail' ? $('#email').val() : (type == "referral" ? referralMail : $('#mobile_phone').val())),
			"channel" : type == "referral" ? 'mail' : type,
			"code" : (type == 'mail' ? $('#email_otp').val() : (type == "referral" ? $('#reference_otp').val() : $('#mobile_otp').val()))
		}
		let response = await common.apiRequest('post', '/users/verify-otp', otpData);
		if (response.data.code == 'success') {
			common.alert(response.data.message);
			return true;
		} else {
			common.alert(response.data.error, 'error');
			return false;
		}
	}

	const verifyPan = async (panNo) => {
		if (!validatePan) {
			return;
		}
		if (panNo.length == 0) {
			setPanResponse('pan_response', 'text-success', 'text-danger', 'Please Enter Pan No', false);
			setFirstAndLastName('', '');
		} else if (panNo.length == 10) {
			let data = {
				'pan_no': panNo
			}
			let response = await common.apiRequest('post', '/users/verify-pan', data);
			if (response.data.isValid) {
				setPanResponse('pan_response', 'text-danger', 'text-success', 'Valid PAN', true);
				setFirstAndLastName(response.data.firstName, response.data.lastName, response.data.middleName);
			} else if (response.data.message) {
				setPanResponse('pan_response', 'text-success', 'text-danger', response.data.message, false);
				setFirstAndLastName('', '');
			} else {
				setPanResponse('pan_response', 'text-success', 'text-danger', 'Invalid PAN', false);
				setFirstAndLastName('', '');
			}
		} else {
			setPanResponse('pan_response', 'text-success', 'text-danger', 'Invalid PAN', false);
			setFirstAndLastName('', '');
		}
	}

	const setPanResponse = (id, remove, add, message, verifyResponse) => {
		$('#'+id).removeClass(remove).addClass(add).html(message);
	}

	const setFirstAndLastName = (firstName, lastName, middleName) => {
		if (!firstName || !lastName) {
			let names = !firstName ? lastName.split(" ") : firstName.split(" ");
			if (names.length > 1) {
				firstName = names[0];
				middleName = names[1]; 
				names.shift();
				lastName = names.join(" ");
			} else {
				if (!firstName) {
					firstName = names.join(" ");
				} else {
					lastName = names.join(" ");
				}
			}
		}
		$('#first_name').val(firstName);
		$('#middle_name').val(middleName); 
		$('#last_name').val(lastName);
	}

	if (localStorage.getItem('token')) {
		navigate('/signup');
	}

	const sendOtp = async (type, referral = 0) => {
		var otpData = {};
		if (type == "mail") {
			otpData.phone = referral ? referralMail : $('#email').val();
		} else if (type == "sms") {
			otpData.phone_no = $('#mobile_phone').val()
		}
		otpData.channel = type;
		let response = await common.apiRequest('post', '/users/send-otp', otpData);
		if (response.data.code == 'success') {
			common.alert(response.data.message);
		} else {
			common.alert(response.data.error, 'error');
		}
		setIsTimerRunning(true);
		setTimer(60);
	}

	const [filePath, setFilePath] = useState("");

	const uploadPanImage = async (file) => {
        var fileData = new FormData();
        fileData.append('user_pan_image', file);
        var response = await common.apiRequest('post', '/users/upload-pan-image', fileData);
		setFilePath(response.data.message);
    }
	const MyBackgroundImage ="assets/img/Back.jpeg";

	const toggleQRScanner = () => {
		setShowQRScanner(!showQRScanner);
	  };

	return (
		<div className='container-fluid d-flex align-items-center justify-content-center' style={{backgroundImage: `url(${MyBackgroundImage})`,backgroundSize: 'cover',backgroundPosition: 'center',backgroundRepeat: 'no-repeat',height:'100vh'}}>
			<Col className='cards' >
			<Card className='cardbody col-md-4 col-sm-10 col-xs-12' style={{marginTop:'20px', maxHeight: '80vh', overflowY: 'auto'}}>
					<CardHeader className='card-headers'>Sign Up</CardHeader>
					<CardBody>
						<div className='btm-border'></div>
						<div id='step-1'>
						<FormGroup className="mb-2 me-sm-2 mb-sm-0">
				                <Label className="me-sm-2" for="email">E Mail</Label>
				                <Input type="email" className='inputs' id="email" placeholder="abc@email.com" />
								{isTimerRunning ? (
									<p>Resend in {timer} seconds</p>
								) : (<Button type='button' id="mail_otp_verify_button" className='submit-button' onClick={() => sendOtp('mail')}>Send OTP</Button> )}
						    </FormGroup>
							<FormGroup className="mb-2 me-sm-2 mb-sm-0">
				                <Label className="me-sm-2" for="email_otp"> E Mail OTP</Label>
				                <Input type="number" className='inputs' id="email_otp" placeholder="1234" />
				            </FormGroup>
							
				            <Button type='button' className='submit-button' onClick={() => nextStep(1)}>Proceed</Button>
          				</div>

          				<div id='step-2' style={{display:'none'}}>
				         
							<p>Congratulations your Details have been validated !</p>
			          		<p>Now please enter your Referer Detail</p>
			          		<FormGroup className="mb-2 me-sm-2 mb-sm-0">
			                	<Label className="me-sm-2" for="referance_id"> Referance ID</Label>
			                	<Input type="text" className='inputs' id="referance_id" onInput={checkReferralSendOtp} placeholder="JOHNDOE" />
								{isTimerRunning ? (
									<p>Resend in {timer} seconds</p>
								) : referralMail ? (<Button type='button' id="mail_otp_verify_button" className='submit-button' onClick={() => sendOtp('mail', 1)}>Send OTP</Button> ) : ''}
			            	</FormGroup>
							<button className="btn btn-warning search_button-QR" onClick={toggleQRScanner}>
								<i className="fa fa-qrcode"></i> Scan QR Code
							</button>
							<div className="Qrcode" style={{ display: showQRScanner ? 'block' : 'none' }}>
								<div id="reader"></div>
							</div>
			            	<FormGroup className="mb-2 me-sm-2 mb-sm-0">
			                	<Label className="me-sm-2" for="reference_otp">OTP</Label>
			                	<Input type="number" className='inputs' id="reference_otp" placeholder="1234" />
			            	</FormGroup>
							<Button type='button' className='submit-button' onClick={() => nextStep(2)}>Proceed</Button>
					  	</div>

						  <div id='step-3' style={{display:'none'}}>
							<FormGroup className="mb-2 me-sm-2 mb-sm-0">
				                <Label className="me-sm-2" for="mobile_phone">Mobile No</Label>
				                <Input type="number" className='inputs' id="mobile_phone" placeholder="9999999999" />
								{isTimerRunning ? (
									<p>Resend in {timer} seconds</p>
								) : (<Button type='button' id="mobile_otp_verify_button" className='submit-button' onClick={() => sendOtp('sms')}>Send OTP</Button> )}

				            </FormGroup>
				          	<FormGroup className="mb-2 me-sm-2 mb-sm-0">
				                <Label className="me-sm-2" for="mobile_otp"> Mobile OTP</Label>
				                <Input type="number" className='inputs' id="mobile_otp" placeholder="1234" />
				            </FormGroup>
			            	<Button type='button' className='submit-button' onClick={() => nextStep(3)}>Proceed</Button>
			            </div>
						<div id='step-4' style={{display:'none'}} className='step-4'>
						<div dangerouslySetInnerHTML={{__html:props.cmsData.terms_and_condition_customer}} />
						<FormGroup check>
								<Label check>
									<Input type="checkbox" checked={isTermsChecked} onChange={(e) => setIsTermsChecked(e.target.checked)} />{' '}
									I accept the Terms and Conditions
								</Label>
							</FormGroup>
							<Button type='button' className='submit-button' onClick={() => nextStep(4)}>Proceed</Button>
						</div>
						<div id='step-5' style={{display:'none'}}>
							<FormGroup className="mb-2 me-sm-2 mb-sm-0">
				                <Label className="me-sm-2" for="first_name">First Name</Label>
				                <Input type="text" className='inputs' id="first_name" placeholder="Bob" disabled={validatePan}/>
				            </FormGroup>
							<FormGroup className="mb-2 me-sm-2 mb-sm-0">
				                <Label className="me-sm-2" for="middel_name">Middle Name</Label>
				                <Input type="text" className='inputs' id="middle_name" placeholder="Josaf" disabled={validatePan}/>
				            </FormGroup>
				            <FormGroup className="mb-2 me-sm-2 mb-sm-0">
				                <Label className="me-sm-2" for="last_name">Last Name</Label>
				                <Input type="text" className='inputs' id="last_name" placeholder="Josaf" disabled={validatePan}/>
				            </FormGroup>
				            <FormGroup className="mb-2 me-sm-2 mb-sm-0">
				                <Label className="me-sm-2" for="pan_no">PAN No</Label>
				                <Input type="text" className='inputs' id="pan_no" placeholder="ABCDE1234G" onInput={(e) => verifyPan(e.target.value)} />
								<span id="pan_response"></span>
				            </FormGroup>
							<FormGroup className="mb-2 me-sm-2 mb-sm-0">
				                <Label className="me-sm-2" for="pan_no">PAN Photo/PDF</Label>
				                <Input type="file" id="pan_image" onChange={(e) => uploadPanImage(e.target.files[0])} />
				            </FormGroup>
							{
								filePath ? 
								<img className='pan-image' src={envConfig.api_url + filePath} />
								: ""
							}
							<FormGroup className="mb-2 me-sm-2 mb-sm-0">
				                <Label className="me-sm-2" for="password"> Password</Label>
				                <Input type="password" className='inputs' id="password" placeholder="*******" />
				            </FormGroup>
				            <FormGroup className="mb-2 me-sm-2 mb-sm-0">
				                <Label className="me-sm-2" for="confirm_password"> Confirm Password</Label>
				                <Input type="password" className='inputs' id="confirm_password" placeholder="*******" />
				            </FormGroup>
			            	<Button type='button' className='submit-button' onClick={() => nextStep(5)}>Proceed</Button>
			            </div>
			            <div id='step-6' style={{display:'none'}}>
			            	<p>Congratulations  now you are a Family member of SR3 World ! And your Unique Member id is {uniqueId}. Welcome to SR3 World .</p>
          					<a href="/login"><Button type='button' className='submit-button'>Login Now</Button></a>
          				</div>
	        		</CardBody>
		    	</Card>
		  	</Col>
	  	</div>
	);
}

export default Signup;