import React, { useEffect, useState, useMemo } from 'react';
import { Modal, ModalHeader, ModalBody, Input, FormGroup, Button, Label, Form, ModalFooter } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import './saller.css'
import { CSVLink } from "react-csv";
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import jsPDF from "jspdf";
import "jspdf-autotable";
import $ from 'jquery';
import moment from 'moment';
import { compressImage } from '../../utils/Compress';
import { exportToCSV, exportToExcel, exportToPDF } from '../../utils/Exports';
import { parseValue } from '../../utils/Common';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');

export default function SellerProduct() {
    const [products, setProducts] = useState([]);
    const [subProducts, setSubProducts] = useState([]);
    const [editdetails, setEditdetails] = useState([]);
    const [subProductImageUploads, setSubProductImageUploads] = useState({});
    const [modal, setModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [pincodeModal, setPincodeModal] = useState(false);
    const [pincodes, setPincodes] = useState([]);
    const toggle = () => setModal(!modal);
    const toggleEditModal = () => setEditModal(!editModal);
    const navigate = useNavigate();

    useEffect(() => {
        getProducts();
    }, []);

    const getProducts = async () => {
        let data = {
            'seller_id': localStorage.getItem('sellerId'),
            'action': "read",
            'start_date': $('#Date-from').val(),
            'end_date': $('#Date-to').val(),
        }
        let response = await common.apiRequest('post', '/seller/get-products', data);
        if (response.data.code == 'success') {
            setProducts(response.data.data);
        } else {
            setProducts([]);
        }
    }

    const showSubProducts = (subProducts) => {
        setSubProducts(subProducts);
        setEditdetails(subProducts)
        toggle();
    }

    const showEditDetails = (product) => {
        setEditdetails([product]);
        toggleEditModal();
    }

    const uploadSubProductImages = async (productId) => {
        if (!subProductImageUploads[productId] || subProductImageUploads[productId].length === 0) {
            common.alert('No images selected for upload.');
            return;
        }

        var fileData = new FormData();
        for (const file of subProductImageUploads[productId]) {
            const compressedFile = await compressImage(file);
            fileData.append('sub_product_images', compressedFile);
        }
        fileData.append('product_id', productId);

        var response = await common.apiRequest('post', '/seller/update-sub-product-images', fileData);
        common.alert(response.data.message);

        setSubProductImageUploads(prev => ({...prev, [productId]: []}));

        const updatedSubProducts = subProducts.map(product => 
            product.id === productId 
                ? {...product, product_image: response.data.updated_images} 
                : product
        );
        setSubProducts(updatedSubProducts);
    }

    const handleFileChange = (event, productId) => {
        const files = Array.from(event.target.files);
        setSubProductImageUploads(prev => ({...prev, [productId]: files}));
    }
    const togglePincodeModal = () => {
        setPincodeModal(!pincodeModal);
    }
    const viewPincodes = async (pincodes) => {
        pincodes = pincodes.split('--');
        pincodes[0] = pincodes[0].replaceAll('-', '');
        pincodes[pincodes.length - 1] = pincodes[pincodes.length - 1].replaceAll('-', '');
        let data = {
            'pincodes': pincodes,
        }
        let response = await common.apiRequest('post', '/seller/get-pincodes', data);
        if (response.data.code === 'success') {
            togglePincodeModal();
            setPincodes(response.data.data);
        } else {
            common.alert(response.data.error, 'error');
        }
    }
    const addDetail = async () => {
        var parameters = {
          'id': editdetails[0].id,
          'product_id': editdetails[0].product_id,
          'action': 'update',
          'product_sku': $('#product_sku').val(),
          'price': $('#price').val(),
          'hsn_code': $('#hsn_code').val(),
          'after_pack_item_weight': $('#after_pack_item_weight').val(),
          'item_weight': $('#item_weight').val(),
          'after_sale_services': $('#after_sale_services').val(),
          'faq': $('#faq').val(),
          'gst_percent': $('#gst_percent').val(),
          'supply_gst_no': $('#supply_gst_no').val(),
          'supply_pincode': $('#supply_pincode').val(),
          'supply_state': $('#supply_state').val(),
          'taxable_value': $('#taxable_value').val(),
          'use_or_utility': $('#use_or_utility').val(),
          'warranty_or_gurantee': $('#warranty_or_gurantee').val(),
          'legal_disclaimer': $('#legal_disclaimer').val(),
          'qty_in_stock': $('#qty_in_stock').val(),
          'active': 0
        };
        let response = await common.apiRequest('post', '/seller/get-products', parameters);
        if (response.status == 200) {
          if (response.data.code == "success") {
            common.alert(response.data.message);
          } else {
            common.alert(response.data.error, 'error');
          }
          navigate('/SHome');
        }
    }

    const deleteProdcut = async (product) => {
        var parameters = {
            'id': product.id,
            'seller_id': product.seller_id,
            'action': 'delete',
        };
        let response = await common.apiRequest('post', '/seller/get-products', parameters);
        if (response.status == 200) {
            if (response.data.code == "success") {
              common.alert(response.data.message);
            } else {
              common.alert(response.data.error, 'error');
            }
            navigate('/seller/products');
        }
        getProducts()
    }

    const exportPDF = () => {
        const unit = "pt";
        const size = "A1";
        const orientation = "portrait";
        const doc = new jsPDF(orientation, unit, size);
        doc.setFontSize(15);
        const title = "Product report";
        const headers = [["OrderId", "Name", "Description", "OrderPrice", "OrderQuantity", "OrderStatus", "OrderCompletedOn", "PaymentId", "PaymentStatus"]];
        const data = products.map(elt => [elt.order_id, elt.name, elt.description, elt.order_price, elt.order_quantity, elt.order_status, elt.order_completed_on,
        elt.order_cancelled_on, elt.payment_id, elt.payment_status]);
        let content = {
          startY: 50,
          head: headers,
          body: data
        };
        doc.text(title, 40, 40);
        doc.autoTable(content);
        doc.save("report.pdf")
    };

    const columnDefs = [
        { headerName: "S.no", valueGetter: "node.rowIndex + 1" },
        { 
            field: "product_image", 
            headerName: "Product Image",
            cellRenderer: params => {
                const images = params.data.product_items[0] && params.data.product_items[0].product_image ? 
                    params.data.product_items[0].product_image.split(',') : [];
                return(
                    <div className="d-flex justify-content-center mb-3">
                     {images.map((image, index) => (
                    <Zoom>
                        <img key={index} src={envConfig.api_url + image} alt="product" style={{ height: '50px', width: '50px', marginRight: '5px' }} />
                     </Zoom>
                    ))}
                
                </div>
                ) 

               
            }
        },
        { field: "name", headerName: "Product Name" },
        { field: "description", headerName: "Product Description" },
        { 
            field: "after_pack_item_weight", 
            headerName: "After Pack Weight",
            valueGetter: params => params.data.product_items[0]?.after_pack_item_weight
        },
        { 
            field: "after_sale_services", 
            headerName: "After Sale Services",
            valueGetter: params => params.data.product_items[0]?.after_sale_services
        },
        { 
            field: "faq", 
            headerName: "FAQ",
            valueGetter: params => params.data.product_items[0]?.faq
        },
        { 
            field: "gst_percent", 
            headerName: "GST Percent",
            valueGetter: params => params.data.product_items[0]?.gst_percent
        },
        { 
            field: "item_weight", 
            headerName: "Item Weight",
            valueGetter: params => params.data.product_items[0]?.item_weight
        },
        { 
            field: "legal_disclaimer", 
            headerName: "Legal Disclaimer",
            valueGetter: params => params.data.product_items[0]?.legal_disclaimer
        },
        { 
            field: "price", 
            headerName: "Net Price",
            valueGetter: params => parseValue (params.data.product_items[0]?.price, 'float')
        },
        { 
            field: "qty_in_stock", 
            headerName: "Quantity in Stock",
            valueGetter: params =>parseValue(params.data.product_items[0]?.qty_in_stock, 'float')
        },
        // { 
        //     field: "supply_gst_no", 
        //     headerName: "Supply GST No",
        //     valueGetter: params => params.data.product_items[0]?.supply_gst_no
        // },
        // { 
        //     field: "supply_pincode", 
        //     headerName: "Supply Pincode",
        //     valueGetter: params => params.data.product_items[0]?.supply_pincode
        // },
        // { 
        //     field: "supply_state", 
        //     headerName: "Supply State",
        //     valueGetter: params => params.data.product_items[0]?.supply_state
        // },
        { 
            field: "taxable_value", 
            headerName: "Taxable Value",
            valueGetter: params => parseValue(params.data.product_items[0]?.taxable_value, 'float')
        },
        { 
            field: "use_or_utility", 
            headerName: "Use or Utility",
            valueGetter: params => params.data.product_items[0]?.use_or_utility
        },
        { 
            field: "warranty_or_gurantee", 
            headerName: "Warranty or Guarantee",
            valueGetter: params => params.data.product_items[0]?.warranty_or_gurantee
        },
        { 
            field: "product_sku", 
            headerName: "Product SKU",
            valueGetter: params => params.data.product_items[0]?.product_sku
        },
        { 
            field: "hsn_code", 
            headerName: "HSN Code",
            valueGetter: params => params.data.product_items[0]?.hsn_code
        },
        {
            field: "created_at",
            headerName: "Product Date",
        },
        { 
            field: "active", 
            headerName: "Approval Status",
            valueGetter: params => params.data.product_items[0]?.active == "1" ? "Approved" : "Not Approved"
        },
        {
            headerName: "Pincodes",
            cellRenderer: (params) => (
                params.data.pincodes ? 
                <button className='btn btn-info btn-sm' onClick={() => viewPincodes(params.data.pincodes)}>View Pincodes</button>
                : "This product is not self deliverable"
            )
        },
        {
            headerName: "More Information",
            cellRenderer: params => (
                <div>
                    <button className='btn btn-primary btn-sm' onClick={() => showSubProducts(params.data.product_items)}>View</button>
                </div>
            )
        },
        {
            headerName: "Delete Products",
            cellRenderer: params => (
                <div>
                    <button className='btn btn-danger btn-sm ml-2' onClick={() => deleteProdcut(params.data)}>Delete</button>
                </div>
            )
        }
    ];

 
    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        resizable: true,
        floatingFilter: true,
        flex: 1,
        minWidth: 100,
    }), []);
    const subProductColumnDefs = [
        { field: "id", headerName: "Sub Product ID" },
        { 
            field: "product_image", 
            headerName: "Product Image",
            cellRenderer: params => {
                const images = params.value ? params.value.split(',') : [];
                return (
                    <div className="d-flex justify-content-center mb-3">
                    {images.map((image, index) => (
                            <Zoom>
                            <img key={index} src={envConfig.api_url + image} alt="sub product" style={{ height: '50px', width: '50px', marginRight: '5px' }} />

                            </Zoom>
                        ))}
                        <input 
                            type="file" 
                            multiple 
                            accept="image/*" 
                            onChange={(e) => handleFileChange(e, params.data.id)} 
                        />
                        {subProductImageUploads[params.data.id] && subProductImageUploads[params.data.id].length > 0 && (
                            <button 
                                className='btn btn-primary btn-sm' 
                                onClick={() => uploadSubProductImages(params.data.id)}
                            >
                                Upload Images
                            </button>
                        )}
                    </div>
                );
            }
        },
        { field: "price", headerName: "Net Price" },
        {
            headerName: "Actions",
            cellRenderer: params => (
                <button className='btn btn-primary btn-sm' onClick={() => showEditDetails(params.data)}>Edit</button>
            )
        }
    ];
    const data = products.map((data, index) => ({
        "Product Name": data.name,
        "Product Description": data.description,
        "After Pack Weight": data.product_items[0]?.after_pack_item_weight,
        "After Sale Services": data.product_items[0]?.after_sale_services,
        "FAQ": data.product_items[0]?.faq,
        "GST Percent": data.product_items[0]?.gst_percent,
        "Item Weight": data.product_items[0]?.item_weight,
        "Legal Disclaimer": data.product_items[0]?.legal_disclaimer,
        "Net Price": data.product_items[0]?.price,
        "Quantity in Stock": data.product_items[0]?.qty_in_stock,
        // "Supply GST No": data.product_items[0]?.supply_gst_no,
        // "Supply Pincode": data.product_items[0]?.supply_pincode,
        // "Supply State": data.product_items[0]?.supply_state,
        "Taxable Value": data.product_items[0]?.taxable_value,
        "Use or Utility": data.product_items[0]?.use_or_utility,
        "Warranty or Guarantee": data.product_items[0]?.warranty_or_gurantee,
        "Product SKU": data.product_items[0]?.product_sku,
        "HSN Code": data.product_items[0]?.hsn_code,
        "Product Date": data.product_items[0]?.created_at,
        "Approval Status": data.product_items[0]?.active == "1" ? "Approved" : "Not Approved"
    }));
    
    const handleExportCSV = () => {
        exportToCSV(data, 'products');
    }
    
    const handleExportExcel = () => {
        exportToExcel(data, 'products');
    }

    const handleExportPDF = () => {
        exportToPDF(data, 'products');
    }
        

    return (
        <>
            <div className="row">
                <div className='col-md-6'>
                    <h3>Seller Product</h3>
                </div>
                <div className='col-md-6'>
                    <div className='Allbuttons'>
                    <Link to='/seller/upload'><button className='btn button-product'>Add Product</button></Link>
                    <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
                    <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
                    <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>
                    </div>
                </div>
            </div>
            <Form className='row'>
                        <FormGroup className='col-md-4 row'>
                            <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                            <input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getProducts} className="form-control col-md-5" id="Date-from" name="Date-from"></input>
                        </FormGroup>
                        <FormGroup className='col-md-4 row'>
                            <Label className=" col-md-2 col-form-label" for="Date-to">To:</Label>
                            <input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getProducts} className="form-control col-md-5" id="Date-to" name="Date-to"></input>
                        </FormGroup>
                    </Form>
            <div className='row saller-margin'>
                <div className='col-md-12'>
                <div className="ag-theme-quartz" style={{ height: 600, width: '100%' }}>
                <AgGridReact
                    rowData={products}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    pagination={true}
                    paginationPageSize={10}
                    rowSelection="multiple"
                    sideBar={{ toolPanels: ['columns'] }}
                />
            </div>
                </div>
            </div>
            <Modal isOpen={modal} toggle={toggle} size="xl">
                <ModalHeader toggle={toggle}>Sub Products</ModalHeader>
                <ModalBody>
                    <div className="ag-theme-quartz" style={{ height: 400, width: '100%' }}>
                        <AgGridReact
                            rowData={subProducts}
                            columnDefs={subProductColumnDefs}
                            defaultColDef={defaultColDef}
                        />
                    </div>
                </ModalBody>
            </Modal>
                <Modal isOpen={pincodeModal} toggle={togglePincodeModal} size="md">
                    <ModalHeader toggle={togglePincodeModal}>Pincodes List</ModalHeader>
                    <ModalBody style={{ maxHeight: '400px', overflowY: 'auto' }}>
                        {
                            pincodes.map((pincode) => {
                                return (
                                    <>
                                        {pincode.pincode}&nbsp;&nbsp;
                                    </>
                                );
                            })
                        }
                    </ModalBody>      
                </Modal>

            <Modal isOpen={editModal} toggle={toggleEditModal} size="lg">
                            <ModalHeader toggle={toggleEditModal}>Edit Product Details</ModalHeader>
                          
                            <ModalBody>
                                 {Object.keys(editdetails).length > 0 ?(
                                    <Form>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new sku</Label>
                                                <Input
                                                    type='text' id="product_sku" defaultValue={editdetails[0].product_sku}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new price</Label>
                                                <Input
                                                    type='text' id="price" defaultValue={editdetails[0].price}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new hsn_code</Label>
                                                <Input
                                                    type='text' id="hsn_code" defaultValue={editdetails[0].hsn_code}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new after_pack_item_weight</Label>
                                                <Input
                                                    type='text' id="after_pack_item_weight" defaultValue={editdetails[0].after_pack_item_weight}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new item_weight</Label>
                                                <Input
                                                    type='text' id="item_weight" defaultValue={editdetails[0].item_weight}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new after_sale_services</Label>
                                                <Input
                                                    type='text' id="after_sale_services" defaultValue={editdetails[0].after_sale_services}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new faq</Label>
                                                <Input
                                                    type='text' id="faq" defaultValue={editdetails[0].faq}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new gst_percent</Label>
                                                <Input
                                                    type='text' id="gst_percent" defaultValue={editdetails[0].gst_percent}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new supply_gst_no</Label>
                                                <Input
                                                    type='text' id="supply_gst_no" defaultValue={editdetails[0].supply_gst_no}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new supply_pincode</Label>
                                                <Input
                                                    type='text' id="supply_pincode" defaultValue={editdetails[0].supply_pincode}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new supply_state</Label>
                                                <Input
                                                    type='text' id="supply_state" defaultValue={editdetails[0].supply_state}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new taxable_value</Label>
                                                <Input
                                                    type='text' id="taxable_value" defaultValue={editdetails[0].taxable_value}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new use_or_utility</Label>
                                                <Input
                                                    type='text' id="use_or_utility" defaultValue={editdetails[0].use_or_utility}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new warranty_or_gurantee</Label>
                                                <Input
                                                    type='text' id="warranty_or_gurantee" defaultValue={editdetails[0].warranty_or_gurantee}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new legal_disclaimer</Label>
                                                <Input
                                                    type='text' id="legal_disclaimer" defaultValue={editdetails[0].legal_disclaimer}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new qty_in_stock</Label>
                                                <Input
                                                    type='text' id="qty_in_stock" defaultValue={editdetails[0].qty_in_stock}
                                                />
                                            </FormGroup>
                                            <Button className='submit'  onClick={addDetail}>
                                              Submit
                                          </Button>
                                    </Form>
                                ):""}
                            </ModalBody>
                        </Modal>
        </>
    )
}