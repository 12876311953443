import React, { useState, useEffect, useMemo } from 'react';
import { Form, FormGroup, Label } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import moment from 'moment';
import $ from 'jquery';
import { exportToCSV, exportToExcel, exportToPDF } from '../../utils/Exports';
const common = require('../../utils/Common');

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const UserServiceAvailable = () => {
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    const parameters = {
      action: 'read',
      fromDate: $('#Date-from').val(),
      toDate: $('#Date-to').val(),
      userId: localStorage.getItem('userId'),
    };
    
    const response = await common.apiRequest('post', '/users/UserServiceDetails', parameters);
    const data = (response.data && response.data.data) ? response.data.data : [];
    
    const transformedData = data.flatMap(item => 
      item.order_items.map(orderItem => {
        const feedbackReplies = orderItem.feedbacks?.[0]?.feedback_replys || [];
        const sellerFeedback = feedbackReplies.find(reply => reply.feedback_reply_by === 'seller')?.feedback_reply || 'No response';
        const adminFeedback = feedbackReplies.find(reply => reply.feedback_reply_by === 'Admin')?.feedback_reply || 'No response';
        
        const queryReplies = orderItem.querys?.[0]?.querys_replys || [];
        const sellerQuery = queryReplies.find(reply => reply.query_reply_by === 'seller')?.query_reply || 'No response';
        const adminQuery = queryReplies.find(reply => reply.query_reply_by === 'Admin')?.query_reply || 'No response';

        return {
          Date: moment(item.created_at).format('YYYY-MM-DD HH:mm:ss'),
          Invoice_No: item.invoices?.[0]?.invoice_no || 'N/A',
          Service: orderItem.seller_service?.service_name || 'N/A',
          Seller_Name: `${item.user?.first_name || 'N/A'} ${item.user?.last_name || 'N/A'}`,
          Service_Taxable_Amount: orderItem.seller_service?.taxable_value || 'N/A',
          Service_Total_Amount: orderItem.seller_service?.price || 'N/A',
          Service_Status: orderItem.order_status || 'N/A',
          Feedback: orderItem.feedbacks?.[0]?.user_feedback || 'N/A',
          Seller_Feedback_Reply: sellerFeedback,
          Admin_Feedback_Reply: adminFeedback,
          Query: orderItem.querys?.[0]?.user_query || 'N/A',
          Seller_Query_Reply: sellerQuery,
          Admin_Query_Reply: adminQuery
        };
      })
    );
    
    setRowData(transformedData);
  };

  const columnDefs = useMemo(() => [
    { headerName: "S.no", valueGetter: "node.rowIndex + 1", width: 80 },
    { headerName: 'Date', field: 'Date', width: 150 },
    { headerName: 'Invoice No', field: 'Invoice_No', width: 150 },
    { headerName: 'Service', field: 'Service', width: 200 },
    { headerName: 'Seller Name', field: 'Seller_Name', width: 200 },
    { headerName: 'Service Taxable Amount', field: 'Service_Taxable_Amount', width: 200 },
    { headerName: 'Service Total Amount', field: 'Service_Total_Amount', width: 200 },
    { headerName: 'Service Status', field: 'Service_Status', width: 150 },
    { headerName: 'Feedback', field: 'Feedback', width: 200 },
    { headerName: 'Seller Feedback Reply', field: 'Seller_Feedback_Reply', width: 200 },
    { headerName: 'Admin Feedback Reply', field: 'Admin_Feedback_Reply', width: 200 },
    { headerName: 'Query', field: 'Query', width: 200 },
    { headerName: 'Seller Query Reply', field: 'Seller_Query_Reply', width: 200 },
    { headerName: 'Admin Query Reply', field: 'Admin_Query_Reply', width: 200 }
  ], []);

  const defaultColDef = useMemo(() => ({
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
  }), []);

  return (
    <>
      <div className="row">
        <div className="col-md-10">
          <br />
          <h2><center>Service Orders</center></h2>
          <br />
        </div>
      </div>
      <div className='row tables'>
        <div className='col-md-12'>
          <div className="col-md-12">
            <Form className='row'>
              <FormGroup className='col-md-4 row'>
                <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                <input
                  type='date'
                  defaultValue={moment().startOf('month').format('YYYY-MM-DD')}
                  onChange={getCategories}
                  className="form-control col-md-5"
                  id="Date-from"
                  name="Date-from"
                />
              </FormGroup>
              <FormGroup className='col-md-4 row'>
                <Label className="col-md-2 col-form-label" for="Date-to">To:</Label>
                <input
                  type='date'
                  defaultValue={moment().endOf('month').format('YYYY-MM-DD')}
                  onChange={getCategories}
                  className="form-control col-md-5"
                  id="Date-to"
                  name="Date-to"
                />
              </FormGroup>
            </Form>
          </div>
          <div>
            <button className='btn button-product' onClick={() => exportToCSV(rowData, 'userService')}>
              Export to CSV
            </button>
            <button className='btn button-product' onClick={() => exportToExcel(rowData, 'userService')}>
              Export to Excel
            </button>
            <button className='btn button-product' onClick={() => exportToPDF(rowData, 'userService')}>
              Export to PDF
            </button>
          </div>
          <div className='ag-theme-alpine' style={{ height: 500, width: '100%' }}>
            <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              pagination={true}
              paginationPageSize={10}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserServiceAvailable;