import CustomButton from "./customButton";
import menus from "./menu";
const common = require('../../utils/Common');

class ActionProvider {
    apiResponse = null;
    apiWhere = null;
    customerEscalation = false;
    constructor(createChatBotMessage, setStateFunc, createCustomMessage) {
      this.createChatBotMessage = createChatBotMessage;
      this.setState = setStateFunc;
      this.createCustomMessage = createCustomMessage;
    }
  
    handleHello() {
      this.showMenu('entry_point');
    }
  
    handleHelp() {
      const message = this.createChatBotMessage("Sure! I can help you with anything. Please ask.");
      this.setState((prevState) => ({
        ...prevState,
        messages: [...prevState.messages, message],
      }));
    }
  
    handleDefault(message) {
      this.showMenu("customer_support_other_issue");
    }
  
    handleError() {
      const message = this.createChatBotMessage("Sorry, I am not able to help in this for now.");
      this.setState((prevState) => ({
        ...prevState,
        messages: [...prevState.messages, message],
      }));
    }
  
    showMenu(actionKey) {
      const currentMenu = menus[actionKey];
  
      if (!currentMenu) {
        this.handleError();
      } else {
        // Add the initial message
        const botMessage = this.createChatBotMessage(currentMenu.message, { withAvatar: true });
        this.setState((prevState) => ({
          ...prevState,
          messages: [...prevState.messages, botMessage],
        }));

        if (this.apiResponse) {
            if (Array.isArray(this.apiResponse)) {
                this.apiResponse.forEach(item => {
                    var text = '';
                    for (const [key, value] of Object.entries(item.data)) {
                        const formattedKey = key.replaceAll('_', ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
                        const formattedValue = value.replaceAll('_', ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
                        text += `${formattedKey}: ${formattedValue}<br/>`;
                    }
                    const customButtonMessage = this.createChatBotMessage(
                        <CustomButton
                            buttonText={text}
                            onClick={() => this.handleButtonClick(currentMenu, item.where)}
                        />
                    );
                    this.setState((prevState) => ({
                        ...prevState,
                        messages: [...prevState.messages, customButtonMessage],
                    }));
                });
            } else if (typeof this.apiResponse === 'string') {
                const textMessage = this.createChatBotMessage(this.apiResponse);
                this.setState((prevState) => ({
                    ...prevState,
                    messages: [...prevState.messages, textMessage],
                }));
            }
        }        
  
        // Add buttons for each menu item
        if (currentMenu.menu) {
          currentMenu.menu.forEach((button) => {
            const customButtonMessage = this.createChatBotMessage(
              <CustomButton
                buttonText={button.name}
                onClick={() => this.handleButtonClick(button)}
              />
            );
            this.setState((prevState) => ({
              ...prevState,
              messages: [...prevState.messages, customButtonMessage],
            }));
          });
        }
      }
    }
  
    async handleButtonClick(button, parameter = null) {
      if (button.saveWhere) {
        this.apiWhere = parameter;
      }
      if (button) {
          if (button.api) {
              var parameters = {};
              if (parameter) {
                parameters.where = parameter;
              } else if (this.apiWhere) {
                parameters.where = this.apiWhere
              }
              if (button.value) {
                parameters.update_value = button.value;
              }
              let response = await common.apiRequest('post', '/chatbot/'+button.api, parameters);
              if (response.data.code === "success") {
                  this.apiResponse = response.data.message ? response.data.message : response.data.data;
              } else {
                  this.apiResponse = "Something went wrong";
              }
          } else {
              this.apiResponse = null;
          }
          this.showMenu(button.action);
      }
    }
}

export default ActionProvider;