import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Html5QrcodeScanner } from "html5-qrcode"
import { Modal, ModalHeader, ModalBody, Input, FormGroup, Button, Label, Form, ModalFooter } from 'reactstrap';
import './nav.css'
import $ from 'jquery';

const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');

function NavbarView(props) {
  let navigate = useNavigate();
  const [scanRsult, setScanResult] = useState(null)
  const [showQRScanner, setShowQRScanner] = useState(false);
  const [modal, setModal] = useState(false);

  const logoutUser = async () => {
    if (localStorage.getItem('token')) {
      let data = {
        "access_token": localStorage.getItem('token')
      }
      let response = await common.apiRequest('post', '/users/logout', data);
      if (response.status == 200) {
        if (response.data.code == 'success') {
          localStorage.removeItem('token');
          localStorage.removeItem("userId");
          localStorage.removeItem("userType");
          localStorage.removeItem("sellerId");
          localStorage.removeItem("cartItems");
          localStorage.removeItem("address_alert_date");
          common.alert(response.data.message);
        } else {
          common.alert(response.data.error, 'error');
        }
      }
      else {
        common.alert('Something went wrong', 'error');
      }
    }
    navigate('/login');
  }

  const goToBusiness = async () => {
    if (localStorage.getItem('userType') && localStorage.getItem('userType') == "buyer") {
      navigate('/Business');
    } else if (localStorage.getItem('userType') && localStorage.getItem('userType') == "seller") {
      navigate('/SHome');
    } else {
      navigate('/home');
    }
  }
  const searchProduct = async () => {
    let searchString = $('#search').val().trim(); // Get the value and remove leading/trailing whitespaces
  
    if (searchString === "") {
      common.alert("Please enter a product.", 'error');
      return; // Exit the function if regId is empty
    }
    var parameters = {
      queryString: searchString
    }
    var response = await common.apiRequest('post', '/products/search-product', parameters);
    response['data']['searchString'] = searchString;
    navigate('/search', { state: response.data ,replace: true});
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchProduct();
    }
  }
  const searchVendor = async (nearMe = 0) => {
    const regId = $('#vendor_search').val().trim();
  
    if (regId === "") {
        common.alert("Please enter a registration ID or nature of business", 'error');
        return;
    }
  
    let parameters = {
        'type': "vendor",
        'reg_id': regId,
        'near_me': nearMe
    };

    if (nearMe && navigator.geolocation) {
        // Create a promise for the geolocation request
        const getLocation = new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    resolve({ latitude, longitude });
                },
                (error) => {
                    common.alert('Error getting user location', 'error');
                    reject(error);
                }
            );
        });

        try {
            // Wait for the location to be fetched
            const { latitude, longitude } = await getLocation;
            parameters.lat = latitude;
            parameters.lon = longitude;
        } catch (error) {
            console.error('Failed to fetch user location:', error);
            return; // Exit the function if location fetch fails
        }
    }
  
    try {
        // Make the API request
        const response = await common.apiRequest('post', '/products/search-product', parameters);
        navigate('/search', { state: {data:response.data, nearBy: nearMe}, replace: true }); // Using 'replace' to avoid stack of navigations
        setModal(false);
    } catch (error) {
        console.error('Failed to make API request:', error);
    }
};
  


  const Qrsearch = async (searchString) => {
    var parameters = {
      'type': "vendor",
      'id': searchString
    };
  
    var response = await common.apiRequest('post', '/products/search-product', parameters);
    response.data.searchString = searchString;
    navigate('/search', { state: response.data });
    setModal(false);
  };
  
  
  useEffect(() => {
    if (showQRScanner) {
      const scanner = new Html5QrcodeScanner('reader', {
        qrbox: {
          width: 250,
          height: 250,
        },
        fps: 5,
      });

      scanner.render(sucess, error);

      function sucess(result) {
        scanner.clear();
        Qrsearch(result)
        setScanResult(result);
      }  
        function error(err) {
        console.warn(err);
      }

      return () => {
        scanner.clear();
      };
    }
  }, [showQRScanner]);

  const toggleQRScanner = () => {
    setShowQRScanner(!showQRScanner);
  };
  const toggle = () => setModal(!modal);
  const showSubProducts = () => {
    toggle();
  }
  return (
    <>
<nav className="navbar navbar-expand navbar-dark bg-dark">
  <div className="container-fluid">
    <div className="row w-100 g-2 align-items-center">
      <div className="col-12 col-lg-8 d-flex">
        <Link className="navbar-brand me-2" to="/home">
          <img src={envConfig.api_url + props.logo} className='logo-image' alt="Logo" />
        </Link>
        <div className="search-container mx-auto">
          <div className="input-group">
            <input className='form-control' type="text" placeholder="Search for product" id="search" onKeyDown={handleKeyDown}/>
            <button className='btn btn-warning' onClick={() => searchProduct()}>
              <i className="fa fa-search" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-4">
        <div className="d-flex justify-content-between align-items-center">
          <ul className="navbar-nav justify-content-end flex-row">
            <li className="nav-item" onClick={() => showSubProducts()}>
              <Link className="nav-link">Vendor</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Service">Services</Link>
            </li>
            <li className="nav-item">
              <a className="nav-link" onClick={goToBusiness}>Business</a>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/my-account">User</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/cart">Cart</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" onClick={logoutUser}>Logout</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</nav>

      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Do vendor search here</ModalHeader>

        <ModalBody>
          <div class="search-container mt-1">
            <input class='searchbarmain' type="text" className='form-control' placeholder="Search for vendor products" id="vendor_search" />
            <button class='btn btn-warning search_button' onClick={() => searchVendor(1)}>
              <i class="fa fa-search" aria-hidden="true"></i>&nbsp;&nbsp;Near Me
            </button>
            <button class='btn btn-warning search_button' onClick={() => searchVendor(0)}>
              <i class="fa fa-search" aria-hidden="true"></i>
            </button>
            <button className="btn btn-warning search_button-QR" onClick={toggleQRScanner}><i class="fa fa-qrcode"></i></button>
            <div className="Qrcode" style={{ display: showQRScanner ? 'block' : 'none' }}>
              <div id="reader"></div>
            </div><br/>
            <span className='text-danger'>Note: Enter <b>Reg ID</b> in case of seller product search and <b>Nature Of Business</b> in case of search near me</span><br/><br/>
          </div>
        </ModalBody>
      </Modal>
    </>



  )
}

export default NavbarView;