import { createChatBotMessage } from "react-chatbot-kit";
import CustomButton from "../components/chatbot/customButton";

const config = {
  botName: 'SR³ Chatbot',
  initialMessages: [createChatBotMessage(`Hello ! Type hello to start the chat...`)],
  customComponents: {
    button: (props) => <CustomButton {...props} />, // Use custom button for rendering
  },
  customStyles: {
    botMessageBox: {
      backgroundColor: '#FFCC00', // Bot message box color
    },
    chatButton: {
      backgroundColor: '#FFCC00', // Chat button color
    },
  },
}

export default config;