import React, { useState, useEffect } from 'react';
import ReactBotKit, { createChatBotMessage } from 'react-chatbot-kit';  // Import ReactBotKit
import 'react-chatbot-kit/build/main.css';
import MessageParser from './messageParser';
import ActionProvider from './actionProvider';
import botConfig from '../../config/chatBotConfig';

const Chatbot = () => {
  return (
    <div>
      <ReactBotKit
        config={botConfig}
        messageParser={MessageParser}
        actionProvider={ActionProvider}
      />
    </div>
  );
};

export default Chatbot;
