import React, {useState, useEffect} from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import './yourorder.css'
import { Input, FormGroup, Button, Label } from 'reactstrap';
import Modal from 'react-bootstrap/Modal';
import $ from 'jquery';
import { exportToCSV, exportToExcel, exportToPDF } from '../../utils/Exports';

const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');

function YourOrdersView() {
    const navigate = useNavigate();
    const attributes = useParams();
    const handleClose = () => setShow(false);
    const handleCloses = () => setShows(false);
    const [orders, setOrders] = useState([]);
    const [show, setShow] = useState(false);
    const [shows, setShows] = useState(false);
    const [orderId, setOrderId] = useState(false);
    const [sellerId, setSellerId] = useState(false);
    const [showCancel, setShowCancel] = useState([]);
    const [showReturn, setShowReturn] = useState([]);
    const [showReplace, setShowReplace] = useState([]);

    const feedbackType =[
        "Order","Service","Logistics"
    ]
    const [orderProgressModal, setOrderProgressModal] = useState(false);
    const [orderItemsData, setOrderItemsData] = useState([]);
    useEffect(() => {
        getOrders();
    }, []);

    const getOrders = async(filterOrderId = null) => {
        let data = {
            "user_id": localStorage.getItem('userId'),
            "order_id": attributes.order_id != "null" && filterOrderId != 0 ? attributes.order_id : filterOrderId
        }
        let response = await common.apiRequest('post', '/orders/get-orders', data);
        if (response && response.data && response.data.code == "success") {
            let orders = response.data.data ? response.data.data : [];
            setOrders(orders);
            let config = await common.getSystemConfig('show_order_status_buttons');
            if (config) {
                setShowCancel(config.show_cancel ?? []);
                setShowReplace(config.show_replace ?? []);
                setShowReturn(config.show_return ?? []);
            }
        }
    };

    const changeOrderStatusOperations = async (orderId, orderStatus) => {
        let data = {
            "order_id": orderId,
            "order_status": orderStatus
        }
        let response = await common.apiRequest('post', '/orders/change-order-status-operations', data);
        if (response.data.code == "success") {
            common.alert(response.data.message.replaceAll("_", " "));
            getOrders();
            showOrderProgressModal([]);
        }
    }

    const generateInvoice = async (orderId, sellerIds) => {
        let response = await common.getInvoiceData(sellerIds, orderId, ['seller_product', 'logistics']);
        navigate(
            '/generate-invoice',
            {
              state: {
                invoiceData: response
              }
            }
        )
    }
    const generateReturnInvoice = async (orderId, sellerIds) => {
        let response = await common.getInvoiceData(sellerIds, orderId, ['return_order'],1);
        navigate(
            '/generate-return-invoice',
            {
              state: {
                invoiceData: response
              }
            }
        )
    }

    const updateNoReturn = async (e, orderId) => {
        let data = {
            'no_return': parseInt(e.target.value),
            'id': orderId
        }
        let response = await common.apiRequest('post', '/orders/update-no-return', data);
        if (response.data.code == "success") {
            common.alert(response.data.message.replaceAll("_", " "));
            getOrders();
            showOrderProgressModal([]);
        } else {
            common.alert(response.data.error, 'error');
        }
    }

    const handleShow = (order_items_id,seller_id) =>{
        setShow(true);
        setOrderId(order_items_id)
        setSellerId(seller_id)
    } 
    const handleShowQuery = (order_items_id,seller_id) =>{
        setShows(true);
        setOrderId(order_items_id)
        setSellerId(seller_id)

    }
    
    const showOrderProgressModal = (orderItemsData) =>{
        setOrderProgressModal(!orderProgressModal);
        setOrderItemsData(orderItemsData);
    }

    const sendFeedBack = async () => {
        var parameters = {
            'user_id': localStorage.getItem('userId'),
            'user_feedback':  $('#feedback').val(),
            'action': 'create',
            'order_id': orderId,
            'seller_id': sellerId,
            'type': $('#feedback_type').val(),
        };
        try {
            let response = await common.apiRequest('post', '/users/user-feedback', parameters);
            if (response.data.code === "success") {
                common.alert(response.data.message);
                setShow(false)

            } else {
                common.alert(response.data.error, 'error');
            }
        } catch (error) {
            common.alert('Error fetching data:', error);
        }
    };
    
    const sendQuery = async () => {
        var parameters = {
            'user_id': localStorage.getItem('userId'),
            'user_query':  $('#query').val(),
            'action': 'create',
            'order_id': orderId,
            'seller_id': sellerId,
            'type': $('#Query_type').val(),
        };
        try {
            let response = await common.apiRequest('post', '/users/user-query', parameters);
            if (response.data.code === "success") {
                common.alert(response.data.message);
                setShows(false)
            } else {
                common.alert(response.data.error, 'error');
            }
        } catch (error) {
            common.alert('Error fetching data:', error);
        }
    };

    const filterOrder = () => {
        let orderId = $('#filter_order_id').val();
        if (orderId.length == 0) {
            getOrders(0);
        } else if (orderId.length >= 16) {
            getOrders(orderId);
        }
    }
    const formatDecimal = (value) => {
        if (value === null || value === undefined || value === '') {
            return '0.00';
        }
        const numValue = typeof value === 'string' ? parseFloat(value) : value;

        if (isNaN(numValue)) {
            return '0.00';
        }
        return Number(Math.round(numValue + 'e2') + 'e-2').toFixed(2);
    };
    console.log(orders)
    const data = orders.flatMap((item, index) => {
        return item.order_items.map((orderItem) => {
            const isService = orderItem.service_id > 0;
            const isOffline = orderItem.offline_sale_id > 0;
            
            // Find seller product invoice
            const invoice = item.invoices.find(invoice => 
                invoice.invoice_type === "seller_product" && invoice.seller_id === orderItem.seller_id
            );
    
            // Find logistics invoice - Match with logistics_vendor_id
            const logisticsInvoice = item.invoices.find(invoice => 
                invoice.seller_id === orderItem.logistics_vendor_id && 
                invoice.invoice_no?.startsWith('LG')  // Match logistics invoice prefix
            );
    
            // Find return order invoice if exists
            const returnInvoice = item.invoices.find(invoice => 
                invoice.seller_id === orderItem.seller_id && 
                invoice.invoice_no?.startsWith('UG')  // Match return invoice prefix
            );
    
            const latestTracking = orderItem.order_trackings?.[0];
            const deliveryStatus = latestTracking ? `${latestTracking.status} - ${latestTracking.remarks}` : 0;
            
            let itemDescription = 0;
            if (isOffline) {
                itemDescription = orderItem.offline_sale_product_datum?.product_description || 0;
            } else if (isService) {
                itemDescription = orderItem.seller_service?.service_description || 0;
            } else {
                itemDescription = orderItem.product_item?.product?.description || 0;
            }
        
            const taxDetails = isOffline ? {
                IGST: formatDecimal(orderItem.offline_sale_product_datum?.igst_amount || 0),
                CGST: formatDecimal(orderItem.offline_sale_product_datum?.cgst_amount || 0),
                SGST: formatDecimal(orderItem.offline_sale_product_datum?.sgst_utgst_amount || 0),
                UTGST: formatDecimal(orderItem.offline_sale_product_datum?.sgst_utgst_amount || 0),
            } : {
                IGST: formatDecimal(orderItem.igst || 0),
                CGST: formatDecimal(orderItem.cgst || 0),
                SGST: formatDecimal(orderItem.sgst || 0),
                UTGST: formatDecimal(orderItem.utgst || 0),
            };
            const logisticsOrder = item.logistics_order || {};

            // Add logistics details
            const logisticsDetails = {
                "Logistics Invoice No": logisticsInvoice?.invoice_no || 0,
                "Shipment ID": orderItem.shipment_id || 0,
                "Tracking No": orderItem.tracking_no || 0,
                "Logistics GST Rate": logisticsOrder.gst_rate || 0,
                "Logistics Taxable Value": logisticsOrder.taxable_value || 0,
                "Logistics IGST %": logisticsOrder.igst_percent || 0,
                "Logistics IGST": logisticsOrder.igst || 0,
                "Logistics CGST %": logisticsOrder.cgst_percent || 0,
                "Logistics CGST": logisticsOrder.cgst || 0,
                "Logistics SGST %": logisticsOrder.sgst_percent || 0,
                "Logistics SGST": logisticsOrder.sgst || 0,
                "Logistics UTGST %": logisticsOrder.utgst_percent || 0,
                "Logistics UTGST": logisticsOrder.utgst || 0
            };
    
    
            // Add return invoice details
            const returnDetails = {
                "Return Invoice No": returnInvoice?.invoice_no || 0,
                "Return Note No": orderItem.rnote_no || 0,
                "Return Note Type": orderItem.rnote_type || 0,
                "Return Supply Type": orderItem.rnote_supply_type || 0,
                "Return Note Date": orderItem.rnote_date || 0,
                "Return Note Value": orderItem.rnote_value || 0,
                "Return GST Rate": orderItem.rgst_rate || 0,
                "Return Taxable Value": orderItem.rtaxable_value || 0,
                "Return IGST %": orderItem.rigst_percent || 0,
                "Return IGST": orderItem.rigst || 0,
                "Return CGST %": orderItem.rcgst_percent || 0,
                "Return CGST": orderItem.rcgst || 0,
                "Return SGST %": orderItem.rsgst_percent || 0,
                "Return SGST": orderItem.rsgst || 0,
                "Return UTGST %": orderItem.rutgst_percent || 0,
                "Return UTGST": orderItem.rutgst || 0,
                "Return IRN": orderItem.rirn || 0,
                "Return IRN Date": orderItem.rirn_date || 0
            };
        
            return {
                "S.no": formatDecimal(index + 1),
                "Order date": item.updated_at || 0,
                "Order ID": item.order_id || 0,
                "Invoice no": invoice?.invoice_no || 0,
                "Item description": itemDescription,
                "Order amount": formatDecimal(orderItem.order_price || 0),
                "Taxable amount": formatDecimal(isOffline ? (orderItem.offline_sale_product_datum?.taxable_supply || 0) : (orderItem.taxable_amount || 0)),
                ...taxDetails,
                "Total amount paid": formatDecimal(orderItem.order_price || 0),
                "Payment date": invoice?.created_at || 0,
                "Mode of payment": isOffline ? "offline" : "online",
                "Payment ID": orderItem.order_placed_on || 0,
                "Delivery status": deliveryStatus,
                ...logisticsDetails,
                ...returnDetails
            };
        });
    });
    // const data = orders.flatMap((item, index) => {
    //     return item.order_items.map((orderItem) => {
    //         const isService = orderItem.service_id > 0;
    //         const isOffline = orderItem.offline_sale_id > 0;
            
    //         const invoice = item.invoices.find(invoice => 
    //             isService ? invoice.invoice_type === "seller_product" && invoice.seller_id === orderItem.seller_id : invoice.invoice_type === "seller_product" && invoice.seller_id === orderItem.seller_id
    //         );
    //         const logisticsInvoice = item.invoices.find(invoice => 
    //             isService 
    //                 ? invoice.invoice_type === "logistics" && invoice.seller_id === orderItem.logistics_vendor_id
    //                 : invoice.invoice_type === "logistics" && invoice.seller_id === orderItem.logistics_vendor_id
    //         );
    //         const latestTracking = orderItem.order_trackings?.[0];
    //         const deliveryStatus = latestTracking ? `${latestTracking.status} - ${latestTracking.remarks}` : 0;
            
    //         let itemDescription = 0;
    //         if (isOffline) {
    //             itemDescription = orderItem.offline_sale_product_datum?.product_description || 0;
    //         } else if (isService) {
    //             itemDescription = orderItem.seller_service?.service_description || 0;
    //         } else {
    //             itemDescription = orderItem.product_item?.product?.description || 0;
    //         }
        
    //         const taxDetails = isOffline ? {
    //             IGST: formatDecimal(orderItem.offline_sale_product_datum?.igst_amount || 0),
    //             CGST: formatDecimal(orderItem.offline_sale_product_datum?.cgst_amount || 0),
    //             SGST: formatDecimal(orderItem.offline_sale_product_datum?.sgst_utgst_amount || 0),
    //             UTGST: formatDecimal(orderItem.offline_sale_product_datum?.sgst_utgst_amount || 0),
    //         } : {
    //             IGST: formatDecimal(orderItem.igst || 0),
    //             CGST: formatDecimal(orderItem.cgst || 0),
    //             SGST: formatDecimal(orderItem.sgst || 0),
    //             UTGST: formatDecimal(orderItem.utgst || 0),
    //         };
    //         const returnNoteDetails = {
    //             "Return Note No": orderItem.rnote_no || 0,
    //             "Return Note Type": orderItem.rnote_type || 0,
    //             "Return Supply Type": orderItem.rnote_supply_type || 0,
    //             "Return Note Date": orderItem.rnote_date || 0,
    //             "Return Note Value": orderItem.rnote_value || 0,
    //             "Return GST Rate": orderItem.rgst_rate || 0,
    //             "Return Taxable Value": orderItem.rtaxable_value || 0,
    //             "Return IGST %": orderItem.rigst_percent || 0,
    //             "Return IGST": orderItem.rigst || 0,
    //             "Return CGST %": orderItem.rcgst_percent || 0,
    //             "Return CGST": orderItem.rcgst || 0,
    //             "Return SGST %": orderItem.rsgst_percent || 0,
    //             "Return SGST": orderItem.rsgst || 0,
    //             "Return UTGST %": orderItem.rutgst_percent || 0,
    //             "Return UTGST": orderItem.rutgst || 0,
    //             "Return IRN": orderItem.rirn || 0,
    //             "Return IRN Date": orderItem.rirn_date || 0
    //         };
    //         return {
    //             "S.no": formatDecimal(index + 1),
    //             "Order date": item.updated_at || 0,
    //             "Order ID": item.order_id || 0,
    //             "Invoice no": invoice?.invoice_no || 0,
    //             "Item description": itemDescription,
    //             "Order amount": formatDecimal(orderItem.order_price || 0),
    //             "Taxable amount": formatDecimal(isOffline ? (orderItem.offline_sale_product_datum?.taxable_supply || 0) : (orderItem.taxable_amount || 0)),
    //             ...taxDetails,
    //             "Total amount paid": formatDecimal(orderItem.order_price || 0),
    //             "Payment date": invoice?.created_at || 0,
    //             "Mode of payment": isOffline ? "offline" : "online",
    //             "Payment ID": orderItem.order_placed_on || 0,
    //             "Delivery status": deliveryStatus,
    //             "Logistics invoice": logisticsInvoice,
    //             ...returnNoteDetails
    //         };
    //     });
    // });
    const handleExportCSV = () => {
        exportToCSV(data, 'your orders');
    }
    
    const handleExportExcel = () => {
        exportToExcel(data, 'your orders');
    }
    
    const handleExportPDF = () => {
        exportToPDF(data, 'your orders');
    }
    return (
       <React.Fragment>
            <div className="container-fluid">
                <div className="row">
                <ol className="col-12 breadcrumb bg-transparent">
                    <li className="breadcrumb-item"><Link to="./" className="text-dark">Home</Link></li>
                    <li className="breadcrumb-item "><Link to="/my-account" className="text-dark">Your Account</Link></li>
                    <li className="breadcrumb-item active"><Link to="#" className="text-dark">Your Order</Link></li>
                </ol>  
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <h4>Your Order</h4>
                    </div>
                    <div className="col-md-8">
                        <form className="form-inline float-right">
                            <div className="input-group"> 
                                <input type="text" className="form-control" name="your order name" id="filter_order_id" defaultValue={attributes.order_id != "null" ? attributes.order_id : null} placeholder="Order Search" onChange={filterOrder}/>
                            </div>
                        </form>
                    </div>
                </div>
                

                <div className="row userodernav1">
                    <div className="col-md-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                            <Link className="nav-link active" id="Orders-tab" data-toggle="tab" to="#Orders" role="tab" aria-controls="Orders" aria-selected="true">Orders</Link>
                            </li>
                            <div className='col-md-6 row'>
                    <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
                    <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
                    <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>

                </div>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="Orders" role="tabpanel" aria-labelledby="Orders-tab">
                                {
                                    orders.length == 0 ?
                                    <div className="card userordercard1">
                                        <div className="card-header">
                                            <div className="row">
                                                <h3>No orders yet</h3>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    orders.map((order) => {
                                        var logisticsPrice = order.logistics_order ? parseInt(order.logistics_order.order_price) : 0;
                                        var sellerIds = order.logistics_order ? [parseInt(order.logistics_order.seller_id)] : [];
                                        var totalPrice = logisticsPrice;
                                        var walletAmount = 0;
                                        if (order.order_items.length > 0) {
                                            order.order_items.map((item) => {
                                                totalPrice =  totalPrice + parseFloat(item.order_price);
                                                walletAmount = walletAmount + parseInt(item.wallet_amount);
                                                if (!sellerIds.includes(parseInt(item.seller_id))) {
                                                    sellerIds.push(parseInt(item.seller_id));
                                                }
                                            });
                                        }
                                        return (
                                            <div className="card userordercard1">
                                                <div className="card-header">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <p className="text-uppercase text-muted userordercardheader1">order time</p>
                                                            <p className="text-uppercase text-muted userordercardheader1">{new Date(order.created_at).toString()}</p>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <p className="text-uppercase text-muted userordercardheader1">Order Price</p>
                                                            <p className="text-uppercase text-muted userordercardheader1">{totalPrice - logisticsPrice}</p><br/>
                                                            <p className="text-uppercase text-muted userordercardheader1">Used From Wallet</p>
                                                            <p className="text-uppercase text-muted userordercardheader1">{walletAmount}</p><br/>
                                                            <p className="text-uppercase text-muted userordercardheader1">Logistics Price</p>
                                                            <p className="text-uppercase text-muted userordercardheader1">{logisticsPrice}</p><br/>
                                                            <p className="text-uppercase text-muted userordercardheader1">Total Price</p>
                                                            <p className="text-uppercase text-muted userordercardheader1">{totalPrice - walletAmount}</p>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <p className="text-uppercase text-muted userordercardheader1 float-right">Order No #{order.order_id}</p>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <p className="text-uppercase text-muted userordercardheader1 float-right">Order Name #{order.name}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            {
                                                                order.order_items.map((orderItems) => {
                                                                    return (
                                                                        <div>
                                                                            <div className='row' style={{cursor: 'pointer'}}>
                                                                            <div className="col-md-4">
                                                                                <img className="userorderpageimg1" src={envConfig.api_url + (orderItems.product_item || orderItems.seller_service ? orderItems.product_item ? orderItems.product_item.product_image.split(',')[0] : orderItems.seller_service.service_image : "")} alt="product"/>
                                                                            </div>
                                                                            <div className="col-md-8">
                                                                                <p className="text-muted userordercardheader1">sold by: TKGHUB</p>
                                                                                <p className="text-muted userordercardheader1">Quantity: {orderItems.order_quantity}</p>
                                                                                <p className="text-danger userordercardheader1 ">Total Price: <i className="fas fa-rupee-sign">{orderItems.order_price}</i></p>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                        <button  onClick={() => handleShow(orderItems.id,orderItems.seller_id)} className='btn btn-outline-success w-50 btn-sm usercardbtn1'>Give feedback</button>
                                                                        <button  onClick={() => handleShowQuery(orderItems.id,orderItems.seller_id)} className='btn btn-outline-danger w-50 btn-sm usercardbtn1'>Any Query?</button>
                                                                        <button  onClick={() => showOrderProgressModal(orderItems)} className='btn btn-outline-warning w-50 btn-sm usercardbtn1'>Tracking & Update</button>
                                                                        {orderItems.order_status === 'process_amount_to_wallet' && (
                                                                            <button 
                                                                                onClick={() => generateReturnInvoice(orderItems.id, sellerIds)} 
                                                                                className='btn btn-outline-warning w-50 btn-sm usercardbtn1'
                                                                            >
                                                                                Return Invoice
                                                                            </button>
                                                                            )}
                                                                        </div>
                                                                        
                                                                        
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                        <div className="col-md-6 d-flex flex-column">
                                                            <button className="btn btn-outline-success w-50 btn-sm usercardbtn1" onClick={() => generateInvoice(order.id, sellerIds)}>Invoice Generate</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="tab-pane fade" id="Buy Again" role="tabpanel" aria-labelledby="Buy Again-tab">Seller Score is calculated by TKGHUB based on customer feedback and seller's performance...</div>
                            <div className="tab-pane fade" id="Open Order" role="tabpanel" aria-labelledby="Open Order-tab">Seller Score is calculated by TKGHUB based on customer feedback and seller's performance...</div>
                            <div className="tab-pane fade" id="Cancelled Order" role="tabpanel" aria-labelledby="Cancelled Order-tab">Seller Score is calculated by TKGHUB based on customer feedback and seller's performance...</div>
                        </div>
                    </div>
                </div>

                
                    <Modal show={show} onHide={handleClose} >
                        <Modal.Header closeButton >
                            <Modal.Title>Give your feedback here</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <FormGroup>
                            <Label for="feedback_type">Select type</Label>
                            <select id="feedback_type">
                            <option value="0">Select</option>
                            {
                                feedbackType.map((type) => {
                                return (
                                    <>
                                    <option>{type}</option>
                                    </>
                                )
                                })
                            }
                            </select>
                        </FormGroup>
                            <FormGroup>
                                <Label for="newEntry">Enter a feedback</Label>
                                <Input type='text' id="feedback"/>
                            </FormGroup>
                        </Modal.Body>
                        <Modal.Footer>
                                <Button className='submit' onClick={sendFeedBack}>
                                Submit
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={shows} onHide={handleCloses} >
                        <Modal.Header closeButton >
                            <Modal.Title>Right your Query</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <FormGroup>
                            <Label for="Query_type">Select type</Label>
                            <select id="Query_type">
                            <option value="0">Select</option>
                            {
                                feedbackType.map((type) => {
                                return (
                                    <>
                                    <option>{type}</option>
                                    </>
                                )
                                })
                            }
                            </select>
                        </FormGroup>
                            <FormGroup>
                                <Label for="newEntry">Enter Query </Label>
                                <Input type='text' id="query"/>
                            </FormGroup>
                        </Modal.Body>
                        <Modal.Footer>
                                <Button className='submit' onClick={sendQuery}>
                                Submit
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={orderProgressModal} onHide={() => showOrderProgressModal([])} size='lg'>
                        <Modal.Header closeButton >
                            <Modal.Title>Order Progress</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='row'>
                                <div className="col-md-6 d-flex flex-column">
                                    <div class="order-track">
                                    {
                                        orderItemsData.order_trackings ? orderItemsData.order_trackings.map((track) => {
                                            if (track.status == 'cancel_requested') {
                                                return (
                                                    <div class="order-track-step">
                                                        <div class="order-track-status">
                                                            <span class="order-track-status-dot"></span>
                                                            <span class="order-track-status-line"></span>
                                                        </div>
                                                        <div class="order-track-text">
                                                            <p class="order-track-text-stat">{track.remarks.replaceAll("_", " ")}</p>
                                                            <span class="order-track-text-sub">{track.created_at}</span>
                                                        </div>
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <div class="order-track-step">
                                                        <div class="order-track-status">
                                                            <span class="order-track-status-dot"></span>
                                                            <span class="order-track-status-line"></span>
                                                        </div>
                                                        <div class="order-track-text">
                                                            <p class="order-track-text-stat">{track.remarks.replaceAll("_", " ")}</p>
                                                            <span class="order-track-text-sub">{track.created_at}</span>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }) : ""
                                    }
                                    {/* {
                                        orderItemsData.order_status == 'in_progress' && orderItemsData.order_status != 'delivered' && orderItemsData.order_status != 'cancelled' ? 
                                        <div class="order-track-step">
                                            <div class="order-track-status">
                                                <span class="order-track-status-dot bg-warning"></span>
                                                <span class="order-track-status-line"></span>
                                            </div>
                                            <div class="order-track-text">
                                                <p class="order-track-text-stat">Delivered</p>
                                                <span class="order-track-text-sub">Not done yet</span>
                                            </div>
                                        </div> : ''
                                    } */}
                                    </div>
                                </div>
                                <div className="col-md-6 d-flex flex-column">
                                    {
                                        showCancel.includes(orderItemsData.order_status)? 
                                        <button className="btn btn-outline-danger w-50 btn-sm usercardbtn1" onClick={() => changeOrderStatusOperations(orderItemsData.id, 'cancel_requested')}>Cancel Order</button>
                                        : ''
                                    }
                                    {
                                        showReturn.includes(orderItemsData.order_status) && orderItemsData.service_id == 0 && orderItemsData.offline_sale_id == 0 ? 
                                        <button className="btn btn-outline-danger w-50 btn-sm usercardbtn1" onClick={() => changeOrderStatusOperations(orderItemsData.id, 'return_requested')}>Return Order</button>
                                        : ''
                                    }
                                    {
                                        showReplace.includes(orderItemsData.order_status) && orderItemsData.service_id == 0 && orderItemsData.offline_sale_id == 0 ?
                                        <button className="btn btn-outline-warning w-50 btn-sm usercardbtn1" onClick={() => changeOrderStatusOperations(orderItemsData.id, 'replace_requested')}>Replace Order</button>
                                        : ""
                                    }
                                    {
                                        orderItemsData.order_status == 'delivered' ? 
                                        <>
                                        <br/>
                                        <label>Satisfied with the item ? No Return ?</label>
                                        <select className='form-control' onChange={(e) => updateNoReturn(e, orderItemsData.id)} defaultValue={orderItemsData.no_return} disabled={orderItemsData.no_return != -1 ? true : false}>
                                            <option value={-1}>Please Select One Option</option>
                                            <option value={1}>Yes</option>
                                            <option value={0}>No</option>
                                        </select></> : ""
                                    }
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
            </div>
        </React.Fragment>
    )
}
export default YourOrdersView;