// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/assets/img/SERVICE.jpeg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.servicep1{
    height: 500px;
    width: 1519px;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .text-box{
    width: 90%;
    color: #fff;
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
  }
  
  .text-box h1{
    font-size: 62px;
  }
  
  .homescard1{
    margin-top: -50px;
    width: 80%;
  
  }
  
  .cardhs1{
    border: none;
  }
  
  .grid_item{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 160px;
    height: 120px;
    cursor: pointer;
    overflow: hidden;
    
  }
  .img1{
    width: 40px;
    height: 40px;
  }
  
  .Logout{
    background-color: #ffa41c !important;
  }

  .service_card_top{
    margin-top:12%
}`, "",{"version":3,"sources":["webpack://./src/components/service/service.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,yDAAsD;IACtD,sBAAsB;IACtB,4BAA4B;IAC5B,2BAA2B;EAC7B;;EAEA;IACE,UAAU;IACV,WAAW;IACX,kBAAkB;IAClB,OAAO;IACP,SAAS;IACT,+BAA+B;IAC/B,kBAAkB;EACpB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,iBAAiB;IACjB,UAAU;;EAEZ;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,eAAe;IACf,gBAAgB;;EAElB;EACA;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,oCAAoC;EACtC;;EAEA;IACE;AACJ","sourcesContent":[".servicep1{\n    height: 500px;\n    width: 1519px;\n    background-image: url(/public/assets/img/SERVICE.jpeg);\n    background-size: cover;\n    background-repeat: no-repeat;\n    background-position: center;\n  }\n  \n  .text-box{\n    width: 90%;\n    color: #fff;\n    position: absolute;\n    top:50%;\n    left: 50%;\n    transform: translate(-50%,-50%);\n    text-align: center;\n  }\n  \n  .text-box h1{\n    font-size: 62px;\n  }\n  \n  .homescard1{\n    margin-top: -50px;\n    width: 80%;\n  \n  }\n  \n  .cardhs1{\n    border: none;\n  }\n  \n  .grid_item{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    width: 160px;\n    height: 120px;\n    cursor: pointer;\n    overflow: hidden;\n    \n  }\n  .img1{\n    width: 40px;\n    height: 40px;\n  }\n  \n  .Logout{\n    background-color: #ffa41c !important;\n  }\n\n  .service_card_top{\n    margin-top:12%\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
