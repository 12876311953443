
import React from 'react'
import './body.css'
import Content from '../content/Content'
import Table from '../tabel/Table';
 function Body() {
    return (
        <div className="body business">
            <Content/>
            {/* <Table/> */}
        </div>
    )
}
export default Body;