// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  overflow-x: hidden;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: #f8f9fa;
  padding-top: 60px;
  transition: all 0.3s ease;
  z-index: 1000;
  overflow-y: auto; 
}

.sidebar.collapsed {
  left: -250px; 
}

.sidebar.expanded {
  left: 0;
}

.sidebarWrapper {
  padding: 20px;
}

.sidebar a {
  margin-top: 20px;
  display: block;
  padding: 10px;
  color: #333;
  text-decoration: none;
  font-size: 1.2rem;
}

.sidebar a:hover {
  background-color: #ddd;
}

.fixed-toggle {
  top: 60px;
  left: 40px;
  z-index: 1000;
  background: none;
  border: none;
  padding: 0; 
  cursor: pointer;
}

.fixed-toggle:hover,
.fixed-toggle:focus {
  outline: none;
  box-shadow: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: block;
}

@media (max-width: 768px) {
  .sidebar {
    width: 100%; 
  }
  .sidebar.collapsed {
    left: -100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/seller-portal/sidebar/ssidebar.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,iBAAiB;EACjB,yBAAyB;EACzB,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,WAAW;EACX,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,aAAa;EACb,gBAAgB;EAChB,YAAY;EACZ,UAAU;EACV,eAAe;AACjB;;AAEA;;EAEE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE;IACE,WAAW;EACb;EACA;IACE,WAAW;EACb;AACF","sourcesContent":["body {\r\n  overflow-x: hidden;\r\n}\r\n\r\n.sidebar {\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  width: 250px;\r\n  height: 100%;\r\n  background-color: #f8f9fa;\r\n  padding-top: 60px;\r\n  transition: all 0.3s ease;\r\n  z-index: 1000;\r\n  overflow-y: auto; \r\n}\r\n\r\n.sidebar.collapsed {\r\n  left: -250px; \r\n}\r\n\r\n.sidebar.expanded {\r\n  left: 0;\r\n}\r\n\r\n.sidebarWrapper {\r\n  padding: 20px;\r\n}\r\n\r\n.sidebar a {\r\n  margin-top: 20px;\r\n  display: block;\r\n  padding: 10px;\r\n  color: #333;\r\n  text-decoration: none;\r\n  font-size: 1.2rem;\r\n}\r\n\r\n.sidebar a:hover {\r\n  background-color: #ddd;\r\n}\r\n\r\n.fixed-toggle {\r\n  top: 60px;\r\n  left: 40px;\r\n  z-index: 1000;\r\n  background: none;\r\n  border: none;\r\n  padding: 0; \r\n  cursor: pointer;\r\n}\r\n\r\n.fixed-toggle:hover,\r\n.fixed-toggle:focus {\r\n  outline: none;\r\n  box-shadow: none;\r\n}\r\n\r\n.overlay {\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n  background-color: rgba(0, 0, 0, 0.5);\r\n  z-index: 999;\r\n  display: block;\r\n}\r\n\r\n@media (max-width: 768px) {\r\n  .sidebar {\r\n    width: 100%; \r\n  }\r\n  .sidebar.collapsed {\r\n    left: -100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
