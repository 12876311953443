const menus = {
    "entry_point": {
        "message": "Hello! Welcome to SR³! I'm your virtual assistant here to help with orders, services, logistics, deliveries, item management, and more. Whether you're shopping, selling, serving or handling shipments, I can guide you. Let's start—what do you need help with?",
        "menu": [
            {
                "name": "Customer Support",
                "action": "customer_support",
                "api": null
            },
            {
                "name": "Seller Support",
                "action": "seller_support",
                "api": null
            },
            {
                "name": "Logistics Partner Support",
                "action": "logistics_partner_support",
                "api": null
            }
        ]
    },
    "customer_support": {
        "message": "Are you looking to browse or need help with an existing order?",
        "menu": [
            {
                "name": "Yes",
                "action": "customer_support_yes",
                "api": "fetch-last-five-orders"
            },
            {
                "name": "Other Issue",
                "action": "escalate_to_customer",
                "api": null
            }
        ]
    },
    "customer_support_yes": {
        "message": "Please select from recent orders below: ",
        "api": "fetch-order-details",
        "action": "user_select_orders"
    },
    "user_select_orders": {
        "message": "Here are the details. Please select one to go for some action : ",
        "action": "update_order",
        "saveWhere": true
    },
    "update_order": {
        "message": "Would you like to update the status of selected item ?",
        "menu": [
            {
                "name": "Yes",
                "action": "update_selected_item",
                "api": null
            },
            {
                "name": "Other Issue",
                "action": "escalate_to_customer",
                "api": null,
                "customerEscalation": true
            }
        ]
    },
    "update_selected_item": {
        "message": "Please select the status from below - ",
        "menu": [
            {
                "name": "Item Deliveted",
                "action": "update_order_item",
                "api": "update-order-item-status",
                "value": {
                    "order_status": "delivered"
                }
            },
            {
                "name": "Return Item",
                "action": "update_order_item",
                "api": "update-order-item-status",
                "value": {
                    "order_status": "returned"
                }
            },
            {
                "name": "Replace Item",
                "action": "update_order_item",
                "api": "update-order-item-status",
                "value": {
                    "order_status": "replaced"
                }
            },
            {
                "name": "Cancel Item",
                "action": "update_order_item",
                "api": "update-order-item-status",
                "value": {
                    "order_status": "cancelled"
                }
            },
            {
                "name": "Other Issue",
                "action": "escalate_to_customer",
                "api": null,
                "customerEscalation": true
            }
        ]
    },
    "update_order_item": {
        "message": "Great! Status Updated! Thank you! Is there anything else you’d like assistance ?",
        "menu": [
            {
                "name": "Yes",
                "action": "entry_point",
                "api": null
            },
            {
                "name": "No",
                "action": "end_chat",
                "api": null
            }
        ]
    },
    "end_chat": {
        "message": "Great! Thank You!"
    },
    "escalate_to_customer": {
        "message": "Please enter the Issue Facing (Enter atleast 10 characters): "
    },
    "customer_support_other_issue": {
        "message": "A customer care representative will contact you at your registered phone number shortly.",
        "action": null
    }
}

export default menus;