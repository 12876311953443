import React from 'react';
import { Button, CardBody, Card, Form, FormGroup, Label, Input, CardHeader } from 'reactstrap';
import $ from 'jquery';
import { Link, useNavigate } from 'react-router-dom';
const common = require('../utils/Common');

const Login = (props) => {
    let navigate = useNavigate();
    const LoginUser = async (e) => {
        e.preventDefault();
        let data = {
            "user_id": $('#user_id').val(),
            "password": $('#password').val(),
        }
        if ($('#user_id').val() === '' || $('#password').val() === '') {
            $('#error').html('Please fill both user id and password');
            return;
        }
        let response = await common.apiRequest('post', '/users/login', data);
        if (response.status === 200) {
            if (response.data.code === 'success') {
                localStorage.setItem('token', response.data.access_token);
                localStorage.setItem('userId', response.data.id);
                localStorage.setItem('userType', response.data.user_type);
                if (response.data.seller_id !== '') {
                    localStorage.setItem('sellerId', response.data.id);
                }
                getCartItems();
                navigate('/home');

            } else {
                $('#error').html(response.data.error);
            }
        } else {
            $('#error').html(response);
        }
    }

    const getCartItems = async (e) => {
        let parameters = {
            'user_id': localStorage.getItem('userId'),
            'action': 'read'
        }
        let response = await common.apiRequest('post', '/products/add-remove-cart-items', parameters);
        let data = (response.data && response.data.data) ? response.data.data : [];
        let cartItems = [];
        data.map((item) => {
            let tempCartItems = {};
            tempCartItems.product_id = item.product_id;
            tempCartItems.product_item_id = item.product_item_id;
            tempCartItems.quantity_purchased = item.quantity_purchased;
            tempCartItems.userID = item.user_id;
            tempCartItems.cart_id = item.id;
            tempCartItems.active = item.active;
            tempCartItems.supply_pincode = item.supply_pincode;
            tempCartItems.service_id = item.service_id;
            cartItems.push(tempCartItems);
        });
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }

    if (localStorage.getItem('token')) {
        navigate('/home');
    }

    const MyBackgroundImage = "assets/img/Back.jpeg";
    return (
        <div className='container-fluid' style={{
            backgroundImage: `url(${MyBackgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            minHeight: '100vh',
            paddingTop: '130px'
        }}>
            <div className='row justify-content-center'>
                <div className='col-lg-4 col-md-6 col-sm-8'>
                    <Card className='card-body'>
                        <CardBody>
                            <CardHeader className='card-headers'>Login</CardHeader>
                            <div className='btm-border'></div>
                            <Form onSubmit={LoginUser} className='forms'>
                                <FormGroup className="mb-2">
                                    <Label for="user_id">User ID</Label>
                                    <Input type="text" id="user_id" placeholder="4567ABCGET" />
                                </FormGroup>
                                <FormGroup className="mb-2">
                                    <Label for="password">Password</Label>
                                    <Input type="password" id="password" placeholder="*******" />
                                </FormGroup>
                                <p className="text-danger" id="error"></p>
                                <Button type="submit" className='submit-button'>Login</Button><br />
                                <Link to="/signup">Register as New User</Link><br />
                                <Link to="/forgotpass">Forgot Password</Link><br />
                                <Link to="/forgetuser">Forgot User ID</Link><br />
                            </Form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default Login;
