import React, { useEffect, useState, useMemo } from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useCSVDownloader } from 'react-papaparse';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import $ from 'jquery';
import { FormGroup, Button, Label, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import { exportToCSV, exportToExcel, exportToPDF } from '../../../utils/Exports';
import { parseValue } from '../../../utils/Common';
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const common = require('../../../utils/Common');

export default function TdsDetails() {
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const { CSVDownloader } = useCSVDownloader();
  useEffect(() => {
    fetchTdsDetails();
  }, []);

 
  const fetchTdsDetails = async () => {
    let data = {
      'seller_id': localStorage.getItem('sellerId'),
      'action': 'read',
      'start_date': $('#Date-from').val(),
      'end_date': $('#Date-to').val(),
    };
    let response = await common.apiRequest('post', '/admin/crud-buyer-payment', data);
    if (response.status === 200) {
      if (response.data.code === 'success') {
        setRowData(response.data.data);
      } else {
        alert(response.data.error);
      }
    } else {
      alert(response.data.error);
    }
  };

  useEffect(() => {
    setColumnDefs([
      { 
        headerName: 'Payment Status', 
        field: 'payment_status', 
        sortable: true, 
        filter: true 
      },
      { 
        headerName: 'Created At', 
        field: 'created_at', 
        sortable: true, 
        filter: true,
        valueGetter: params => (params.data.created_at)
      },
      { 
        headerName: 'Updated At', 
        field: 'updated_at', 
        sortable: true, 
        filter: true,
        valueGetter: params =>(params.data.updated_at)
      },
      { 
        headerName: 'Type', 
        field: 'type', 
        sortable: true, 
        filter: true 
      },
      { 
        headerName: 'Price', 
        field: 'price', 
        sortable: true, 
        filter: true,
        valueGetter: params => parseValue(params.data.price, 'float')
      },
      { 
        headerName: 'Taxable Value', 
        field: 'taxable_value', 
        sortable: true, 
        filter: true,
        valueGetter: params => parseValue(params.data.taxable_value, 'float')
      },
      { 
        headerName: 'Commission', 
        field: 'commission', 
        sortable: true, 
        filter: true,
        valueGetter: params => parseValue(params.data.commission, 'float')
      },
      { 
        headerName: 'TDS', 
        field: 'tds', 
        sortable: true, 
        filter: true,
        valueGetter: params => parseValue(params.data.tds, 'float')
      },
      { 
        headerName: 'Date Of Deduction', 
        field: 'date_of_deduction', 
        sortable: true, 
        filter: true,
        valueGetter: params => (params.data.date_of_deduction)
      },
      { 
        headerName: 'Rate Of Deduction', 
        field: 'rate_of_deduction', 
        sortable: true, 
        filter: true,
        valueGetter: params => parseValue(params.data.rate_of_deduction, 'float')
      },
      { 
        headerName: 'Section', 
        field: 'section', 
        sortable: true, 
        filter: true 
      },
      { 
        headerName: 'Challan No', 
        field: 'challan_no', 
        sortable: true, 
        filter: true 
      },
      { 
        headerName: 'Challan Date', 
        field: 'challan_date', 
        sortable: true, 
        filter: true,
        valueGetter: params => (params.data.challan_date)
      },
      { 
        headerName: 'BSR Code', 
        field: 'bsr_code', 
        sortable: true, 
        filter: true 
      },
      { 
        headerName: 'Order ID', 
        field: 'order_item.order.order_id', 
        sortable: true, 
        filter: true 
      }
    ]);
  }, []);
  const exportPDF = () => {
    const doc = new jsPDF('landscape', 'pt', 'a4');
    doc.text("TDS Details Report", 40, 40);

    const columns = columnDefs.map(col => ({ header: col.headerName, dataKey: col.field }));

    doc.autoTable({
      columns: columns,
      body: rowData,
      startY: 50,
    });

    doc.save("tds_details_report.pdf");
  };

  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
    editable: false,
  }), []);

  const data = rowData.map((data, index) => ({
    "Payment Status": data.payment_status,
    "Created At": data.created_at,
    "Updated At": data.updated_at,
    "Type": data.type,
    "Price": parseValue(data.price, 'float'),
    "Taxable Value":  parseValue(data.taxable_value, 'float'),
    "Commission": parseValue(data.commission, 'float'),
    "TDS": parseValue(data.tds, 'float'),
    "Date Of Deduction": data.date_of_deduction,
    "Rate Of Deduction": data.rate_of_deduction,
    "Section": data.section,
    "Challan No": data.challan_no,
    "Challan Date": data.challan_date,
    "BSR Code": data.bsr_code,
    'Order ID': data.order_item.order.order_id
  }));
const handleExportCSV = () => {
    exportToCSV(data, 'TDS report');
}

const handleExportExcel = () => {
    exportToExcel(data, 'TDS report');
}

const handleExportPDF = () => {
    exportToPDF(data, 'TDS report');
}
  return (
    <>
      <div className="row report-margin">
        <div className='col-md-6'>
          <h3>TDS Details</h3>
        </div>
        <div className="col-md-12">
                    <Form className='row'>
                        <FormGroup className='col-md-4 row'>
                            <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                            <input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={fetchTdsDetails} className="form-control col-md-5" id="Date-from" name="Date-from"></input>
                        </FormGroup>
                        <FormGroup className='col-md-4 row'>
                            <Label className=" col-md-2 col-form-label" for="Date-to">To:</Label>
                            <input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={fetchTdsDetails} className="form-control col-md-5" id="Date-to" name="Date-to"></input>
                        </FormGroup>
                    </Form>      
                </div>
        <div className='col-md-6 d-flex justify-content-end'>
            <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
            <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
            <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <div className="ag-theme-quartz" style={{ height: 600, width: '100%' }}>
            <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowSelection="multiple"
              suppressRowClickSelection={true}
              pagination={true}
              paginationPageSize={10}
              paginationPageSizeSelector={[10, 25, 50]}
            />
          </div>
        </div>
      </div>
    </>
  );
}
