import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { exportToCSV, exportToExcel, exportToPDF } from '../../utils/Exports';
import moment from 'moment';
import $ from 'jquery';
import { Modal, ModalBody, ModalHeader, Input, FormGroup, Button, Label, Form } from 'reactstrap';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');

export default function SellerOrders() {
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    const [deliveryUsers, setDeliveryUsers] = useState([]);
    const [orderStatuses, setOrderStatuses] = useState([]);

    useEffect(() => {
        getOrders();
        getDeliveryUsers();
    }, []);

    const getOrders = async () => {
        let data = {
            'seller_id': localStorage.getItem('sellerId'),
            'start_date': $('#Date-from').val(),
            'end_date': $('#Date-to').val(),
        }
        let response = await common.apiRequest('post', '/seller/get-orders', data);
        if (response.data.code === 'success') {
            setOrders(response.data.data);
            setOrderStatuses(await common.getSystemConfig('product_service_order_status'));
        } else {
            setOrders([]);
        }
    }

    const getDeliveryUsers = async () => {
        let data = {
            'action': 'read',
            'deliver_manager_id': localStorage.getItem('sellerId')
        }
        let response = await common.apiRequest('post', '/delivery/crud-delivery-users', data);
        if (response.data.code === 'success') {
            setDeliveryUsers(response.data.data);
        } else {
            setDeliveryUsers([]);
        }
    }

    const assignDeliver = async (orderId, deliverId, columnName) => {
        let data = {
            'action': 'update',
            'id': orderId,
            [columnName]: parseInt(deliverId)
        }
        let response = await common.apiRequest('post', '/delivery/assign-deassign-orders', data);
        if (response.data.code === 'success') {
            common.alert(response.data.message);
            getOrders();
        } else {
            common.alert(response.data.error, 'error');
        }
    }

    const changeOrderStatus = async (orderId, orderStatus, orderPrice, userId, previousStatus) => {
        if (previousStatus === "process_amount_to_wallet") {
            common.alert('Status cannot be changed now', 'error');
            return;
        }
        let data = {
            'action': 'update',
            'order_id': orderId,
            'order_status': orderStatus,
            'order_price': orderPrice,
            'user_id': userId
        }
        let response = await common.apiRequest('post', '/orders/change-order-status-operations', data);
        if (response.data.code === 'success') {
            common.alert(response.data.message.replaceAll("_", " "));
            getOrders();
        } else {
            common.alert(response.data.error, 'error');
        }
    }

    const generateInvoice = async (sellerId, orderId, invoiceType) => {
        let response = await common.getInvoiceData([sellerId], orderId, invoiceType);
        if (response.length === 0) {
            common.alert('No invoice', 'error');
            return;
        }
        navigate(
            '/generate-invoice',
            {
                state: {
                    invoiceData: response
                }
            }
        )
    }
    const data = orders.map(data => ({
        orderId: data.order?.order_id || "NA",
        orderType: data.product_item 
            ? "Online Product Sale" 
            : data.seller_service 
                ? "Online Service Sale" 
                : "Offline Product Sale",
        sellerLogisticsBothOrder: (() => {
            const isLogistics = data.logistics_vendor_id == localStorage.getItem('userId');
            const isSeller = data.seller_id == localStorage.getItem('userId');
            const isOfflineSale = data.offline_sale_product_datum?.offline_sale?.seller_id == localStorage.getItem("userId");
            
            if ((isLogistics && isSeller) || isOfflineSale) return "Both";
            if (isLogistics) return "Logistics";
            return "Seller";
        })(),
        "Product Name": data.product_item 
            ? data.product_item.product.name 
            : data.seller_service 
                ? data.seller_service.service_name 
                : data.offline_sale_product_datum?.product_description || "NA",
        "product Description": data.product_item 
            ? data.product_item.product.description 
            : data.seller_service 
                ? data.seller_service.service_description 
                : data.offline_sale_product_datum?.product_description || "NA",
        "User InvoiceNo": data.order?.invoices?.find(inv => inv.invoice_type === 'product')?.invoice_no || "NA",
        "User Return InvoiceNo": data.order?.invoices?.find(inv => inv.invoice_type === 'return_order')?.invoice_no || "NA",
        // "Seller InvoiceNo": data.order?.invoices?.find(inv => inv.invoice_type === 'seller_product')?.invoice_no || "NA",
        "Logistics Seller InvoiceNo": 
        data.logistics_vendor_id > 0 
        ? data.order?.invoices?.find(inv => inv.invoice_type === 'logistics')?.invoice_no || "NA" 
        : "NA",
        "Logistics Price": 
        data.logistics_vendor_id > 0 
        ? data.order?.invoices?.find(inv => inv.invoice_type === 'logistics')?.price || "NA" 
        : "NA",
        "Logistics Provider": data.logistics_order ? data.logistics_order.seller_service.user.user_business.business_name || 0 : 0,
            "Order Price": data.order_price || 0,
            "Tax Rate": data.offline_sale_product_datum 
            ? (data.offline_sale_product_datum.cgst_rate || 0) + 
              (data.offline_sale_product_datum.sgst_utgst_rate || 0) + 
              (data.offline_sale_product_datum.igst_rate || 0)
            : data.tax_percent || 0,
        "Taxable Amount": data.offline_sale_product_datum
            ? data.offline_sale_product_datum.taxable_supply || 0
            : data.taxable_amount || 0,
        "Igst Rate": data.offline_sale_product_datum
            ? data.offline_sale_product_datum.igst_rate || 0
            : data.igst_percent || 0,
        "Igst Amount": data.offline_sale_product_datum
            ? data.offline_sale_product_datum.igst_amount || 0
            : data.igst || 0,
        "Sgst Rate": data.offline_sale_product_datum
            ? data.offline_sale_product_datum.sgst_utgst_rate || 0
            : data.sgst_percent || 0,
        "Sgst Amount": data.offline_sale_product_datum
            ? data.offline_sale_product_datum.sgst_utgst_amount || 0
            : data.sgst || 0,
        "Cgst Rate": data.offline_sale_product_datum
            ? data.offline_sale_product_datum.cgst_rate || 0
            : data.cgst_percent || 0,
        "Cgst Amount": data.offline_sale_product_datum
            ? data.offline_sale_product_datum.cgst_amount || 0
            : data.cgst || 0,
        "Order Quantity": data.order_quantity || 0
    }));
    
    const handleExportCSV = () => {
        exportToCSV(data, 'orders.csv');
    }
    
    const handleExportExcel = () => {
        exportToExcel(data, 'orders.xlsx');
    }

    const handleExportPDF = () => {
        exportToPDF(data, 'orders.pdf');
    }

    const columnDefs = useMemo(() => [
        { field: 'order.order_id', headerName: 'Order ID', checkboxSelection: true },
        { 
            field: 'orderType', 
            headerName: 'Order Type',
            valueGetter: params => 
                params.data.product_item ? "Online Product Sale" : 
                params.data.seller_service ? "Online Service Sale" : 
                "Offline Product Sale"
        },
        { 
            field: 'sellerLogisticsBothOrder', 
            headerName: 'Seller/Logistics/Both Order',
            valueGetter: params => {
                const isLogistics = params.data.logistics_vendor_id == localStorage.getItem('userId');
                const isSeller = params.data.seller_id == localStorage.getItem('userId');
                const isOfflineSale = params.data.offline_sale_product_datum && 
                                      params.data.offline_sale_product_datum.offline_sale && 
                                      params.data.offline_sale_product_datum.offline_sale.seller_id == localStorage.getItem("userId");
                
                if ((isLogistics && isSeller) || isOfflineSale) return "Both";
                if (isLogistics) return "Logistics";
                return "Seller";
            }
        },
        { 
            field: 'productName', 
            headerName: 'Product Name',
            valueGetter: params => 
                params.data.product_item ? params.data.product_item.product.name : 
                params.data.seller_service ? params.data.seller_service.service_name : 
                params.data.offline_sale_product_datum ? params.data.offline_sale_product_datum.product_description : 
                "NA"
        },
        { 
            field: 'productDescription', 
            headerName: 'Product Description',
            valueGetter: params => 
                params.data.product_item ? params.data.product_item.product.description : 
                params.data.seller_service ? params.data.seller_service.service_description : 
                params.data.offline_sale_product_datum ? params.data.offline_sale_product_datum.product_description : 
                "NA"
        },
        { 
            field: 'userInvoiceNo', 
            headerName: 'User Invoice No',
            valueGetter: params => {
                if (params.data.order && params.data.order.invoices) {
                    const invoice = params.data.order.invoices.find(inv => inv.invoice_type === 'product');
                    return invoice ? invoice.invoice_no : "NA";
                }
                return "NA";
            }
        },
        { 
            field: 'userReturnInvoiceNo', 
            headerName: 'User Return Invoice No',
            valueGetter: params => {
                if (params.data.order && params.data.order.invoices) {
                    const invoice = params.data.order.invoices.find(inv => inv.invoice_type === 'return_order');
                    return invoice ? invoice.invoice_no : "NA";
                }
                return "NA";
            }
        },
        { 
            field: 'sellerInvoiceNo', 
            headerName: 'Seller Invoice No',
            valueGetter: params => {
                if (params.data.order && params.data.order.invoices) {
                    const invoice = params.data.order.invoices.find(inv => inv.invoice_type === 'seller_product' && params.data.seller_id==inv.seller_id);
                    return invoice ? invoice.invoice_no : "NA";
                }
                return "NA";
            }
        },
        {
            field: 'logisticsSellerInvoiceNo',
            headerName: 'Logistics Seller Invoice No',
            valueGetter: params => {
                const hasLogisticsVendor = params.data?.logistics_vendor_id > 0;
                const invoices = params.data.order?.invoices;
                
                if (hasLogisticsVendor && invoices) {
                    const invoice = invoices.find(inv => inv.invoice_type === 'logistics');
                    return invoice ? invoice.invoice_no : "NA";
                }
                
                return "NA";
            }
        }
        ,
        {
            field: 'logisticsPrice',
            headerName: 'Logistics Price',
            valueGetter: params => {
                const hasLogisticsVendor = params.data?.logistics_vendor_id > 0;
                const invoices = params.data.order?.invoices;
                
                if (hasLogisticsVendor && invoices) {
                    const invoice = invoices.find(inv => inv.invoice_type === 'logistics');
                    return invoice ? invoice.price : "NA";
                }
                
                return "NA";
            }
        },
        {
            field: 'LogisticsProvider',
            headerName: 'Logistics Provider',
            valueGetter: params => {
                if (params.data.logistics_order) {
                    return params.data.logistics_order.seller_service.user.user_business.business_name || 0;
                }
            }
        },
        {
            field: 'order_price', headerName:'Order Price', valueGetter: params => params.data.order_price
        },
        {
            field: 'tax_percent',
            headerName: 'Tax Rate',
            valueGetter: params => {
                if (params.data.offline_sale_product_datum) {
                    const total =(params.data.offline_sale_product_datum.cgst_rate || 0) +
                                 (params.data.offline_sale_product_datum.sgst_utgst_rate || 0) +
                                 (params.data.offline_sale_product_datum.igst_rate || 0);
                    return total;
                }
                return params.data.tax_percent || 0;
            }
        },
        {
            field: 'taxable_amount',
            headerName: 'Taxable Amount',
            valueGetter: params => {
                if (params.data.offline_sale_product_datum) {
                    return params.data.offline_sale_product_datum.taxable_supply || 0;
                }
                return params.data.taxable_amount || 0;
            }
        },
        {
            field: 'igst_percent',
            headerName: 'IGST Rate',
            valueGetter: params => {
                if (params.data.offline_sale_product_datum) {
                    return params.data.offline_sale_product_datum.igst_rate || 0;
                }
                return params.data.igst_percent || 0;
            }
        },
        {
            field: 'igst',
            headerName: 'IGST Amount',
            valueGetter: params => {
                if (params.data.offline_sale_product_datum) {
                    return params.data.offline_sale_product_datum.igst_amount || 0;
                }
                return params.data.igst || 0;
            }
        },
        {
            field: 'sgst_percent',
            headerName: 'SGST/UTGST Rate',
            valueGetter: params => {
                if (params.data.offline_sale_product_datum) {
                    return params.data.offline_sale_product_datum.sgst_utgst_rate || 0;
                }
                return params.data.sgst_percent || 0;
            }
        },
        {
            field: 'sgst',
            headerName: 'SGST/UTGST Amount',
            valueGetter: params => {
                if (params.data.offline_sale_product_datum) {
                    return params.data.offline_sale_product_datum.sgst_utgst_amount || 0;
                }
                return params.data.sgst || 0;
            }
        },
        {
            field: 'cgst_percent',
            headerName: 'CGST Rate',
            valueGetter: params => {
                if (params.data.offline_sale_product_datum) {
                    return params.data.offline_sale_product_datum.cgst_rate || 0;
                }
                return params.data.cgst_percent || 0;
            }
        },
        {
            field: 'cgst',
            headerName: 'CGST Amount',
            valueGetter: params => {
                if (params.data.offline_sale_product_datum) {
                    return params.data.offline_sale_product_datum.cgst_amount || 0;
                }
                return params.data.cgst || 0;
            }
        },
        { field: 'order_quantity', headerName: 'Order Quantity' },
        { 
            field: 'order_status', 
            headerName: 'Order Status',
            cellRenderer: params => {
                const getStatusOptions = () => {
                    if (params.data.product_item || params.data.offline_sale_product_datum) {
                        return orderStatuses.product || {};
                    } else if (params.data.seller_service) {
                        return orderStatuses.service || {};
                    }
                    return {};
                };

                const statusOptions = getStatusOptions();

                return (
                    <select 
                        id="order_status" 
                        value={params.value} 
                        className="form-control" 
                        onChange={(e) => changeOrderStatus(params.data.id, e.target.value, params.data.order_price, params.data.order.user_id, params.value)} 
                        style={{width: '130px'}}
                    >
                        {Object.entries(statusOptions).map(([key, value]) => (
                            <option key={key} value={key}>{value}</option>
                        ))}
                    </select>
                );
            }
        },
        { field: 'order_completed_on', headerName: 'Order Completed On' },
        { field: 'order_cancelled_on', headerName: 'Order Cancelled On' },
        { 
            field: 'payment_id', 
            headerName: 'Payment ID',
            valueGetter: params => params.data.order.payment_detail ? params.data.order.payment_detail.razorpay_payment_id : ""
        },
        { 
            field: 'payment_status', 
            headerName: 'Payment Status',
            valueGetter: params => params.data.order.payment_detail ? "Success" : "Failed"
        },
        {
            headerName: 'Assign Delivery Person',
            cellRenderer: params => {
                const canAssignDelivery = 
                    (params.data.logistics_vendor_id == localStorage.getItem('userId')) || 
                    (params.data.product_item && params.data.product_item.self_deliver && params.data.seller_id == localStorage.getItem('userId')) || 
                    (params.data.seller_service && params.data.seller_service.seller_id == localStorage.getItem("userId"))

                if (!canAssignDelivery) return "NA";

                return (
                    <select 
                        value={params.data.deliver_id || "0"} 
                        onChange={e => assignDeliver(params.data.id, e.target.value, 'deliver_id')}
                        className='form-control'
                    >
                        <option value="0">Select Delivery User</option>
                        {deliveryUsers.map(user => (
                            <option key={user.id} value={user.id}>
                                {`${user.first_name} ${user.last_name} (${user.reg_id})`}
                            </option>
                        ))}
                    </select>
                );
            }
        },
        {
            headerName: 'Assign Delivery Person Return Case',
            cellRenderer: params => {
                const canAssignDelivery = 
                    (params.data.logistics_vendor_id == localStorage.getItem('userId')) || 
                    (params.data.product_item && params.data.product_item.self_deliver && params.data.seller_id == localStorage.getItem('userId')) || 
                    (params.data.seller_service && params.data.seller_service.seller_id == localStorage.getItem("userId"))

                if (!canAssignDelivery) return "NA";

                return (
                    <select 
                        value={params.data.return_deliver_id || "0"} 
                        onChange={e => assignDeliver(params.data.id, e.target.value, 'return_deliver_id')}
                        className='form-control'
                    >
                        <option value="0">Select Delivery User</option>
                        {deliveryUsers.map(user => (
                            <option key={user.id} value={user.id}>
                                {`${user.first_name} ${user.last_name} (${user.reg_id})`}
                            </option>
                        ))}
                    </select>
                );
            }
        },
        {
            headerName: 'Assign Delivery Person Replace Case',
            cellRenderer: params => {
                const canAssignDelivery = 
                    (params.data.logistics_vendor_id == localStorage.getItem('userId')) || 
                    (params.data.product_item && params.data.product_item.self_deliver && params.data.seller_id == localStorage.getItem('userId')) || 
                    (params.data.seller_service && params.data.seller_service.seller_id == localStorage.getItem("userId"))

                if (!canAssignDelivery) return "NA";

                return (
                    <select 
                        value={params.data.replace_deliver_id || "0"} 
                        onChange={e => assignDeliver(params.data.id, e.target.value, 'replace_deliver_id')}
                        className='form-control'
                    >
                        <option value="0">Select Delivery User</option>
                        {deliveryUsers.map(user => (
                            <option key={user.id} value={user.id}>
                                {`${user.first_name} ${user.last_name} (${user.reg_id})`}
                            </option>
                        ))}
                    </select>
                );
            }
        },
        {
            headerName: 'Generate Invoice',
            cellRenderer: params => (
                <>
                    <button className="btn btn-outline-success w-50 btn-sm usercardbtn1" onClick={() => generateInvoice(params.data.seller_id, params.data.order.id, ['seller_product'])}>
                        Invoice Generate
                    </button>
                    <button className="btn btn-outline-success w-50 btn-sm usercardbtn1" onClick={() => generateInvoice(params.data.logistics_vendor_id, params.data.order.id, ['logistics'])}>
                        Logistics Invoice
                    </button>
                </>
            )
        }
    ], [deliveryUsers, orderStatuses]);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        resizable: true,
    }), []);

    return (
        <>
            <div className="row">
                <div className='col-md-6'>
                    <h3>Orders</h3>
                </div>
                <div className='col-md-6'>
                    <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
                    <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
                    <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>
                </div>
            </div>
            <Form className='row'>
                        <FormGroup className='col-md-4 row'>
                            <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                            <input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getOrders} className="form-control col-md-5" id="Date-from" name="Date-from"></input>
                        </FormGroup>
                        <FormGroup className='col-md-4 row'>
                            <Label className=" col-md-2 col-form-label" for="Date-to">To:</Label>
                            <input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getOrders} className="form-control col-md-5" id="Date-to" name="Date-to"></input>
                        </FormGroup>
                    </Form>
            <div className='row'>
                <div className='col-md-12'>
                    <div className="ag-theme-quartz" style={{ height: 600, width: '100%' }}>
                        <AgGridReact
                            rowData={orders}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            rowSelection="multiple"
                            suppressRowClickSelection={true}
                            pagination={true}
                            paginationPageSize={10}
                            paginationPageSizeSelector={[10, 25, 50]}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}