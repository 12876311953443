import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { ArrowUpRight, ArrowDownRight, DollarSign, ShoppingCart, Users, Mail } from 'lucide-react';
import moment from 'moment';
import './content.css'
import { Link, useNavigate } from 'react-router-dom';

const common = require('../../../utils/Common');

const StatCard = ({ title, value, subValue, color, icon: Icon, textColor }) => (
  <Card className={`text-white h-100 shadow ${color}`}>
    <CardBody className="d-flex flex-column justify-content-between">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <CardTitle tag="h5" className="m-0 text-white fs-4 fw-bold">{title}</CardTitle>
        <Icon size={24} />
      </div>
      <CardText className="display-6 fw-bold text-white mb-2">{value}</CardText>
      <CardText className="mb-0 text-white fs-5">{subValue}</CardText>
    </CardBody>
  </Card>
);

const FeaturedInfo = () => {
  const [homeData, setHomeData] = useState([{
    total_orders: 0,
    total_order_value: 0,
    product_count: 0,
    product_value: 0,
    offline_sale: 0,
    online_sale: 0,
    service_count: 0,
    service_value: 0
  }]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getSellerHomeData();
  }, []);

  const getSellerHomeData = async () => {
    try {
      setIsLoading(true);
      const data = {
        'seller_id': localStorage.getItem('sellerId'),
        'action': "read",
        'start_date': document.getElementById('Date-from')?.value,
        'end_date': document.getElementById('Date-to')?.value,
      };
      const response = await common.apiRequest('post', '/seller/fetch-seller-dashboard', data);
      if (response.data.code === 'success') {
        setHomeData(response.data.data);
      } else {
        setHomeData([{
          total_orders: 0,
          total_order_value: 0,
          product_count: 0,
          product_value: 0,
          offline_sale: 0,
          online_sale: 0,
          service_count: 0,
          service_value: 0,
          total_feedback_count: 0,
          total_query_count: 0,
        }]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setHomeData([{
        total_orders: 0,
        total_order_value: 0,
        product_count: 0,
        product_value: 0,
        offline_sale: 0,
        online_sale: 0,
        service_count: 0,
        service_value: 0,
        total_feedback_count: 0,
        total_query_count: 0,
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  const safeHomeData = homeData && homeData[0] ? homeData[0] : {
    offline_sale: 0,
    online_sale: 0,
    total_orders: 0,
    total_order_value: 0,
    product_count: 0,
    product_value: 0,
    service_count: 0,
    service_value: 0,
    total_query_count: 0,
    total_feedback_count: 0
  };

  const chartData = [
    {
      name: 'Sales',
      offline_sale: safeHomeData.offline_sale || 0,
      online_sale: safeHomeData.online_sale || 0
    },
  ];        

  return (
    <Container fluid className="p-3 mt-5">
      <Form className="row mb-4">
        <FormGroup className="col-md-4 row">
          <Label className="col-md-2 col-form-label" for="Date-from">
            From:
          </Label>
          <div className="col-md-5">
            <Input
              type="date"
              id="Date-from"
              name="Date-from"
              defaultValue={moment().startOf('month').format('YYYY-MM-DD')}
              onChange={getSellerHomeData}
            />
          </div>
        </FormGroup>
        <FormGroup className="col-md-4 row">
          <Label className="col-md-2 col-form-label" for="Date-to">
            To:
          </Label>
          <div className="col-md-5">
            <Input
              type="date"
              id="Date-to"
              name="Date-to"
              defaultValue={moment().endOf('month').format('YYYY-MM-DD')}
              onChange={getSellerHomeData}
            />
          </div>
        </FormGroup>
      </Form>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <Row className="mb-3 g-3">
          <Col xs={12} sm={6} lg={3}>
            <StatCard
              title="Total Orders Received"
              value={safeHomeData.total_orders}
              subValue={`Completed Orders: ${safeHomeData.total_order_value}`}
              color="bg-gradient-primary"
              icon={ShoppingCart}
            />
          </Col>
          <Col xs={12} sm={6} lg={3}>
            <StatCard
              title="Product Sales"
              value={safeHomeData.product_count}
              subValue={`This Month: ${safeHomeData.product_value}`}
              color="bg-gradient-success"
              icon={ArrowUpRight}
            />
          </Col>
          <Col xs={12} sm={6} lg={3}>
            <StatCard
              title="Sales"
              value={`offline sale: ${safeHomeData.offline_sale}`}
              subValue={`online sale: ${safeHomeData.online_sale}`}
              color="bg-gradient-warning"
              icon={DollarSign}
            />
          </Col>
          <Col xs={12} sm={6} lg={3}>
            <StatCard
              title="Service Sales"
              value={safeHomeData.service_count}
              subValue={`This Month: ${safeHomeData.service_value}`}
              color="bg-gradient-danger"
              textColor="text-white"
              icon={ArrowDownRight}
            />
          </Col>
        </Row>
      )}

      <Row className="g-3">
        <Col xs={12} lg={8}>
          <Card className="shadow h-100 bg-light">
            <CardBody>
              <CardTitle tag="h5" className="mb-4">Unique Visitors</CardTitle>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="offline_sale" fill="#8884d8" />
                  <Bar dataKey="online_sale" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12} lg={4}>
          <Row className="g-3 h-100">
            <Col xs={12}>
              <Card className="shadow h-100 bg-info text-white">
                <CardBody className="d-flex flex-column justify-content-between">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <CardTitle tag="h5" className="m-0">Query Count</CardTitle>
                    <Users size={24} />
                  </div>
                  <CardText className="display-6 fw-bold mb-2 text-white fs-4">{safeHomeData.total_query_count}</CardText>
                  <Button color="light" className="mt-3"><Link to='/seller/SellerReplyQuery'>Manage Query</Link></Button>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12}>
              <Card className="shadow h-100 bg-secondary text-white">
                <CardBody className="d-flex flex-column justify-content-between">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <CardTitle tag="h5" className="m-0">Feedback Count</CardTitle>
                    <Mail size={24} />
                  </div>
                  <CardText className="display-6 fw-bold mb-2 text-white fs-4">{safeHomeData.total_feedback_count}</CardText>
                  <Button color="light" className="mt-3"><Link to='/seller/SellerReplyFeedback'>Manage Feedback</Link></Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default FeaturedInfo;