import React, { useEffect, useState, useMemo } from 'react';
import { useCSVDownloader } from 'react-papaparse';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { exportToCSV, exportToExcel, exportToPDF } from '../../../utils/Exports';
import { FormGroup, Button, Label, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import $ from 'jquery';
import moment from 'moment';
import { formatHeaderName } from '../../../utils/formatHeaderName';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const common = require('../../../utils/Common');

function VendorReport() {
    const [rowData, setRowData] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const { CSVDownloader } = useCSVDownloader();

    useEffect(() => {
        getReportData();
    }, []);

    const getReportData = async () => {
        var parameters = {
            'seller_id': localStorage.getItem('sellerId'),
            'report_name': 'vendor_reports',
            'start_date': $('#Date-from').val(),
            'end_date': $('#Date-to').val(),
        };
        let response = await common.apiRequest('post', '/reports/get-reports', parameters);
        let data = (response.data && response.data.data) ? response.data.data : [];
        const keysToExclude = ['order_item_id'];

        // Set column definitions
        const cols = [
            {
                field: 'slNo',
                headerName: 'Sl No.',
                valueGetter: (params) => params.node.rowIndex + 1,
                checkboxSelection: true,
                headerCheckboxSelection: true,
                width: 100
            },
            ...data.keys
              .filter(key => !keysToExclude.includes(key))
              .map(key => ({
                  field: key,
                  headerName: formatHeaderName(key),
                  filter: 'agTextColumnFilter',
                  editable: true,
                  valueFormatter: formatCellValue(),
                  ...(key.includes('price') || key.includes('value') || key.includes('gst') ? 
                      { width: 130, type: 'numericColumn', filter: 'agNumberColumnFilter' } : {}),
                  ...(key.includes('date') || key.includes('created_at') || key.includes('updated_at') ? 
                      { width: 160 } : {})
              }))
        ];
        setColumnDefs(cols);

        // Set row data
        setRowData(data.reportData);
    }
    const formatCellValue = (value) => {
        if (value === null || value === undefined) {
          return '';
        }
      
        // Handle dates
        if (value instanceof Date || (typeof value === 'string' && !isNaN(Date.parse(value)))) {
          const date = new Date(value);
          if (date.toString() !== 'Invalid Date') {
            return date.toLocaleDateString();
          }
        }
      
        // Handle numbers
        if (typeof value === 'number') {
          // Check if it's a valid number
          if (isNaN(value)) return '0';
          // Format number with commas for thousands
          return value.toLocaleString();
        }
      
        // Handle booleans
        if (typeof value === 'boolean') {
          return value ? 'Yes' : 'No';
        }
      
        // Handle arrays
        if (Array.isArray(value)) {
          return value.join(', ');
        }
      
        // Handle objects
        if (typeof value === 'object') {
          try {
            return JSON.stringify(value);
          } catch (e) {
            return '[Object]';
          }
        }
      
        // Default: convert to string
        return String(value);
      };
    const exportPDF = () => {
        const doc = new jsPDF('landscape', 'pt', 'a4');
        doc.text("Vendor Report", 40, 40);
        
        const columns = columnDefs.map(col => ({ header: col.headerName, dataKey: col.field }));
        
        doc.autoTable({
            columns: columns,
            body: rowData,
            startY: 50,
        });

        doc.save("vendor_report.pdf");
    };

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        resizable: true,
        editable: true,
    }), []);
    
      const excludeFields = ['order_item_id'];

      const data = rowData.map(row => {
          const newRow = {};
          Object.keys(row)
              .filter(key => !excludeFields.includes(key))
              .forEach(key => {
                  newRow[formatHeaderName(key)] = row[key];
              });
          return newRow;
      });
    
    const handleExportCSV = () => {
      exportToCSV(data, 'Vendorreport');
    }
    const handleExportExcel = () => {
      exportToExcel(data, 'Vendorreport');
    }
    
    const handleExportPDF = () => {
      exportToPDF(data, 'Vendorreport');
    }
    return (
        <>
            <div className="row report-margin">
                <div className='col-md-6'>
                    <h3>Vendor Reports</h3>
                </div>
                <div className="col-md-12">
                    <Form className='row'>
                        <FormGroup className='col-md-4 row'>
                            <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                            <input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getReportData} className="form-control col-md-5" id="Date-from" name="Date-from"></input>
                        </FormGroup>
                        <FormGroup className='col-md-4 row'>
                            <Label className=" col-md-2 col-form-label" for="Date-to">To:</Label>
                            <input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getReportData} className="form-control col-md-5" id="Date-to" name="Date-to"></input>
                        </FormGroup>
                    </Form>      
                </div>
                <div className='col-md-6 row'>
                     <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
         <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
         <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>

                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <div className="ag-theme-quartz" style={{height: 600, width: '100%'}}>
                        <AgGridReact
                            rowData={rowData}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            rowSelection="multiple"
                            suppressRowClickSelection={true}
                            pagination={true}
                            paginationPageSize={10}
                            paginationPageSizeSelector={[10, 25, 50]}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default VendorReport;