import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import $ from 'jquery';
const common = require('../utils/Common');
const envConfig = require('../config/envConfig');

const Search = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchString, setSearchString] = useState("");
    const [allProducts, setAllProducts] = useState([]);
    const [allFilters, setAllFilters] = useState([]);
    const [nearBy, setNearBy] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState({
        variations: [],
        variationOptions: []
    })

    const searchProduct = async () => {
        let searchString = $('#search').val().trim();
        if (searchString != "") {
            var parameters = {
                'queryString': searchString,
                'filters': appliedFilters
            }
            var response = await common.apiRequest('post', '/products/search-product', parameters);
            setAllProducts(response.data.data.products);
            setAllFilters(response.data.data.filters);
            setSearchString(searchString);
        }
    }

    const addToFilters = async (variationId, variationOptionId, e) => {
        let previousVariations = appliedFilters.variations;
        let previousVariationOptions = appliedFilters.variationOptions;
        if (e.target.checked) {
            previousVariations.push(variationId);
            previousVariationOptions.push(variationOptionId);
        } else {
            let index = previousVariations.indexOf(variationId);
            if (index > -1) {
                previousVariations.splice(index, 1);
            }
            index = previousVariationOptions.indexOf(variationOptionId);
            if (index > -1) {
                previousVariationOptions.splice(index, 1);
            }
        }
        setAppliedFilters(prev => ({
            ...prev, 
            variations: previousVariations,
            variationOptions: previousVariationOptions
        }));
        searchProduct();
    }

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            navigate('/login');
        }
        if (location.state && location.state.data) {
            if (location.state.data.products) {
                setAllProducts(location.state.data.products);
            }
            if (location.state.nearBy) {
                setNearBy(true);
                setAllProducts(location.state.data.data.products);
            } else {
                setNearBy(false);
                if (location.state.data.data) {
                    setAllProducts(location.state.data.data.products);
                }
            }
            if (location.state.data.filters) {
                setAllFilters(location.state.data.filters);
            }
            if (location.state.data.searchString) {
                setSearchString(location.state.data.searchString);
            }
        }
    }, [location.state]);
    

    return (
        <>
            <div className='row'>
                <div className='col-md-2 mt-5'>
                    { !nearBy && allProducts.length > 0 && allFilters && allFilters.length !== 0 ? (
                        allFilters.map((filter) => {
                            return (
                                <>
                                    <label className='pl-4 mt-3'><b>{filter.name}</b></label><br/>
                                    {
                                        filter.value.split(',').map((option, index) => {
                                            let variationOptionIds = filter.variation_option_ids.split(',');
                                            let variationIds = filter.variation_ids.split(',');
                                            return (
                                                <>
                                                    <input type='checkbox' className='ml-4' onClick={(e) => addToFilters(variationIds[index], variationOptionIds[index], e)}/> {option}
                                                </>
                                            );
                                        })
                                    }
                                    <br/>
                                </>
                            );
                        })
                    ) : null}
                </div>
                <div className='col-md-10'>
                    <div class="container-fluid bg-trasparent my-4 p-3" style={{ position: "relative",maxWidth: "1400px" }}>
                        <div class="row row-cols-1 row-cols-xs-2 row-cols-sm-2 row-cols-lg-4 g-3">
                        {!nearBy ? allProducts.length > 0 ?
                        allProducts.map((product) => (
                            product.product_items && product.product_items.map((item) => {
                                return (
                                    <div class="col hp mt-5">
                                        <div class="card h-100 shadow-sm">
                                                <Link to={`/product/${product.id}/${item.id}`}>
                                                {item.product_image && item.product_image.split(',')[0] && (
                                                        <img src={envConfig.api_url + item.product_image.split(',')[0]} class="card-img-top"  alt="product img" />
                                                    )}
                                                </Link>
                                            <div class="label-top shadow-sm">
                                            
                                                <Link class="text-white" to={`/product/${product.id}/${item.id}`}>
                                                        {product.name}
                                                    </Link>
                                            </div>
                                        
                                            <div class="card-body">
                                                <div class="clearfix mb-3">
                                                    <span class="float-start badge rounded-pill bg-warning">Rs. {item.price}</span>
                                                </div>
                                                {/* <h5 class="card-title">
                                                    <a target="_blank" href="#">ASUS TUF FX505DT Gaming Laptop- 15.6", 120Hz Full HD, AMD Ryzen 5 R5-3550H Processor, GeForce GTX 1650 Graphics, 8GB DDR4, 256GB PCIe SSD, RGB Keyboard, Windows 10 64-bit - FX505DT-AH51</a>
                                                </h5> */}

                                                {/* <div class="d-grid gap-2 my-4">

                                                    <a href="#" class="btn btn-warning bold-btn">add to cart</a>

                                                </div> */}
                                            
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        )) : <>
                            <h4>No Product Found</h4>
                        </>: null}
                        
                        {allProducts.length > 0 && allProducts[0]['address'] ? 
                            allProducts.map((product) => {
                                return (
                                    <div class="col hp mt-5">
                                        <div class="card h-100 shadow-sm">
                                            <h2>{product.user.user_business.business_name}</h2>
                                            <h4>{product.user.user_business.nature_of_business}</h4>
                                            <h4>{product.address}</h4>
                                        </div>
                                    </div>
                                )
                            })
                        : nearBy ? 
                        <>
                            <h4>No near vendors found</h4>
                        </> : null
                        }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Search;
