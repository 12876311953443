import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import './business.css';
import { ToWords } from 'to-words';

const common = require('../utils/Common');
const envConfig = require('../config/envConfig');

function GenerateInvoice() {
    const [invoiceData, setInvoiceData] = useState([]);
    const location = useLocation();
    var counter = 0;

    useEffect(() => {
        setInvoiceData(location.state?.invoiceData || {});
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
    }, [location.state]);

    const downloadPdfDocument = (invoice) => {
        const input = document.getElementById(`invoice-${invoice.invoice_no}`);
        if (!input) return;
    
        const a4Width = 595.28;
        const a4Height = 841.89;
        const padding = 0;
    
        html2canvas(input, {
            scale: 2,
            useCORS: true,
            logging: true,
            windowWidth: (a4Width + padding) * 2,
            windowHeight: a4Height * 2
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg', 1.0);
            const pdf = new jsPDF('p', 'pt', 'a4');
    
            const imgWidth = a4Width - padding * 2;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
    
            if (imgHeight <= a4Height) {
                pdf.addImage(imgData, 'JPEG', padding, 0, imgWidth, imgHeight);
            } else {
                let y = 0;
                while (y < imgHeight) {
                    pdf.addImage(imgData, 'JPEG', padding, -y, imgWidth, imgHeight);
                    y += a4Height;
                    if (y < imgHeight) pdf.addPage();
                }
            }
    
            pdf.save(`invoice-${invoice.invoice_no}.pdf`);
        });
    }

    const formatDecimal = (value) => {
        if (value === null || value === undefined || value === '') {
            return '0.00';
        }
        const numValue = typeof value === 'string' ? parseFloat(value) : value;

        if (isNaN(numValue)) {
            return '0.00';
        }
        return Number(Math.round(numValue + 'e2') + 'e-2').toFixed(2);
    };

    const toWords = new ToWords({
        localeCode: 'en-IN',
        converterOptions: {
            currency: true,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false,
        }
    });

    const convertToWords = (amount) => {
        const numAmount = parseFloat(amount);
        if (isNaN(numAmount)) return '';
        return toWords.convert(numAmount, { currency: true });
    };
    if (invoiceData && invoiceData.length > 0) {
        return (
            <div id='invoice' className='mb-5'>
            {
                invoiceData.map((invoiceData,index) => {
                    counter = 0;
                    if (invoiceData?.order?.user) {
                        return (
                            <>
                            <div key={index} className='mb-5' id={`invoice-${invoiceData.invoice_no}`}>
                                <div className='bill-customer' id="testId">
                                    <div className='invoice'>
                                        <div className='invoice-head col-md-12'>
                                        <h2>{"Tax Invoice / Bill of Supply"}</h2>
                                        </div>
                                        <div className='customer-details'>
                                            <div className='customer-details-head'>
                                                <h2>Customer Details:</h2>
                                            </div>
                                            <div className='bill-details row'>
                                                <div className='billing-address col-md-6'>
                                                    <div className='billing-address-head'><h4>Billing Address :</h4></div>
                                                    <div className='billing-address-details'>
                                                        <div className='user-address row'>
                                                            <label className='col-md-6'>Name :</label>
                                                            <div className='col-md-6'>{invoiceData.order.user.first_name + " " + invoiceData.order.user.last_name}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className='col-md-6'>Address :</label>
                                                            <div className='col-md-6'>{invoiceData.order.user_address?.address || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className='col-md-6'>Pincode :</label>
                                                            <div className='col-md-6'>{invoiceData.order.user_address?.pincode || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className='col-md-6'>State :</label>
                                                            <div className='col-md-6'>{invoiceData.order.user_address?.state || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className='col-md-6'>Pan No :</label>
                                                            <div className='col-md-6'>{invoiceData.order.user?.pan_no || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className='col-md-6'>Email :</label>
                                                            <div className='col-md-6'>{invoiceData.order.user?.email || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className='col-md-6'>Mobile No :</label>
                                                            <div className='col-md-6'>{invoiceData.order.user?.mobile_phone || "N/A"}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='shipping-address col-md-6'>
                                                    <div className='shipping-address-head'><h4>Shipping Address :</h4></div>
                                                    <div className='shipping-address-details'>
                                                        <div className='user-address row'>
                                                            <label className='col-md-6'>Name :</label>
                                                            <div className='col-md-6'>{invoiceData.order.user.first_name + " " + invoiceData.order.user.last_name}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className='col-md-6'>Address :</label>
                                                            <div className='col-md-6'>{invoiceData.order.order_type == "offline_product" ? invoiceData.seller.seller_addresses[0]?.address : invoiceData.order.user_address?.address || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className='col-md-6'>Pincode :</label>
                                                            <div className='col-md-6'>{invoiceData.order.order_type == "offline_product" ? invoiceData.seller.seller_addresses[0]?.pincode : invoiceData.order.user_address?.pincode || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className='col-md-6'>State :</label>
                                                            <div className='col-md-6'>{invoiceData.order.order_type == "offline_product" ? invoiceData.seller.seller_addresses[0]?.state : invoiceData.order.user_address?.state || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className='col-md-6'>Email :</label>
                                                            <div className='col-md-6'>{invoiceData.order.user?.email || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className='col-md-6'>Mobile No :</label>
                                                            <div className='col-md-6'>{invoiceData.order.user?.mobile_phone || "N/A"}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='customer-details'>
                                            <div className='customer-details-head'>
                                                <h4>Supply by:</h4>
                                            </div>
                                            <div className='bill-details row'>
                                                <div className='billing-address col-md-6'>
                                                    <div className='billing-address-details'>
                                                        <div className='user-address row'>
                                                            <label className='col-md-6'>Name :</label>
                                                            <div className='col-md-6'>{invoiceData.seller?.user_business?.business_name || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className='col-md-6'>Address :</label>
                                                            <div className='col-md-6'>{invoiceData.seller?.seller_addresses[0]?.address || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className='col-md-6'>Pincode :</label>
                                                            <div className='col-md-6'>{invoiceData.seller?.seller_addresses[0]?.pincode || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className='col-md-6'>GSTIN :</label>
                                                            <div className='col-md-6'>{invoiceData.seller?.seller_addresses[0]?.gst_no || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className='col-md-6'>State :</label>
                                                            <div className='col-md-6'>{invoiceData.seller?.seller_addresses[0]?.state || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className='col-md-6'>Pan No :</label>
                                                            <div className='col-md-6'>{invoiceData.seller?.user_business?.pan_no || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className='col-md-6'>Place of supply :</label>
                                                            <div className='col-md-6'>{invoiceData.seller?.seller_addresses[0]?.state || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className='col-md-6'>Place of delivery :</label>
                                                            <div className='col-md-6'>{invoiceData.order.user_address?.state || "N/A"}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='shipping-address col-md-6'>
                                                    <div className='shipping-address-details'>
                                                        <div className='user-address row'>
                                                            <label className='col-md-6'>Invoice Number :</label>
                                                            <div className='col-md-6'>{invoiceData.invoice_no || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className='col-md-6'>Invoice Date :</label>
                                                            <div className='col-md-6'>{invoiceData.created_at || "N/A"}</div>
                                                        </div>
                                                            {/* {isReturned && (
                                                            <div className='user-address row'>
                                                            <label className='col-md-6'>Return Date :</label>
                                                            <div className='col-md-6'>{invoiceData.order.order_items[0].rirn_date || "N/A"}</div>
                                                            </div>
                                                            )} */}
                                                        <div className='user-address row'>
                                                            <label className='col-md-6'>Order ID (SRRR Number) :</label>
                                                            <div className='col-md-6'>{invoiceData.order.order_id || "N/A"}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className='col-md-6'>Mode of Payment  :</label>
                                                            <div className='col-md-6'>Online</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className='col-md-6'>Amount received  :</label>
                                                            <div className='col-md-6'>Rs. {invoiceData.price || 0}</div>
                                                        </div>
                                                        <div className='user-address row'>
                                                            <label className='col-md-6'>Date & Time :</label>
                                                            <div className='col-md-6'>{invoiceData.created_at || "N/A"}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className='invoice-table'>
                                            <div className='invoice-table-head'>
                                                <h4>Goods wise Details:</h4>
                                            </div>
                                            <div className='invoice-table-body'>
                                                <table className="orders-table report-margin table-responsive">
                                                    <thead>
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th>Product</th>
                                                            <th>HSN/SAC Code</th>
                                                            <th>Qty</th>
                                                            <th>Rate</th>
                                                            <th>Sale Amount</th>
                                                            <th>Disc</th>
                                                            <th>Taxable Supply</th>
                                                            <th>IGST
                                                                <th>Rate %</th>
                                                                <th>Amount</th>
                                                            </th>
                                                            <th>CGST
                                                                <th>Rate %</th>
                                                                <th>Amount</th>
                                                            </th>
                                                            <th>SGST / UTGST
                                                                <th>Rate %</th>
                                                                <th>Amount</th>
                                                            </th>
                                                            <th>TOTAL
                                                                <th>GST</th>
                                                                <th>Invoice Amount</th>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            invoiceData?.invoice_type == "logistics" ?
                                                                    <tr>
                                                                        <td>1</td>
                                                                        <td>Logistics</td>
                                                                        <td>NA</td>
                                                                        <td>NA</td>
                                                                        <td>{formatDecimal(invoiceData?.order?.logistics_order?.order_price || 0)}</td>
                                                                        <td>{formatDecimal(invoiceData?.order?.logistics_order?.order_price || 0)}</td>
                                                                        <td>{formatDecimal(0)}</td>
                                                                        <td>{formatDecimal(invoiceData?.order?.logistics_order?.taxable_value || 0)}</td>
                                                                        <td>
                                                                            <td>{formatDecimal(invoiceData?.order?.logistics_order?.igst_percent || 0)}</td>
                                                                            <td>{formatDecimal(invoiceData?.order?.logistics_order?.igst || 0)}</td>
                                                                        </td>
                                                                        <td>
                                                                            <td>{formatDecimal(invoiceData?.order?.logistics_order?.cgst_percent || 0)}</td>
                                                                            <td>{formatDecimal(invoiceData?.order?.logistics_order?.cgst || 0)}</td>
                                                                        </td>
                                                                        <td>
                                                                            <td>{formatDecimal(invoiceData?.order?.logistics_order?.sgst_percent || invoiceData?.order?.logistics_order?.utgst_percent || 0)}</td>
                                                                            <td>{formatDecimal(invoiceData?.order?.logistics_order?.sgst || invoiceData?.order?.logistics_order?.utgst || 0)}</td>
                                                                        </td>
                                                                        <td>
                                                                            <td>{formatDecimal(invoiceData?.order?.logistics_order?.gst_rate)}</td>
                                                                            <td>{formatDecimal(invoiceData?.order?.logistics_order?.order_price || 0)}</td>
                                                                        </td>
                                                                    </tr>
                                                            :
                                                                invoiceData.order.order_items.map((data) => {
                                                                    if (invoiceData.seller_id == data.seller_id ) {
                                                                        const isReturned = data.order_status === 'process_amount_to_wallet';
                                                                        counter++;
                                                                        return (
                                                                            <tr key={counter}>
                                                                            <td>{counter}</td>
                                                                            <td>{data.product_item ? data.product_item.product?.name || "N/A" : data.seller_service ? data.seller_service?.service_name || "N/A" : data.offline_sale_product_datum?.product_description || "N/A"}</td>
                                                                            <td>{data.product_item?.hsn_code || data.seller_service?.hsn_code || data.offline_sale_product_datum?.hsn_code || "N/A"}</td>
                                                                            <td>{data.offline_sale_product_datum?.quantity || data.order_quantity || "N/A"}</td>
                                                                            <td>{formatDecimal(data.product_item?.price || data.seller_service?.price || data.offline_sale_product_datum?.price || 0)}</td>
                                                                            <td>{formatDecimal(data.order_price || data.offline_sale_product_datum?.sale_amount || 0)}</td>
                                                                            <td>{formatDecimal(data.offline_sale_product_datum?.discount || 0)}</td>
                                                                            <td>{formatDecimal( data.taxable_amount || data.offline_sale_product_datum?.taxable_supply || 0)}</td>
                                                                            <td>
                                                                                <td>{formatDecimal( data.igst_percent || data.offline_sale_product_datum?.igst_rate)}</td>
                                                                                <td>{formatDecimal( data.igst || data.offline_sale_product_datum?.igst_amount)}</td>
                                                                            </td>
                                                                            <td>
                                                                                <td>{formatDecimal( data.cgst_percent  || data.offline_sale_product_datum?.cgst_rate)}</td>
                                                                                <td>{ formatDecimal(data.cgst  || data.offline_sale_product_datum?.cgst_amount)}</td>
                                                                            </td>
                                                                            <td>
                                                                                <td>{ formatDecimal(data.sgst_percent || data.offline_sale_product_datum?.sgst_utgst_rate)}</td>
                                                                                <td>{formatDecimal( data.sgst || data.offline_sale_product_datum?.sgst_utgst_amount)}</td>
                                                                            </td>
                                                                            <td>
                                                                                <td>{ formatDecimal((data.product_item ? data.product_item?.gst_percent || 0 : data.seller_service?.gst_rate || (data.offline_sale_product_datum?.cgst_rate + data.offline_sale_product_datum?.sgst_utgst_rate) || 0))}</td>
                                                                                <td>{formatDecimal(data.order_price || data.offline_sale_product_datum?.sale_amount || 0)}</td>
                                                                            </td>
                                                                        </tr>
                                                                        )
                                                                    }
                                                                })
                                                        }
                                                        <tr>
                                                            <td colSpan="7"></td>
                                                            <td colSpan="10">
                                                                <tr>
                                                                    <td><b>Total</b></td>
                                                                    <td>Rs. {formatDecimal(invoiceData.price || 0)}</td>
                                                                    <td>{convertToWords(formatDecimal(invoiceData.price || 0))}</td>

                                                                </tr>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className='footer-data row'>
                                            <b className='col-md-6'>Whether GST payable under reverse charge  ?</b>
                                            <h6 className='col-md-6'>No</h6>
                                        </div>
                                        <div className='footer-data row'>
                                            <div className='col-md-12 row'>
                                                <h5 className='col-md-6'>Remarks:</h5>
                                                <div className='col-md-6 row'>
                                                    <label className='col-md-2'>for</label>
                                                    <div className='col-md-4'>
                                                        <b>Name:</b>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='footer-data row'>
                                            <div className='col-md-12 row'>
                                                <h5 className='col-md-6'>Remarks:</h5>
                                                <div className='col-md-6'>
                                                    <div className='Sign-image-vendor'>Sign image of vendor AS</div>
                                                    <div className='Authorised-Signatory'>Authorised Signatory</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button onClick={() => downloadPdfDocument(invoiceData)} className="btn btn-primary mt-3">Download Invoice</button>
                            </div>
                            </>
                        );
                    } else {
                        return 'No Invoice';
                    }
                })
            }
            {/* <button onClick={downloadPdfDocument}>Download Pdf</button> */}
            </div>
        )
    }
}

export default GenerateInvoice;
