import React, { useEffect, useState, useMemo } from 'react';
import { Modal, ModalHeader, ModalBody, Input, FormGroup, Button, Label, Form, Alert } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import moment from 'moment';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { exportToCSV, exportToExcel, exportToPDF } from '../../utils/Exports';

const common = require('../../utils/Common');

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const SellerReplyFeedback = () => {
    const [feedbackData, setFeedbackData] = useState([]);
    const [replyData, setReplyData] = useState({});
    const [modal, setModal] = useState(false);
    const [feedbackReply, setFeedbackReply] = useState('');
    const [itemsPerPage] = useState(10); // Set the number of items per page
    const navigate = useNavigate();

    const toggle = () => setModal(!modal);

    useEffect(() => {
        getFeedback();
    }, []);

    const getFeedback = async () => {
        const parameters = {
            source: 'seller',
            'seller_id': localStorage.getItem('sellerId'),
            'fromDate': $('#Date-from').val(),
            'toDate': $('#Date-to').val(),
        };
        let response = await common.apiRequest('post', '/users/FeedBackDetails', parameters);
        let data = (response.data && response.data.data) ? response.data.data : [];
        setFeedbackData(data);
    };

    const InvoiceFilter = async () => {
        const parameters = {
            source: 'Seller',
            "filter": "invoice",
            'seller_id': localStorage.getItem('sellerId'),
            'fromDate': $('#Date-from').val(),
            'toDate': $('#Date-to').val(),
            'invoice_no': $('#Invoice').val(),
        };
        let response = await common.apiRequest('post', '/users/FeedBackDetails', parameters);
        let data = (response.data && response.data.data) ? response.data.data : [];
        setFeedbackData(data);
    }
    const invoiceId = replyData?.order_item?.order?.invoices?.[0]?.id;

    const reply = async () => {
        const parameters = {
            feedback_id: replyData.id,
            feedback_reply: feedbackReply,
            invoice_id: invoiceId,
            feedback_reply_by: "seller",
            source: "admin",
            active_status: 1
        };
        let response = await common.apiRequest('post', '/users/user-feedback', parameters);
        if (response.status === 200) {
            if (response.data.code === "success") {
                common.alert(response.data.message);
                window.location.reload();
            } else {
                common.alert(response.data.error, 'error');
            }
        }
    };

    const replyFeedback = (data) => {
        setReplyData(data);
        setFeedbackReply('');
        toggle();
    };

    const columnDefs = useMemo(() => [
        { headerName: "S.no", valueGetter: "node.rowIndex + 1" },
        { headerName: "User Id", field: "user.reg_id", width: 150 },
        { 
            headerName: "Invoice No", 
            valueGetter: params => {
                const invoices = params.data?.order_item?.order?.invoices || [];
                const sellerProductInvoice = invoices.find(invoice => invoice.invoice_type === 'seller_product' && invoice.seller_id == params.data.seller_id);
                return sellerProductInvoice?.invoice_no || "N/A";
              },
            width: 150 
        },        
        { headerName: "User feedback", field: "user_feedback", valueGetter: params => params.data.user_feedback || "N/A" },
        { headerName: "Date", field: "created_at", valueGetter: params => params.data.created_at || "N/A" },
        { headerName: "Category", field: "type", valueGetter: params => params.data.type || "N/A" },
        {
            headerName: "Response from vendor", field: "vendor_response",
            valueGetter: params => {
                const vendorResponse = params.data.feedback_replys.find(reply => reply.feedback_reply_by === 'seller');
                return vendorResponse ? vendorResponse.feedback_reply : "No response";
            }
        },
        {
            headerName: "Date of response (Vendor)", field: "vendor_response_date",
            valueGetter: params => {
                const vendorResponse = params.data.feedback_replys.find(reply => reply.feedback_reply_by === 'seller');
                return vendorResponse ? vendorResponse.created_at : "N/A";
            }
        },
        {
            headerName: "Response from Admin", field: "admin_response",
            valueGetter: params => {
                const adminResponse = params.data.feedback_replys.find(reply => reply.feedback_reply_by === 'Admin');
                return adminResponse ? adminResponse.feedback_reply : "No response";
            }
        },
        {
            headerName: "Date of response (Admin)", field: "admin_response_date",
            valueGetter: params => {
                const adminResponse = params.data.feedback_replys.find(reply => reply.feedback_reply_by === 'Admin');
                return adminResponse ? adminResponse.created_at : "N/A";
            }
        },
        {
            headerName: "Reply", field: "reply", cellRenderer: params => (
                <Button className='btn btn-primary btn-sm' onClick={() => replyFeedback(params.data)}>View</Button>
            )
        },
    ], []);

    const defaultColDef = useMemo(() => ({
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        sortable: true,
        resizable: true,
    }), []);
    const rowData = feedbackData.map((data, index) => {
        const vendorResponse = data.feedback_replys?.find(reply => reply.feedback_reply_by === 'seller');
        const adminResponse = data.feedback_replys?.find(reply => reply.feedback_reply_by === 'Admin');
    
        return {
            ...data,
            vendorResponse: vendorResponse ? vendorResponse.feedback_reply : "No response",
            vendorResponseDate: vendorResponse ? vendorResponse.created_at : "N/A",
            adminResponse: adminResponse ? adminResponse.feedback_reply : "No response",
            adminResponseDate: adminResponse ? adminResponse.created_at : "N/A"
        };
    });
    const data = feedbackData.map((data, index) => ({
        "Reg ID": data.user.reg_id,
        "Invoice no": data.order_item?.order?.invoices?.find(invoice => invoice.invoice_type === 'seller_product' && invoice.seller_id == data.seller_id)?.invoice_no,
        "User Feedback": data.user_feedback,
        "Created at": data.created_at,
        "Type": data.type,
        "Vendor Response": rowData[index]?.vendorResponse,
        "Vendor ResponseDate": rowData[index]?.vendorResponseDate,
        "Admin Response": rowData[index]?.adminResponse,
        "Admin Response Date": rowData[index]?.adminResponseDate,
    }));
    const handleExportCSV = () => {
        exportToCSV(data, 'feedbackData');
    }
    
    const handleExportExcel = () => {
        exportToExcel(data, 'feedbackData');
    }
    
    const handleExportPDF = () => {
        exportToPDF(data, 'feedbackData');
    }
    return (
        <>
            <div className='feedback-component'>
                <div>
                    <div className='feedback-header col-md-12'>
                        <h2>Users Feedback</h2>
                    </div>
                    <div className="col-md-12">
                        <Form className='row'>
                            <FormGroup className='col-md-4 row'>
                                <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                                <input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getFeedback} className="form-control col-md-5" id="Date-from" name="Date-from"></input>
                            </FormGroup>
                            <FormGroup className='col-md-4 row'>
                                <Label className=" col-md-2 col-form-label" for="Date-to">To:</Label>
                                <input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getFeedback} className="form-control col-md-5" id="Date-to" name="Date-to"></input>
                            </FormGroup>
                        </Form>
                        <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
                        <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
                        <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>

                    </div>
                    <div className='ag-theme-alpine' style={{ height: 500, width: '100%' }}>
                        <AgGridReact
                            rowData={feedbackData}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            pagination={true}
                            paginationPageSize={itemsPerPage}
                        />
                    </div>
                </div>
            </div>

            <Modal isOpen={modal} toggle={toggle} size="xl">
                <ModalHeader toggle={toggle}>Feedback reply</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="reply">Reply feedback</Label>
                            <Input
                                type='text'
                                id="feedback_reply"
                                value={feedbackReply}
                                onChange={(e) => setFeedbackReply(e.target.value)}
                            />
                        </FormGroup>
                    </Form>
                    <Button className='submit' onClick={reply}>
                        Submit
                    </Button>
                </ModalBody>
            </Modal>
        </>
    );
};

export default SellerReplyFeedback;

