import React, {useState,useEffect} from'react';
import { useNavigate } from 'react-router-dom';
import { useCSVReader, useCSVDownloader } from 'react-papaparse';
import $ from 'jquery';
import Multiselect from 'multiselect-react-dropdown';
const common = require('../../utils/Common');
var finalData = [];

export default function FileUpload() {
  const { CSVReader } = useCSVReader();
  const { CSVDownloader, Type } = useCSVDownloader();
  const [upload, setUpload] = useState(0);
  const [categories, setCategories] = useState([]);
  const [userAddress, setUserAddress] = useState([]);
  const [showPincodes, setShowPincodes] = useState(false);
  const [showStates, setShowStates] = useState(false);
  const [panIndia, setPanIndia] = useState(false);
  const [showDeliveryType, setShowDeliveryType] = useState(false);
  const [productSubCategory, setproductSubCategory] = useState([]);
  const [panIndiaPincodes, setPanIndiaPincodes] = useState(null);
  const [pincodes, setPincodes] = useState({
    allPincodes: [],
    filterPincodes: [],
    msgPincode: "Type first 3 letters to search",
    selectedPincodes: []
  });
  const [states, setStates] = useState({
    allStates: [],
    selectedStates: [],
    msgStates: "No States Found"
  });
  const navigate = useNavigate();

  const uploadData = async (e) => {
    e.preventDefault();
    const category = document.getElementById('category').value;
    const gstNo = document.getElementById('gst_no').value;
    if (!document.getElementById('product_sub_category')) {
      common.alert("Please select category and sub category first", "error");
      return;
    }
    const productSubCategory = document.getElementById('product_sub_category').value;
    const selfDeliver = document.getElementById('self_deliver').value;
    const deliveryType = document.getElementById('self_deliver').value;
    if (!category || !gstNo || !productSubCategory || finalData.length === 0 || !selfDeliver || !deliveryType || (selfDeliver == "1" && ((showStates && states.selectedStates.length == 0) || (showPincodes && pincodes.selectedPincodes.length == 0)))) {
      common.alert('Please fill in all fields and upload a file.', 'error');
      return;
    }
		let response = await common.apiRequest('post', '/products/upload-products', finalData);
    if (response.status == 200) {
			if (response.data.code == 'success') {
        common.alert(response.data.message);
        setUpload(0);
        navigate('/seller/products');
			} else {
        common.alert(response.data.error, 'error');
        setUpload(0);
			}
		} else {
			common.alert('Something went wrong' ,'error');
      setUpload(0);
		}
  };
  const getBusinessUserAddress = async () => {
    var parameters = {
        'action': 'read',
        'seller_id': localStorage.getItem('userId'),
    };
    let response = await common.apiRequest('post', '/users/seller-address', parameters);
    let data = (response.data && response.data.data) ? response.data.data : [];
    if (data.length == 0) {
      common.alert("Please add seller business address first", 'error');
      navigate('/seller/Seller_Address');
    }
    setUserAddress(data);
}
  const getCategories = async (parent_category_id = 0) => {
		var parameters = {
      'action': 'read',
      'source': 'web',
      'parent_category_id': parent_category_id
    }
    let response = await common.apiRequest('post', '/admin/crud-product-category', parameters);
    let data = (response.data && response.data.data) ? response.data.data : [];
    if (parent_category_id == 0) {
      setCategories(data);
  } else {
      setproductSubCategory(data);
  }
  }

  const searchPincode = async (value) => {
    if (value.length > 2) {
      var parameters = {
        'action': 'read',
        'value': value
      }
      let response = await common.apiRequest('post', '/admin/crud-pincodes', parameters);
      let data = (response.data && response.data.data) ? response.data.data : [];
      const items = data;
      if (items.length == 0) {
          setPincodes({...pincodes, filterPincodes: [], msgPincode: "No pincode found"});
      } else {
          setPincodes({...pincodes, filterPincodes: items, msgPincode: "Type first 3 letters to search"});
      }
    } else {
      setPincodes({...pincodes, filterPincodes: [], msgPincode: "Type first 3 letters to search"});
    }
  }

  const getAllStatesAndPincodes = async (type) => {
    var parameters = {
      'action': 'read',
      'type': type
    }
    let response = await common.apiRequest('post', '/admin/crud-pincodes', parameters);
    let data = (response.data && response.data.data) ? response.data.data : [];
    if (type == "pan_india") {
      setPanIndiaPincodes(data);
    } else {
      if (data.length == 0) {
          setStates({...states, allStates: [], msgPincode: "No pincode found", selectedStates: []});
      } else {
          setStates({...states, allStates: data, msgPincode: null, selectedStates: []});
      }
    }
  }

  const selectPincode = (selectedList, selectedItem) => {
      var selectedPincodes = pincodes.selectedPincodes;
      selectedPincodes.push(selectedItem.id);
      setPincodes({...pincodes, selectedPincodes: selectedPincodes});
  }

  const removePincode = (selectedList, removedItem) => {
      var selectedPincodes = pincodes.selectedPincodes;
      selectedPincodes.splice(selectedPincodes.indexOf(removedItem.id));
      setPincodes({...pincodes, selectedPincodes: selectedPincodes});
  }

  const selectState = (selectedList, selectedItem) => {
    var selectedStates = states.selectedStates;
    let ids = selectedItem.id.split(',');
    ids.forEach(id => {
      selectedStates.push(id);
    });
    setStates({...states, selectedStates: selectedStates});
  }

  const removeState = (selectedList, removedItem) => {
      var selectedStates = states.selectedStates;
      let ids = removedItem.id.split(',');
      ids.forEach(id => {
        selectedStates.splice(selectedStates.indexOf(id));
      });
      setStates({...states, selectedStates: selectedStates});
  }

  const setSelfDeliveryStatus = () => {
    setShowPincodes(false);
    setShowStates(false);
    if ($('#self_deliver').val() == "1") {
      setShowDeliveryType(true);
    } else {
      setShowDeliveryType(false);
    }
  }

  const selectDeliveryType = async () => {
    let type = $('#delivery_type').val();
    if (type == "") {
      return;
    }
    if (type == "state_wise") {
      // call state wise api
      await getAllStatesAndPincodes('states');
      setShowStates(true);
      setShowPincodes(false);
      setPanIndia(false);
    } else if (type == "pincode_wise") {
      setPincodes({...pincodes, selectedPincodes: []});
      setShowPincodes(true);
      setShowStates(false);
      setPanIndia(false);
    } else if (type == "pan_india") {
      await getAllStatesAndPincodes('pan_india');
      setPanIndia(true);
      setShowPincodes(false);
      setShowStates(false);
    }
  }

  useEffect(()=>{
    getCategories();
    getBusinessUserAddress();
  },[]);

  return (
    <CSVReader
      onUploadAccepted={(results) => {
        const category = document.getElementById('category').value;
        const gstNo = document.getElementById('gst_no').value;
        const selfDeliver = document.getElementById('self_deliver').value;
        if (!document.getElementById('product_sub_category')) {
          common.alert("Please select category and sub category first", "error");
          return;
        }
        const productSubCategory = document.getElementById('product_sub_category').value;
        if (!category || !gstNo || !productSubCategory || !selfDeliver || (selfDeliver == "1" && $("#delivery_type").val() == "")) {
          // Check if mandatory fields and data are provided
          common.alert('Please fill in all fields and upload a file.', 'error');
          return;
        }
        
        // If self-delivery is selected, additional checks are required
        if (selfDeliver === "1") {
          // Check if states are required and selected
          if (showStates && (!states.selectedStates || states.selectedStates.length === 0)) {
            common.alert('Please select a state.', 'error');
            return;
          }
          
          // Check if pincodes are required and selected
          if (showPincodes && (!pincodes.selectedPincodes || pincodes.selectedPincodes.length === 0)) {
            common.alert('Please select a pincode.', 'error');
            return;
          }
        }
        if (results) {
          var fileDataOk = true;
          finalData = [];
          finalData.push(localStorage.getItem('userId'));
          finalData.push($('#gst_no').val());
          finalData.push($('#product_sub_category').val());
          var obj = {};
          results.data[0] = results.data[0].map(function (el) {
            return el.trim();
          });
          var headers = results.data[0];
          results.data.shift();
          results.data.pop();
          if (results.data[0][0] != "Main" && results.data[0][0] != "main") {
            common.alert("First row of sheet should be Main", 'error');
            fileDataOk = false;
            return;
          }
          results.data.map((data) => {
            data = data.map(function (el) {
              return el.trim();
            });
            if (data[0] != '' && (data[0] == 'Main' || data[0] == 'Sub' || data[0] == 'main' || data[0] == 'sub')) {
              if (data[0] == 'Main' || data[0] == 'main') {
                if (JSON.stringify(obj) != '{}') {
                  finalData.push(obj);
                }
                obj = {};
                obj['sub'] = [];
                for(var j = 1; j <= 2; j++) {
                  if (data[j] == '') {
                    common.alert("Please add value for " + headers[j], 'error');
                    fileDataOk = false;
                    return;
                  }
                  obj[headers[j]] = data[j];
                }
                obj['product_category'] = $('#category').val();
              }
              let pincode = (panIndia ? panIndiaPincodes : (showStates ? states.selectedStates.map(v => '-'+v+'-').join('') : pincodes.selectedPincodes.map(v => '-'+v+'-').join('')));
              obj['pincodes'] = pincode;
              var obj1 = {};
              obj1['variations'] = {};
              for(var j = 3; j < 15; j++) {
                if (data[j] != "") {
                  obj1[headers[j]] = data[j];
                } else {
                  common.alert("Please add value for " + headers[j], 'error');
                  fileDataOk = false;
                  return;
                }
              }
              obj1['self_deliver'] = $('#self_deliver').val();
              obj1[headers[19]] = data[19];
              for(var j = 20; j < data.length; j++) {
                if (data[j] != '') {
                  obj1['variations'][headers[j]] = data[j];
                }
              }
              obj.sub.push(obj1);
            } else {
              common.alert("Please add product type as Main or Sub", 'error');
              fileDataOk = false;
              return;
            }
          });
          if (fileDataOk) {
            finalData.push(obj);
            setUpload(1);
          }
        } else {
          common.alert('File empty', 'error');
        }
      }}
    >
      {({
        getRootProps,
        acceptedFile,
        ProgressBar,
        getRemoveFileProps,
        Remove
      }) => (
        <>
          <div className='row p-4 saller-margin'>
            <div className='col-md-12'>
              <h5 className='text-danger'>*****Please read below instructions before importing products*****</h5>
              <ol className='pl-3'>
                <li>Download products csv file by clicking on
                            &nbsp; <a href='../../../products.csv' download="products.csv" style={{'color' : 'blue', 'cursor' : 'pointer'}}>Download</a>
                    </li>
                <li>Do not remove headers from csv files.</li>
                <li>Please specify product type as <b>Main</b> or <b>Sub</b> (case-sensitive).</li>
                <li>Enter product name, product description, product sku, product quantity, product price in the sheet.</li>
                <li>Product category, product name, product description details are not required in case product is a sub product.</li>
                <li>For vairations please specify variation name in the header after faq and value below it.</li>
                <li>In case some variant is present in one product and not present in other product then keep that field empty.</li>
                <li>One can provide any number of variants.</li>
                <li>To download all the instructions please click this <a href="#">products instructions</a> link.</li>
              </ol>
            </div>
          </div>
          <div className='col-md-12 mt-3'>
              <select id="category" className='form-control' onChange={(e) => getCategories(e.target.value)}>
                  <option value="">Select Category</option>
                  {
                      categories.map((category) => {
                          return (
                              <>
                                  <option value={category.id}>{category.category_name}</option>
                              </>
                          );
                      })
                  }
              </select>
          </div>
          {
                    productSubCategory.length == 0 ? "" :
                        <div className='col-md-12 mt-3'>
                            <select id="product_sub_category" className='form-control'>
                                <option value="">Product Sub Category</option>
                                {
                                    productSubCategory.map((category) => {
                                        return (
                                            <>
                                                <option value={category.id}>{category.category_name}</option>
                                            </>
                                        );
                                    })
                                }
                            </select>
                        </div>
                }
          <div className='col-md-12 mt-3'>
              <select id="gst_no" className='form-control'>
                  <option value="">Select Gst</option>
                  {
                      userAddress.map((address) => {
                          return (
                              <>
                                  <option value={address.id}>{address.gst_no}</option>
                              </>
                          );
                      })
                  }
              </select>
          </div>
          <div className='col-md-12 mt-3'>
              <select id="self_deliver" className='form-control' onChange={setSelfDeliveryStatus}>
                  <option value="">Self Deliver ?</option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
              </select>
          </div>
          {
            showDeliveryType ? 
            <div className='col-md-12 mt-3'>
                <select id="delivery_type" className='form-control' onChange={selectDeliveryType}>
                    <option value="">Delivery Type ?</option>
                    <option value="pan_india">PAN India</option>
                    <option value="state_wise">State Wise</option>
                    <option value="pincode_wise">Pincode Wise</option>
                </select>
            </div>
           : null  
          }
          {
            showStates ? 
              <div className='col-md-12 mt-3'>
                {states ?
                      <Multiselect
                      options={states.allStates} // Options to display in the dropdown
                      displayValue="name"
                      showCheckbox="true"
                      onSelect={selectState}
                      onRemove={removeState}
                      placeholder="Select State"
                  />: <div className='loaader'></div>
                }
              </div>
            : ""
          }
          {
            showPincodes ? 
              <div className='col-md-12 mt-3'>
                {pincodes?
                      <Multiselect
                      options={pincodes.filterPincodes} // Options to display in the dropdown
                      displayValue="name"
                      showCheckbox="true"
                      onSearch={searchPincode}
                      onSelect={selectPincode}
                      onRemove={removePincode}
                      emptyRecordMsg={pincodes.msgPincode}
                      placeholder="Select Pincodes"
                  />: <div className='loaader'></div>
                }
                    
              </div>
            : ""
          }
          <div className='upload' style={{ 'marginBottom' : '100px'}}>
              <h2 className='text-center'>Upload your file</h2>
              <input id='actual-btn' {...getRootProps()} type="button" name="img" hidden/>
              <center><label for="actual-btn"><img src="/assets/img/file.png" className="uploadImg cursor-pointer" style={{'height' : '100px', 'width' : '100px', 'cursor' : 'pointer'}}/></label></center>
              <div className='row'>
                <div className='col-md-4'></div>
                <div className='col-md-4'><ProgressBar/></div>
                <div className='col-md-4'></div>
              </div>
              {upload && acceptedFile ?
                <>
                  <h5 className='text-center'>
                    {acceptedFile && acceptedFile.name}
                    <span className='cursor-pointer pl-10'
                      {...getRemoveFileProps()}><Remove color='red'/></span>
                  </h5>
                  <div className='text-center'>
                    <button className='btn btn-success' onClick={uploadData}>Upload</button>
                  </div>
                </> : '' }
          </div>
        </>
      )}
    </CSVReader>
  )
}