import React, { useEffect, useState, useMemo } from 'react';
import { Modal, ModalHeader, ModalBody, Input, FormGroup, Button, Label, Form, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import moment from 'moment';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { exportToCSV, exportToExcel, exportToPDF } from '../../utils/Exports';

const common = require('../../utils/Common');

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const SellerReplyQuery = () => {
    const [QueryData, setQueryData] = useState([]);
    const [replyData, setReplyData] = useState([]);
    const [modal, setModal] = useState(false);
    const [modalTwo, setModalTwo] = useState(false);
    const [detailsModal, setDetailsModal] = useState(false);  
    const [QueryReply, setQueryReply] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10); 
    const [selectedDetails, setSelectedDetails] = useState(null);  
    const navigate = useNavigate();

    const toggle = () => setModal(!modal);
    const toggleAnother = () => setModalTwo(!modalTwo);
    const toggleDetails = () => setDetailsModal(!detailsModal); 

    useEffect(() => {
        getQuery();
    }, []);
    const getQuery = async () => {
        const parameters = {
            source: 'seller',
            'seller_id': localStorage.getItem('sellerId'),
            'fromDate': $('#Date-from').val(),
            'toDate': $('#Date-to').val(),
        };
        let response = await common.apiRequest('post', '/users/QueryDetails', parameters);
        let data = (response.data && response.data.data) ? response.data.data : [];
        setQueryData(data);
    };

    const invoiceId = replyData?.order_item?.order?.invoices?.[0]?.id;
    const invoiceNO = replyData?.order_item?.order?.invoices?.[0]?.invoice_no;

    const reply = async () => {
        const parameters = {
            query_id: replyData.id,
            query_reply: QueryReply,
            invoice_id: invoiceId,
            query_reply_by: "seller",
            source: "admin",
            active_status: 1
        };
        let response = await common.apiRequest('post', '/users/user-Query', parameters);
        if (response.status === 200) {
            if (response.data.code === "success") {
                common.alert(response.data.message);
            } else {
                common.alert(response.data.error, 'error');
            }
            window.location.reload();
        }
    };

    const replyQuery = (data) => {
        setReplyData(data);
        toggle();
    };

    const showQuery = (data) => {
        setReplyData(data);
        toggleAnother();
    };

    const showDetails = (data) => {
        setSelectedDetails(data);  
        toggleDetails();
    };

   
    const columnDefs = useMemo(() => [
        { headerName: "S.no", valueGetter: "node.rowIndex + 1", width: 80 },
        { headerName: "User Id", field: "user.reg_id", width: 150 },
        { 
            headerName: "Invoice No", 
            valueGetter: params => {
                const invoices = params.data?.order_item?.order?.invoices || [];
                const sellerProductInvoice = invoices.find(invoice => invoice.invoice_type === 'seller_product' && invoice.seller_id == params.data.seller_id);
                return sellerProductInvoice?.invoice_no || "N/A";
              },
                width: 150 
        },
        { 
            headerName: "User Query", 
            field: "user_query", 
            width: 200 
        },
        { 
            headerName: "Date", 
            valueGetter: params => moment(params.data.created_at).format('YYYY-MM-DD') || "N/A", 
            width: 150 
        },
        { 
            headerName: "Category", 
            field: "type", 
            width: 150 
        },
        { 
            headerName: "Response from vendor", 
            field: "vendorResponse", 
            width: 200 
        },
        { 
            headerName: "Date of response", 
            valueGetter: params => params.data.vendorResponseDate || "N/A", 
            width: 150 
        },
        { 
            headerName: "Response from Admin", 
            field: "adminResponse", 
            width: 200 
        },
        { 
            headerName: "Date of response", 
            valueGetter: params => params.data.adminResponseDate || "N/A", 
            width: 150 
        },
        { 
            headerName: "Reply", 
            field: "reply", 
            cellRenderer: (params) => (
                <button className='btn btn-primary btn-sm' onClick={() => replyQuery(params.data)}>View</button>
            ),
            width: 100 
        },
        // { 
        //     headerName: "Details", 
        //     cellRenderer: (params) => (
        //         <button className='btn btn-secondary btn-sm' onClick={() => showDetails(params.data)}>Details</button>
        //     ),
        //     width: 100 
        // }
    ], []);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        resizable: true,
    }), []);

    const rowData = QueryData.map((data, index) => {
        const vendorResponse = data.querys_replys?.find(reply => reply.query_reply_by === 'seller');
        const adminResponse = data.querys_replys?.find(reply => reply.query_reply_by === 'Admin');
    
        return {
            ...data,
            vendorResponse: vendorResponse ? vendorResponse.query_reply : "No response",
            vendorResponseDate: vendorResponse ? vendorResponse.created_at : "N/A",
            adminResponse: adminResponse ? adminResponse.query_reply : "No response",
            adminResponseDate: adminResponse ? adminResponse.created_at : "N/A"
        };
    });
    const data = QueryData.map((data, index) => ({
        "Reg id": data.user.reg_id,
        "Invoice no": data.order_item?.order?.invoices?.find(invoice => invoice.invoice_type === 'seller_product' && invoice.seller_id == data.seller_id)?.invoice_no,
        "User Query": data.user_query,
        "Created at": data.created_at,
        "Type": data.type,
        "Vendor Response": rowData[index]?.vendorResponse,
        "Vendor Response Date": rowData[index]?.vendorResponseDate,
        "Admin Response": rowData[index]?.adminResponse,
        "Admin ResponseDate": rowData[index]?.adminResponseDate,
    }));
    const handleExportCSV = () => {
        exportToCSV(data, 'QueryData');
    }
    
    const handleExportExcel = () => {
        exportToExcel(data, 'QueryData');
    }
    
    const handleExportPDF = () => {
        exportToPDF(data, 'QueryData');
    }
    return (
        <>
            <div className='Query-component'>
                <div>
                    <div className='Query-header col-md-12'>
                        <h2>Users Query</h2>
                    </div>
                    <div className="col-md-12">
                        <Form className='row'>
                            <FormGroup className='col-md-4 row'>
                                <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                                <input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getQuery} className="form-control col-md-5" id="Date-from" name="Date-from"></input>
                            </FormGroup>
                            <FormGroup className='col-md-4 row'>
                                <Label className=" col-md-2 col-form-label" for="Date-to">To:</Label>
                                <input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getQuery} className="form-control col-md-5" id="Date-to" name="Date-to"></input>
                            </FormGroup>
                        </Form>
                        <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
                        <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
                        <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>

                    </div>
                    <div className='ag-theme-alpine' style={{ height: 500, width: '100%' }}>
                        <AgGridReact
                            rowData={rowData}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            pagination={true}
                            paginationPageSize={itemsPerPage}
                        />
                    </div>
                </div>
            </div>

            {/* Query Reply Modal */}
            <Modal isOpen={modal} toggle={toggle} size="xl">
                <ModalHeader toggle={toggle}>Query reply</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="reply">Reply Query</Label>
                            <Input
                                type='text'
                                id="Query_reply"
                                value={QueryReply}
                                onChange={(e) => setQueryReply(e.target.value)}
                            />
                        </FormGroup>
                    </Form>
                    <Button className='submit' onClick={reply}>
                        Submit
                    </Button>
                </ModalBody>
            </Modal>

            {/* Details Modal */}
            <Modal isOpen={detailsModal} toggle={toggleDetails} size="xl">
                <ModalHeader toggle={toggleDetails}>Details</ModalHeader>
                <ModalBody>
                    {selectedDetails && (
                        <table className='table table-bordered'>
                            <thead>
                                <tr>
                                    <th>S. No.</th>
                                    <th>Invoice No</th>
                                    <th>Item/Particular</th>
                                    <th>Online Shopping Taxable Amount</th>
                                    <th>Online Shopping Total Amount</th>
                                    <th>Offline Shopping Taxable Amount</th>
                                    <th>Offline Shopping Total Amount</th>
                                    <th>Online Sales Taxable Amount</th>
                                    <th>Online Sales Total Amount</th>
                                    <th>Offline Sales Taxable Amount</th>
                                    <th>Offline Sales Total Amount</th>
                                    <th>Commission Amount</th>
                                    <th>TDS</th>
                                    <th>Net Receivable</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{selectedDetails.sno || 'N/A'}</td>
                                    <td>{selectedDetails?.order_item?.order?.invoices?.[0]?.invoice_no || 'N/A'}</td>
                                    <td>{selectedDetails.item || 'N/A'}</td>
                                    <td>{selectedDetails.onlineShoppingTaxableAmount || 'N/A'}</td>
                                    <td>{selectedDetails.onlineShoppingTotalAmount || 'N/A'}</td>
                                    <td>{selectedDetails.offlineShoppingTaxableAmount || 'N/A'}</td>
                                    <td>{selectedDetails.offlineShoppingTotalAmount || 'N/A'}</td>
                                    <td>{selectedDetails.onlineSalesTaxableAmount || 'N/A'}</td>
                                    <td>{selectedDetails.onlineSalesTotalAmount || 'N/A'}</td>
                                    <td>{selectedDetails.offlineSalesTaxableAmount || 'N/A'}</td>
                                    <td>{selectedDetails.offlineSalesTotalAmount || 'N/A'}</td>
                                    <td>{selectedDetails.commissionAmount || 'N/A'}</td>
                                    <td>{selectedDetails.tds || 'N/A'}</td>
                                    <td>{selectedDetails.netReceivable || 'N/A'}</td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                </ModalBody>
            </Modal>
        </>
    );
};

export default SellerReplyQuery;
