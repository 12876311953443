import React, { useEffect, useState } from 'react'
import { Input, FormGroup, Button, Label } from 'reactstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import $ from 'jquery';

const common = require('../../utils/Common');

function SellerChangePass() {
    let navigate = useNavigate();
    const location = useLocation()
    const { editPaas } = location.state ? location.state : {};
    const [userID, setAddress] = useState(editPaas ? editPaas : {});
    const changePassword = async () => {
        const new_pass = $('#new_pass').val()
        const confirm_pass = $('#confirm_pass').val()

        if(new_pass == confirm_pass){
            var parameters = {
                'action':"changepass",
                "source":"seller",
                'password':$('#old_pass').val(),
                'new_pass':$('#new_pass').val(),
                'id': userID.id
            };
            let response = await common.apiRequest('post', '/users/crudUserDetails', parameters);
            if (response.status == 200) {
              if (response.data.code == "success") {
                common.alert(response.data.message);
                $('#old_pass').val('')
                $('#new_pass').val('')
                $('#confirm_pass').val('')
            } else {
                common.alert(response.data.error, 'error');
              }
            }
        }
        else(
            common.alert("your new password is not match with confirm pass", 'error')
        )
      }

  return (
        <>

                <div className='chnagepasscard'>
                <div class="card" style={{width:"40%"}}>
                <div class="card-body">
                <FormGroup>
                    <Label for="newEntry">Enter your current password</Label>
                    <Input
                        type='text' id="old_pass" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Enter a new password</Label>
                    <Input
                        type='text' id="new_pass" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Please confirm password</Label>
                    <Input
                        type='text' id="confirm_pass" 
                    />
                </FormGroup>
                <Button className='submit' onClick={changePassword}>
                    Submit
                </Button>
                </div>
                </div>
                </div>
                
                
        </>

    )
}

export default SellerChangePass