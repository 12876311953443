import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import $ from 'jquery';
const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');

function Payment() {
    const navigate = useNavigate();
    const location = useLocation();
    const [deliveryAddress, setDeliveryAddress] = useState(0);
    const [logisticVendor, setLogisticVendor] = useState({});
    const [cartPrice, setCartPrice] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [cartItems, setCartItems] = useState([]);
    const [userWallet, setUserWallet] = useState(0);
    const [prevWalletTotalAmount, setPrevWalletTotalAmount] = useState({
        totalPrice: 0,
        wallet: 0
    });
    useEffect(() => {
        setDeliveryAddress(location.state.deliveryAddress);
        setLogisticVendor(location.state.logisticVendor);
        getCartItems();
    }, [logisticVendor]);

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function displayRazorpay() {
        if (totalPrice == 0) {
            placeOrder(0);
            return;
        }
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!res) {
            common.alert("Razorpay SDK failed to load. Are you online?", 'error');
            return;
        }
        var data = {
            'amount': totalPrice
        }
        const result = await common.apiRequest('post', '/payment/orders', data);
        if (!result) {
            common.alert("Server error. Are you online?", 'error');
            return;
        }
        const { amount, id: order_id, currency } = result.data.data;
        const receipt_id = result.data.receiptId;
        const options = {
            key: envConfig.razorpay_key_id, // Enter the Key ID generated from the Dashboard
            amount: amount.toString(),
            currency: currency,
            name: "Shree Raja Ram Raj Biz",
            description: "Place Order",
            image: {},
            order_id: order_id,
            handler: async function (response) {
                const data = {
                    orderCreationId: order_id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                    receiptId: receipt_id
                };
                const result = await common.apiRequest('post', '/payment/success', data);
                common.alert(result.data.data.msg, 'success');
                placeOrder(result.data.paymentId);
            },
            theme: {
                color: "#61dafb",
            },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    const getCartItems = async () => {
        let parameters = {
            'user_id': localStorage.getItem('userId'),
            'action': 'read'
        }
        let response = await common.apiRequest('post', '/products/add-remove-cart-items', parameters);
        let data = (response.data && response.data.data) ? response.data.data : [];
        setUserWallet(response.data && response.data.wallet ? response.data.wallet : 0);
        
        const activeItems = data.filter(item => item.active === false);

        calculateTotalPrice(activeItems);

        setCartItems(activeItems);
    }


    const calculateTotalPrice = (cartItems) => {
        let cartPrice = 0;
        cartItems.map((item) => {
            cartPrice += parseFloat(item.product_item ? item.product_item.price.replaceAll(',', '') : item.seller_service.price.toString().replaceAll(',', '')) * parseInt(item.quantity_purchased);
        });
        setCartPrice(cartPrice);
        let totalPrice = cartPrice + (logisticVendor.price ? logisticVendor.price : 0);
        setTotalPrice(totalPrice);
    }

    const useWalletAmount = () => {
        if($('#used_wallet').prop('checked')) {
            setPrevWalletTotalAmount({
                totalPrice: totalPrice,
                wallet: userWallet
            });
            setTotalPrice(totalPrice < userWallet ? 0 : totalPrice-userWallet);
            setUserWallet(totalPrice > userWallet ? 0 : userWallet-totalPrice);
        } else {
            setTotalPrice(prevWalletTotalAmount.totalPrice);
            setUserWallet(prevWalletTotalAmount.wallet);
            setPrevWalletTotalAmount({
                totalPrice: 0,
                wallet: 0
            });
        }
    }

    const placeOrder = async (paymentId) => {
        var orderItems = [];
        var order = {
            'user_id': localStorage.getItem('userId'),
            'address_id': deliveryAddress,
            'order_price': cartPrice,
            'logistics_vendor_service_id': logisticVendor.serviceId ? logisticVendor.serviceId : 0,
            'logistics_vendor_id': logisticVendor.vendorId ? logisticVendor.vendorId : 0,
            'logistics_vendor_price': logisticVendor.price ? logisticVendor.price : 0,
            'zone': logisticVendor.zone ? logisticVendor.zone : '-',
            'distance': logisticVendor.distance ? logisticVendor.distance : '-',
            'payment_id': paymentId,
            'order_type': 'product',
            'order_status': 'in_progress',
            'logistic_orders': logisticVendor.distances ? logisticVendor.distances : [],
            'user_wallet': userWallet
        }
        console.log(order,"uhhhhhhhhhhhhhhhhhhhh")
        var walletAmount = prevWalletTotalAmount.wallet;
        cartItems.map((item) => {
            let wallet = 0;
            let itemPrice = parseFloat(item.product_item ? item.product_item.price.replaceAll(',', '') : item.seller_service.price.toString().replaceAll(',', '')) * parseInt(item.quantity_purchased);
            if (walletAmount != 0) {
                if (walletAmount > itemPrice) {
                    walletAmount = walletAmount - itemPrice;
                    wallet = itemPrice;
                    itemPrice = 0;
                } else {
                    wallet = walletAmount;
                    itemPrice = itemPrice - walletAmount;
                    walletAmount = 0;
                }
            }
            let items = {
                'seller_id': item.product_item ? item.product_item.product.seller_id : item.seller_service.seller_id,
                'product_id': item.product_item ? item.product_item_id : 0,
                'order_quantity': item.quantity_purchased,
                'order_price': parseFloat(item.product_item ? item.product_item.price.replaceAll(',', '') : item.seller_service.price.toString().replaceAll(',', '')) * parseInt(item.quantity_purchased),
                'service_id': item.seller_service ? item.seller_service.id : 0,
                'order_status': "in_progress",
                'logistics_vendor_id': item.product_item && !item.self_deliver && logisticVendor.vendorId ? logisticVendor.vendorId : 0,
                'logistics_order': !item.self_deliver && item.product_item && logisticVendor.distances ? logisticVendor.distances.find(logisticItem => logisticItem.seller_id == item.product_item.product.seller_id) : null,
                'wallet_amount': wallet
            };
            orderItems.push(items);
        });
        var data = {
            'order': order,
            'orderItems': orderItems
        }
        let response = await common.apiRequest('post', '/orders/place-orders', data);
        if (response.data.code == "success") {
            localStorage.setItem('cartItems', JSON.stringify([]));
            let parameters = {
                'user_id': localStorage.getItem('userId'),
                'action': 'delete-by-user',
                "type": "save"
            }
            await common.apiRequest('post', '/products/add-remove-cart-items', parameters);
            common.alert(response.data.message);
            navigate('/orders/null');
        }
    }

    return (
        <React.Fragment>
            <div className="container my-5">
                <div className="row">
                    <div className="col-md-8">
                        <h3>Payment</h3>
                        <hr />
                    </div>
                </div>
                <br />
                <div className="col-md-3 offset-md-1">
                    <div className="card" style={{ width: "30rem" }}>
                        <div className="card-header bg-transparent">
                            <p className="text-center text-success" style={{ fontSize: "15px" }}>
                                Your Item Is Ready For Payment
                            </p>
                        </div>
                        <div className="card-body text-center">
                            <p className="font-weight-bold">
                                Total ({cartItems.length} Item): <i className="fas fa-rupee-sign"></i> {cartPrice}
                            </p>
                            {
                                logisticVendor.price ? 
                                <p className="font-weight-bold">
                                    Logistics Price : <i className="fas fa-rupee-sign"></i> {logisticVendor.price}
                                </p>
                                : ''
                            }
                            <input type='checkbox' id='used_wallet' onClick={useWalletAmount}/> Use wallet amount (Remaining Rs. {userWallet}) <br/><br/> 
                            <hr/>
                            <p className="font-weight-bold">
                                Total Price : <i className="fas fa-rupee-sign"></i> {totalPrice}
                            </p>
                            {
                                cartItems.length > 0 ?
                                    <button className='btn btn-primary' onClick={displayRazorpay}>Proceed For Payment</button>
                                    :
                                    <Link to="/home"><button className="btn btn-info btn-sm btn-block">Go to home</button></Link>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Payment;