class MessageParser {
    constructor(actionProvider) {
      this.actionProvider = actionProvider;
    }
  
    parse(message) {
      if (message.toLowerCase().includes('hello')) {
        this.actionProvider.handleHello();
      } else if (message.includes('help')) {
        this.actionProvider.handleHelp();
      } else if (message.length > 10) {
        this.actionProvider.handleDefault(message);
      } else {
        this.actionProvider.handleError();
      }
    }
}

export default MessageParser;