// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .bg-gradient-primary {
    background: linear-gradient(45deg, #4e54c8, #8f94fb);
  }
  
  .bg-gradient-success {
    background: linear-gradient(45deg, #43a047, #66bb6a);
  }
  
  .bg-gradient-warning {
    background: linear-gradient(45deg, #ff9800, #ffc107);
  }
  
  .bg-gradient-danger {
    background: linear-gradient(45deg, #e53935, #ef5350);
  }
`, "",{"version":3,"sources":["webpack://./src/components/seller-portal/content/content.css"],"names":[],"mappings":"EAAE;IACE,oDAAoD;EACtD;;EAEA;IACE,oDAAoD;EACtD;;EAEA;IACE,oDAAoD;EACtD;;EAEA;IACE,oDAAoD;EACtD","sourcesContent":["  .bg-gradient-primary {\r\n    background: linear-gradient(45deg, #4e54c8, #8f94fb);\r\n  }\r\n  \r\n  .bg-gradient-success {\r\n    background: linear-gradient(45deg, #43a047, #66bb6a);\r\n  }\r\n  \r\n  .bg-gradient-warning {\r\n    background: linear-gradient(45deg, #ff9800, #ffc107);\r\n  }\r\n  \r\n  .bg-gradient-danger {\r\n    background: linear-gradient(45deg, #e53935, #ef5350);\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
