import React, { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FormGroup, Label, Form, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import $ from 'jquery';
import moment from 'moment';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { exportToCSV, exportToExcel, exportToPDF } from '../../utils/Exports';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');

function FeedBackView() {
    const navigate = useNavigate();
    const [offlineOrdersData, setOfflineOrdersData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [feedbackType, setFeedbackType] = useState('show'); 
    const [selectedFeedback, setSelectedFeedback] = useState([]);
    const [feedbackInput, setFeedbackInput] = useState('');
    const [selectedOrder, setSelectedOrder] = useState(null);

    const getData = async () => {
        let parameters = {
            source: 'user',
            'user_id': localStorage.getItem('userId'),
            'fromDate': $('#Date-from').val(),
            'toDate': $('#Date-to').val(),
        };
        let response = await common.apiRequest('post', '/users/FeedBackDetails', parameters);
        if (response.data.code === 'success') {
            setOfflineOrdersData(response.data.data);
        } else {
            common.alert(response.data.error, 'error');
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const sendFeedBack = async () => {
        var parameters = {
            'user_id': localStorage.getItem('userId'),
            'user_feedback': feedbackInput,
            'action': 'create',
            'order_id': selectedOrder?.id, 
            'invoice_no': selectedOrder?.invoice?.invoice_no,
            'category': selectedOrder?.invoice?.invoice_type,
        };
        try {
            let response = await common.apiRequest('post', '/users/user-feedback', parameters);
            if (response.data.code === "success") {
                common.alert(response.data.message);
                setFeedbackInput('');
                toggleModal();
            } else {
                common.alert(response.data.error, 'error');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const toggleModal = () => setModalOpen(!modalOpen);

    const showFeedback = (order, inviceno) => {
        setFeedbackType('show');
        const invoiceNo = inviceno;
        const feedbackForOrder = order.filter(feedback => feedback.invoice_no === invoiceNo);
        setSelectedFeedback(feedbackForOrder);
        toggleModal();
    };

    const openFeedbackModal = (order) => {
        setFeedbackType('send');
        setSelectedOrder(order);
        toggleModal();
    };

    const columnDefs = useMemo(() => [
        { headerName: "S.no", valueGetter: "node.rowIndex + 1" },
        { 
            headerName: "Invoice No", 
            valueGetter: params => {
                const invoices = params.data?.order_item?.order?.invoices || [];
                const sellerProductInvoice = invoices.find(invoice => invoice.invoice_type === 'seller_product' && invoice.seller_id == params.data.seller_id);
                return sellerProductInvoice?.invoice_no || "N/A";
              },
            width: 150 
        },        
        { field: "user_feedback", headerName: "User Feedback", sortable: true, filter: true },
        { field: "created_at", headerName: "Date", sortable: true, filter: true },
        { field: "type", headerName: "Category", sortable: true, filter: true },
        { 
            field: "feedback_replys",
            headerName: "Response from Vendor",
            valueGetter: params => {
                const vendorResponse = params.data.feedback_replys.find(reply => reply.feedback_reply_by === 'seller');
                return vendorResponse ? vendorResponse.feedback_reply : "No response";
            }
        },
        { 
            field: "feedback_replys",
            headerName: "Date of Vendor Response",
            valueGetter: params => {
                const vendorResponse = params.data.feedback_replys.find(reply => reply.feedback_reply_by === 'seller');
                return vendorResponse ? vendorResponse.created_at : "N/A";
            }
        },
        { 
            field: "feedback_replys",
            headerName: "Response from Admin",
            valueGetter: params => {
                const adminResponse = params.data.feedback_replys.find(reply => reply.feedback_reply_by === 'Admin');
                return adminResponse ? adminResponse.feedback_reply : "No response";
            }
        },
        { 
            field: "feedback_replys",
            headerName: "Date of Admin Response",
            valueGetter: params => {
                const adminResponse = params.data.feedback_replys.find(reply => reply.feedback_reply_by === 'Admin');
                return adminResponse ? adminResponse.created_at : "N/A";
            }
        }
    ], []);

    const defaultColDef = useMemo(() => ({
        sortable: true,
        filter: true,
        floatingFilter: true,
        resizable: true,
    }), []);
    
    const data = offlineOrdersData.map((item, index) => ({
            "S.no": index + 1,
            "Invoice no": item.order_item?.order?.invoices?.find(invoice => invoice.invoice_type === 'seller_product' && invoice.seller_id == item.seller_id)?.invoice_no,
            "User Feedback": item.user_feedback,
            "Date": item.created_at,
            "Category": item.type,
            "Response from Vendor": item.feedback_replys.find(reply => reply.feedback_reply_by === 'seller')?.feedback_reply || "No response",
            "Date of Vendor Response": item.feedback_replys.find(reply => reply.feedback_reply_by === 'seller')?.created_at || "N/A",
            "Response from Admin": item.feedback_replys.find(reply => reply.feedback_reply_by === 'Admin')?.feedback_reply || "No response",
            "Date of Admin Response": item.feedback_replys.find(reply => reply.feedback_reply_by === 'Admin')?.created_at || "N/A"
        }));
    

    const handleExportCSV = () => {
        exportToCSV(data, 'feedback');
    }
    
    const handleExportExcel = () => {
        exportToExcel(data, 'feedback');
    }
    
    const handleExportPDF = () => {
        exportToPDF(data, 'feedback');
    }
    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="row">
                    <ol className="col-12 breadcrumb bg-transparent">
                        <li className="breadcrumb-item"><Link to="/" className="text-dark" id="bccap">Home</Link></li>
                        <li className="breadcrumb-item active"><Link to="/my-account" className="text-dark" id="bccap">user-account</Link></li>
                        <li className="breadcrumb-item active"><Link to="#" className="text-dark" id="bccap">Feedback</Link></li>
                    </ol>
                </div>
            </div>

            <div className='row tables'>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>Your Valuable Feedback :</h2>
                        </div>
                    </div>
                </div>

                <div className='col-md-12'>
                    <div className="col-md-12">
                        <Form className='row'>
                            <FormGroup className='col-md-4 row'>
                                <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                                <Input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getData} className="form-control col-md-5" id="Date-from" name="Date-from"></Input>
                            </FormGroup>
                            <FormGroup className='col-md-4 row'>
                                <Label className="col-md-2 col-form-label" for="Date-to">To:</Label>
                                <Input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getData} className="form-control col-md-5" id="Date-to" name="Date-to"></Input>
                            </FormGroup>
                        </Form>
                        <button className='btn button-product' onClick={handleExportCSV}>Export to CSV</button>
                        <button className='btn button-product' onClick={handleExportExcel}>Export to Excel</button>
                        <button className='btn button-product' onClick={handleExportPDF}>Export to PDF</button>

                    </div>
                    <div className='ag-theme-alpine' style={{ height: 500, width: '100%' }}>
                        <AgGridReact
                            rowData={offlineOrdersData}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            rowSelection="multiple"
                            pagination={true}
                            paginationPageSize={10}
                        />
                    </div>

                </div>
            </div>
        </React.Fragment>
    );
}

export default FeedBackView;
